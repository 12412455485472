/* Queries */
import INSPECT_EMAIL from '../queries/getEmailData.graphql';
import USER from '../queries/getUser.graphql';
import USER_TYPE from '../queries/getUserType.graphql';
import USER_ERRORS from '../queries/getErrors.graphql';
import USER_DATA from '../queries/getUserData.graphql';
import PHONE_NUM from '../queries/getPhoneNum.graphql';
import FIRST_NAME from '../queries/getUserFirstName.graphql';
import OTP_HASH from '../queries/getOtpHash.graphql';
import USER_EMAIL from '../queries/getUserEmail.graphql';
import HOSTNAME from '../queries/getHostname.graphql';
import REFERRER from '../queries/getReferrer.graphql';
import PHONE_EMAIL_CONFIRMATION from '../queries/emailConfirmation.graphql';
import USER_PRODUCTS from '../queries/getUserProducts.graphql';
import RETRIEVE_HASH from '../queries/retrieveHash.graphql';
import ENTITLEMENT_STATUS from '../queries/getEntitlementStatus.graphql';
import ENTITLEMENT_SUBS_NO from '../queries/getEntitlementSubsNo.graphql';
import IS_SMS_ENTER from '../queries/isEnterWithSms.graphql';
import IS_LOGIN_SUCCESS from '../queries/isLoginSuccess.graphql';
/* Mutations */
import GENERATE_HASH from '../mutations/generateHash.graphql';
import CONNECT_MAIL_MOBILE from '../mutations/connectMailMobile.graphql';
import VALIDATE_MAIL_TO_MOBILE from '../mutations/validateMailMobileConfirmation.graphql';
import SEND_MAIL_CONFIRMATION_REQUEST from '../mutations/sendEmailConfirmation.graphql';
import CONFIRM_MAIL from '../mutations/validateEmail.graphql';

const getDataFromUserInfo = client => (email, site) => client.query({
  query: INSPECT_EMAIL,
  variables: { email, site: site.toUpperCase(), },
})
  .then(res => res.data);

const mockDataFromUserInfo = client => email => Promise.resolve({
  userByMail: {
    ssoId: '20023790436',
    mobilePhone: '0548888888',
    phonePrefix: '054',
    phoneSuffix: '8888888',
    userStatus: {
      isMailValidated: false,
      isMobileValidated: true,
      isPhoneEmailConn: false,
      isMiniReg: false,
    },
    userCrmStatus: {
      id: 654654,
      isActiveTm: true,
      isActiveHeb: false,
      isActiveEng: false,
    },
  },
});

const isEnterWithSms = client => client.readQuery({ query: IS_SMS_ENTER, }).isEnterWithSms;
const saveIsEnterWithSms = client => boolean => client.writeData(
  { data: { isEnterWithSms: boolean, }, }
);

const getEntitlementData = client => {
  const entitlementStatusData = client.readQuery({
    query: ENTITLEMENT_STATUS,
  });
  const entitlementSubsNoData = client.readQuery({
    query: ENTITLEMENT_SUBS_NO,
  });
  return {
    status: entitlementStatusData?.entitlementStatus || null,
    subsNo: entitlementSubsNoData?.entitlementSubsNo || null,
  };
};
const saveEntitlementData = client => (entitlementStatus, entitlementSubsNo = null) => {
  client.writeData({
    data: {
      entitlementStatus,
      entitlementSubsNo,
    },
  });
};
const isEntitlement = client => getEntitlementData(client).status != null;

const saveEntitlementCheckData = client => entitlementCheckData => {
  const status = entitlementCheckData?.status || 'n/a';
  const subsNo = status === 'LOGIN_ENTITLEMENT' && entitlementCheckData.description
    ? entitlementCheckData.description.match(/subsNo:(\d*)/)?.[1] || null
    : null;
  const data = {
    entitlementStatus: status,
    entitlementSubsNo: subsNo,
  };
  client.writeData({
    data,
  });
  return data;
};

const getUser = client => client.readQuery({ query: USER, }).userData;
const getUserData = client => client.readQuery({ query: USER_DATA, }).userData;
const getOtpHash = client => {
  try {
    return client.readQuery({ query: OTP_HASH, }).otpHash;
  }
  catch (e) {
    return null;
  }
};
const getFirstName = client => client.readQuery({ query: FIRST_NAME, }).user.firstName || client.readQuery({ query: FIRST_NAME, }).userData.firstName;
const getEmail = client => client.readQuery({ query: USER_EMAIL, }).userEmail;
const getType = client => client.readQuery({ query: USER_TYPE, }).user.type;
const getPhoneNum = client => client.readQuery({ query: PHONE_NUM, }).userData.mobilePhone;
const getErrors = client => client.readQuery({ query: USER_ERRORS, }).userData.errors;
const getHostname = client => client.readQuery({ query: HOSTNAME, }).hostname;
const getReferrer = client => client.readQuery({ query: REFERRER, }).loginReferrer;
const isLoginSuccess = client => client.readQuery({ query: IS_LOGIN_SUCCESS, }).loginSuccess;
const getPhoneEmailConfirmation = client => client.readQuery({
  query: PHONE_EMAIL_CONFIRMATION,
}).phoneEmailConfirmation;

const saveUserData = client => userDataObj => {
  client.writeData({ data: userDataObj, });
  return userDataObj;
};

const generateOtp = client => userMail => client.mutate({
  variables: { input: { typeId: userMail}, },
  mutation: GENERATE_HASH,
});

const retrieveHash = client => emailSsoObj => client.query({
  variables: { input: emailSsoObj, },
  query: RETRIEVE_HASH,
});

const getUserProducts = client => () => client.query({
  query: USER_PRODUCTS,
});

const connectMailWithPhone = client => ({ email, paramString, url, userName, phone, type, }) => (
  client.mutate({
    variables: {
      input: {
        email: (email || '').toLowerCase(),
        url,
        userName,
        phone,
        paramString: `${Buffer.from(paramString).toString('base64')}`,
        type,
      },
    },
    mutation: CONNECT_MAIL_MOBILE,
  })
);

const validateMailWithPhone = client => dataObj => client.mutate({
  variables: { input: dataObj, },
  mutation: VALIDATE_MAIL_TO_MOBILE,
});

const sendMailConfirmation = client => ({ email, paramString, url, confirmationType, firstName, type, }) => client.mutate({
  variables: {
    input: {
      email: (email || '').toLowerCase(),
      url,
      paramString: `${Buffer.from(paramString).toString('base64')}`,
      confirmationType,
      firstName,
      type,
    },
  },
  mutation: SEND_MAIL_CONFIRMATION_REQUEST,
});

const validateMailConfirmation = client => dataObj => client.mutate({
  variables: { input: dataObj, },
  mutation: CONFIRM_MAIL,
});

const saveOtpHash = client => otpHashObj => {
  client.writeData({ data: otpHashObj, });
  return otpHashObj;
};

const saveUserEmail = client => email => {
  client.writeData({ data: { userEmail: (email || '').toLowerCase(), }, });
  return email;
};

const savePhoneNum = client => phoneNumObj => {
  client.writeData({ data: phoneNumObj, });
  return phoneNumObj;
};

const saveLoginSuccess = client => isSuccess => {
  client.writeData({ data: { loginSuccess: isSuccess, }, });
};

// eslint-disable-next-line jsdoc/require-param
/**
 * check if the user has an actve TM / HTZ / HDC product
 */
const getUserType = ({ isPaying, }) => {
  return isPaying;
};

export {
  getPhoneNum,
  savePhoneNum,
  getUser,
  getType,
  getUserData,
  getHostname,
  getReferrer,
  saveUserData,
  getErrors,
  getOtpHash,
  saveOtpHash,
  getEmail,
  getPhoneEmailConfirmation,
  saveUserEmail,
  getDataFromUserInfo,
  mockDataFromUserInfo,
  generateOtp,
  connectMailWithPhone,
  validateMailWithPhone,
  sendMailConfirmation,
  validateMailConfirmation,
  retrieveHash,
  getUserProducts,
  isEnterWithSms,
  saveIsEnterWithSms,
  isEntitlement,
  saveLoginSuccess,
  isLoginSuccess,
  getFirstName,
  getUserType,
  saveEntitlementCheckData,
  getEntitlementData,
  saveEntitlementData,
};
