import config from 'config';

const assetPrefix = config.has('assetPrefix') ? config.get('assetPrefix') : '';


const fallbackFont = '"Helvetica Neue",tahoma,Helvetica,Arial,sans-serif';
const fallbackFontHdc = '"Helvetica Neue", Helvetica,Arial, sans-serif';

const fontStacks = site => (site === 'hdc'
  ? Object.freeze({
    webfonts: [
      [
        'Merriweather',
        [ `${assetPrefix}/static/fonts/merriweatherLatin-300.woff2`, `${assetPrefix}/static/fonts/merriweatherLatin-300.woff`, ],
        { fontWeight: 200, fontDisplay: 'fallback', },
      ],
      [
        'Merriweather',
        [ `${assetPrefix}/static/fonts/merriweatherLatin-300.woff2`, `${assetPrefix}/static/fonts/merriweatherLatin-300.woff`, ],
        { fontWeight: 300, fontDisplay: 'fallback', },
      ],
      [
        'Merriweather',
        [
          `${assetPrefix}/static/fonts/merriweatherLatin-regular.woff2`,
          `${assetPrefix}/static/fonts/merriweatherLatin-regular.woff`,
        ],
        { fontWeight: 400, fontDisplay: 'fallback', },
      ],
      [
        'Merriweather',
        [ `${assetPrefix}/static/fonts/merriweatherLatin-700.woff2`, `${assetPrefix}/static/fonts/merriweatherLatin-700.woff`, ],
        { fontWeight: 700, fontDisplay: 'fallback', },
      ],
    ],
    base: `${fallbackFontHdc}`,
    alt: fallbackFontHdc,
    commercial: fallbackFontHdc,
  })
  : Object.freeze({
    webfonts: [
      [
        'Open Sans Hebrew',
        [ `${assetPrefix}/static/fonts/OpenSansHebrewLight.woff2`, `${assetPrefix}/static/fonts/OpenSansHebrewLight.woff`, ],
        { fontWeight: 200, fontDisplay: 'fallback', },
      ],
      [
        'Open Sans Hebrew',
        [
          `${assetPrefix}/static/fonts/OpenSansHebrewRegular.woff2`, `${assetPrefix}/static/fonts/OpenSansHebrewRegular.woff`, ],
        { fontWeight: 400, fontDisplay: 'fallback', },
      ],
      [
        'Open Sans Hebrew',
        [ `${assetPrefix}/static/fonts/OpenSansHebrewBold.woff2`, `${assetPrefix}/static/fonts/OpenSansHebrewBold.woff`, ],
        { fontWeight: 700, fontDisplay: 'fallback', },
      ],
    ],
    base: `"Open Sans Hebrew",${fallbackFont}`,
    alt: fallbackFont,
    commercial: fallbackFont,
  }));

export default fontStacks;
