import GET_HOST from '../queries/getHostname.graphql';
import {
  generateOtp,
  getDataFromUserInfo,
  getEmail,
  getUserData,
  saveOtpHash,
  saveUserData,
} from '../queryutil/userDetailsOperations';
import objTransform from './objectTransformationUtil';
import { extractHostname, getSiteByHost, getTextByHost } from './siteUtil';

const getHost = client => client.readQuery({ query: GET_HOST, }).hostname.match(/^(?:.*?\.)?(.*)/i)[1];

const checkForPhoneMailConnect = userData => {
  const transformed = objTransform({ user: userData, });
  return !!transformed.user.isPhoneConnectedWithEmail;
};

const updateWithServerIfNeeded = ({ client, userData, site, }) => new Promise(resolve => {
  if (!userData.userStatus.isPhoneEmailConn) {
    resolve(
      getDataFromUserInfo(client)(getEmail(client), site)
        .then(res => saveUserData(client)({ userData: res.user, }).userData)
    );
  }
  else {
    resolve(userData);
  }
});

const generateOtpErrorMessage = ({ msg, }) => {
  if (msg.includes('sms')) {
    return 'עקב מספר נסיונות כושלים לא ניתן להיכנס כעת.  אנא נסו שנית בעוד 20 דקות.';
  }
  return msg || 'אירעה שגיאה, אנא נסה שנית מאוחר יותר.';
};

const generateOtpHash = ({
  client,
  generationPredicate,
  userMail,
  successCallback,
  failCallback,
}) => shouldGenerate => {
  const host = extractHostname(getHost(client));
  if (shouldGenerate && generationPredicate()) {
    generateOtp(client)(userMail)
      .then(data => {
        saveOtpHash(client)({ otpHash: data.data.generateOtp.hash, });
        return data.data.generateOtp;
      })
      .then(successCallback, failCallback);
  }
  else {
    failCallback({
      msg: getTextByHost({
        host,
        he: 'לא ביצעתם אימות של הטלפון דרך המייל שנשלח אליכם',
        en: 'You have not verified the phone through the email sent to you',
      }),
    });
  }
};

const handleGenerateOtpIO = ({
  client,
  userMail,
  miscOperations,
  generationPredicate = () => true,
  successCallback,
  failCallback,
}) => () => {
  miscOperations();
  const userData = getUserData(client);
  const host = extractHostname(getHost(client));
  const site = getSiteByHost(host);

  updateWithServerIfNeeded({ client, userData, site, })
    .then(checkForPhoneMailConnect)
    .then(generateOtpHash({
      client,
      generationPredicate,
      userMail,
      successCallback,
      failCallback,
    }));
};

export { getHost, handleGenerateOtpIO, generateOtpErrorMessage, };
