// @flow
/* --------------------------------- */
/* Styled Components for Login Pages */
/* --------------------------------- */

import * as React from 'react';
import { createComponent, useFela, } from 'react-fela';
import config from 'config';
import { isEngSite, } from '../../util/siteUtil';

const assetPrefix = (config.has('assetPrefix') ? config.get('assetPrefix') : '') || '../..';

// Functions -------------------------------------------
const getColors = host => ({
  main: host === 'themarker.com' ? '#008838' : '#0b7eb5',
  secondry: host === 'themarker.com' ? '#00c800' : '#006B96',
  light: host === 'themarker.com' ? '#00c800' : '#acd2ed',
  links: host === 'themarker.com' ? '#00c800' : '#0895c3',
  border: host === 'themarker.com' ? '#206F1B' : '#289dd3',

  headerMain: host === 'themarker.com' ? '#2D2D2D' : '#289dd3',

  type: host === 'themarker.com' ? 'tm' : 'htz',
});

// Styles ----------------------------------------------
const Styles = {
  MiscLayout: {
    inputLinkButton: ({ links, }) => () => ({
      fontSize: '16px',
      color: links,

      '> button': {
        float: 'left',
        marginTop: '-20px',
        cursor: 'pointer',
        fontSize: '2rem',
        fontFamily: 'Open Sans Hebrew,"Helvetica Neue",Helvetica,Arial,sans-serif',
      },
    }),
    topSpacerStyle: (misc = { spacing: 1, }) => () => ({
      paddingTop: `${misc.spacing}rem`,
    }),
  },

  Dialog: {
    dialogWrapperStyle: ({ light, }) => () => ({
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.7)',
      zIndex: '100',
    }),
    dialogContentStyle: ({ light, }) => () => ({
      position: 'relative',
      width: '450px',
      maxWidth: '90%',
      margin: '0 auto',
      padding: '30px 15px 20px 15px',
      textAlign: 'center',
      backgroundColor: '#ffffff',
      border: `solid 1px ${light}`,
    }),
    closeButtonStyle: ({ type, }) => () => ({
      position: 'absolute',
      top: '7px',
      left: '7px',
      width: '14px',
      height: '14px',
      '> button': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        margin: '0',
        lineHeight: '14px',
        left: '0',
        top: '0',
        backgroundImage: `url(${assetPrefix}/static/images/close-${type}.png)`,
      },
    }),
  },
};

function TermsWrapper({ children, }: { children: React.Node, }) {
  const { css, theme, } = useFela();

  return (
    <div
      className={css({
        lineHeight: '17px',
        '& a': {
          color: `${theme.color('link', 'base')} !important`,
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        '& div': {
          fontSize: '1.8rem',
          '@media (max-width: 768px)': {
            fontSize: '2.2rem',
          },
        },
      })}
    >
      {children}
    </div>
  );
}

function TabsFormWrapper({ children, host, }: { children: React.Node, host: String, }) {
  const { css, theme, } = useFela();
  const textAlign = theme.direction === 'ltr' ? 'left' : 'right';

  return (
    <div
      className={css({
        width: '550px',
        maxWidth: '100%',
        margin: '0 auto',
        paddingTop: '6%',
        borderRight: `0.5px solid ${theme.color('tabsForm', 'border')}`,
        borderBottom: `0.5px solid ${theme.color('tabsForm', 'border')}`,
        borderLeft: `0.5px solid ${theme.color('tabsForm', 'border')}`,

        '&>form': {
          maxWidth: '91%',
          margin: '0 auto',
        },
        '&>form>div': {
          paddingBottom: '1rem',
        },
        '& label': {
          fontSize: '2rem',
          marginTop: '2rem !important',
        },
        '& input[type="email"]': {
          direction: 'ltr',
          textAlign,
        },
        '& input[type="tel"]': {
          direction: 'ltr',
          textAlign,
        },
        '& h5': {
          fontSize: '2.3rem',
        },

        '@media (max-width: 768px)': {
          // maxWidth: '85%',
          maxWidth: !isEngSite(host) ? '85%' : undefined,
          '& label': {
            fontSize: '2.7rem',
          },
          '& h5': {
            width: '100%',
            fontSize: '3rem',
          },
        },
      })}
    >
      {children}
    </div>
  );
}

// Components ------------------------------------------
const LoginContentStylesThemed = () => ({
  TabsFormWrapper,
});

const LoginMiscLayoutStylesThemed = (host: string, misc: { spacing: number, }) => {
  const colors = getColors(host);
  return {
    InputLinkButton: createComponent(Styles.MiscLayout.inputLinkButton(colors)),
    TermsWrapper,
    TopSpacer: createComponent(Styles.MiscLayout.topSpacerStyle(misc)),
  };
};

const LoginDialogBoxThemed = (host: string) => {
  const colors = getColors(host);
  return {
    DialogWrapper: createComponent(Styles.Dialog.dialogWrapperStyle(colors)),
    DialogContent: createComponent(Styles.Dialog.dialogContentStyle(colors)),
    CloseButton: createComponent(Styles.Dialog.closeButtonStyle(colors)),
  };
};

// Export ----------------------------------------------
export { LoginContentStylesThemed, LoginMiscLayoutStylesThemed, LoginDialogBoxThemed, };
