import React, { Fragment, } from 'react';
import { createApp, UserInjector, userScheme, } from '@haaretz/htz-components';
import { withApollo, } from '@haaretz/app-utils';

const initialState = () => ({
  articleId: null,
  pageType: 'Login',
  currentState: null,
  historyPointer: null,
  isEnterWithSms: false,
  isEntitlement: false,
  entitlementStatus: null,
  entitlementSubsNo: null,
  loginSuccess: false,
  userEmail: null,
  stateHistory: {
    pastState: null,
    pastTransition: null,
    __typename: 'StateHistory',
  },
  userData: {
    facebook: {
      redirect: null,
      token: null,
      utm: null,
      amp: null,
      __typename: 'facebookLogin',
    },
    firstName: null,
    mobilePhone: null,
    ssoId: null,
    type: null,
    userCrmStatus: null,
    userLegalBySite: [],
    isPaying: false,
    termsCheck: false,
    userStatus: {
      isMailValidated: false,
      isMobileValidated: false,
      isPhoneEmailConn: false,
      __typename: 'UserStatus',
    },
    __typename: 'SsoUser',
  },
  htzUser: userScheme,
});


// eslint-disable-next-line react/prop-types
function AppWrapper({ children, }) {
  return (
    <Fragment>
      <UserInjector />
      {children}
    </Fragment>
  );
}


export default withApollo({ ssr: true, })(createApp(null, AppWrapper), initialState);
