/* global window */
import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import log from 'loglevel';
import Query from '../ApolloBoundary/Query';
import GoogleAnalyticsInit from './GoogleAnalyticsInit';

const GET_HOST_USER = gql`
  query GetHostAndUserForGoogleAnalytics {
    hostname @client
    user: htzUser @client {
      type
    }
    googleAnalyticsId @client {
      htzUa
      tmUa
      hdcUa
    }
  }
`;

GoogleAnalytics.propTypes = {
  // enable ecommerce features
  withEC: PropTypes.bool,
  // Set true to Track pageView manually
  withPageView: PropTypes.bool,
};

GoogleAnalytics.defaultProps = {
  withEC: false,
  withPageView: false,
};

function GoogleAnalytics({ withEC, withPageView, }) {
  return (
    <Query query={GET_HOST_USER}>
      {({ data, loading, error, }) => {
        if (error) {
          log.error(error);
          return null;
        }
        if (loading) return null;
        const {
          hostname,
          user,
          googleAnalyticsId,
        } = data;
        const host = hostname.includes('themarker.com')
          ? 'themarker.com'
          : hostname.includes('haaretz.com')
            ? 'haaretz.com'
            : 'haaretz.co.il';
        // GaHost has defaults with test env id's, and get the production id's from ENV variables
        // see createClient in app-utils for implementation
        const GaHost = googleAnalyticsId[
          hostname.includes('themarker.com')
            ? 'tmUa'
            : hostname.includes('haaretz.com')
              ? 'hdcUa'
              : 'htzUa'
        ];

        if (!host || (!user?.type && !hostname.includes('login'))) {
          return null;
        }

        return (
          <GoogleAnalyticsInit
            GaHost={GaHost}
            host={host}
            userType={user.type}
            withEC={withEC}
            withPageView={withPageView}
          />
        );
      }}
    </Query>
  );
}

export default GoogleAnalytics;
