/* eslint-disable react/no-did-mount-set-state */

import React, { Fragment, } from 'react';
// import { ApolloConsumer, } from 'react-apollo';
import {
  GoogleAnalytics,
  EventTracker,
  UserDispenser,
  ApolloConsumer,
  BIRequest,
  Logout,
} from '@haaretz/htz-components';
import { StyleProvider, } from '@haaretz/fela-utils';
import HeaderBySite from '../layouts/HeaderBySite';
import FooterNew from '../layouts/FooterNew';
import styleRenderer from '../components/styleRenderer/styleRenderer';
import theme from '../theme/index';
import GET_HOST from '../queries/getHostname.graphql';
import FlowDispenser from '../components/FlowDispenser/FlowDispenser';
import IndexForm from '../components/Misc/Forms/IndexForm';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';

function Index() {
  return (
    <Fragment>
      <GoogleAnalytics withEC withPageView />
      <BIRequest pageType="login" />
      <ApolloConsumer>
        {client => {
          const host = client.readQuery({ query: GET_HOST, }).hostname;
          const site = host.includes('themarker.com')
            ? 'tm'
            : host.includes('haaretz.com')
              ? 'hdc'
              : 'htz';
          return (
            <StyleProvider renderer={styleRenderer} theme={theme(site)}>
              <Fragment>
                <UserDispenser
                  render={({ isLoggedIn, user, }) => (
                    <EventTracker>
                      {({ biAction, gaAction, gaMapper, }) => (
                        <PageWrapper>
                          <HeaderBySite site={site} />
                          <ContentWrapper>
                            <Logout
                              render={({ logoutWithoutReload: logout, }) => (
                                <FlowDispenser
                                  render={({ getFlowByData, }) => (
                                    <IndexForm
                                      client={client}
                                      getFlowByData={getFlowByData}
                                      biAction={biAction}
                                      gaAction={gaAction}
                                      userDispenser={{ isLoggedIn, user, }}
                                      logout={logout}
                                    />
                                  )}
                                />
                              )}
                            />
                          </ContentWrapper>
                          <FooterNew site={site} />
                        </PageWrapper>
                      )}
                    </EventTracker>
                  )}
                />
              </Fragment>
            </StyleProvider>
          );
        }}
      </ApolloConsumer>
    </Fragment>
  );
}

export default Index;
