/* global Event window document */
import React, { useState, useEffect, useRef, } from 'react';
import PropTypes from 'prop-types';
import { createComponent, FelaComponent, useFela, } from 'react-fela';

import debounce from 'lodash/debounce';
import { border, borderBottom, parseStyleProps, parseComponentProp, } from '@haaretz/htz-css-tools';
import { attrsPropType, } from '../../propTypes/attrsPropType';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import Abbr from './elements/Abbr';
import Button from '../Button/Button'; // Adjust the import path as needed
import IconBold from '../Icon/icons/IconBold';
import IconItalic from '../Icon/icons/IconItalic';
import InputElement from './elements/InputElement';
import Note from '../Note/Note';
import { responsivePropBaseType, } from '../../propTypes/responsivePropBaseType';
import { textInputVariantType, } from './textInputVariantType';
import correctEmailTypo from './correctEmailTypo';


// Define styled components
const labelStyle = ({
  boxModel,
  isContentEditable,
  isError,
  isFocused,
  isTextArea,
  isLabelUp,
  isDisabled,
  miscStyles,
  theme,
  variant,
}) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  ...(isTextArea || isContentEditable ? { flexDirection: 'column', alignItems: 'flex-start', } : {}),
  paddingInlineStart: `${boxModel && boxModel.hp ? boxModel.hp : 1}rem`,
  paddingInlineEnd: `${boxModel && boxModel.hp ? boxModel.hp : 1}rem`,
  width: '100%',
  ...(!isTextArea && !isContentEditable ? { position: 'relative', } : {}),
  fontFamily: theme.fontStacks[theme.framedFont],
  extend: [
    theme.type(theme.inputStyle.typeScale),
    parseComponentProp(
      undefined,
      variant,
      theme.mq,
      setVariant,
      theme.color,
      boxModel,
      isError,
      isFocused,
      theme,
      isDisabled,
      isLabelUp
    ),
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

const StyledLabel = createComponent(labelStyle, 'label', [ 'htmlFor', 'onClick', ]);

function setVariant(
  prop,
  variant,
  getColor,
  boxModel,
  isError,
  isFocused,
  theme,
  isDisabled,
  isLabelUp
) {
  const focusedStyle = {
    backgroundColor: getColor('input', `${variant}FocusBg`),
    borderColor: isError
      ? getColor('input', `${variant}ErrorBorder`)
      : getColor('input', `${variant}FocusBorder`),
  };

  return {
    color: getColor('input', `${variant}Text`),
    backgroundColor: getColor('input', `${variant}Bg`),
    ...border(
      `${theme.inputStyle.borderWidth}px`,
      boxModel && boxModel.vp ? boxModel.vp : theme.inputStyle.lines,
      theme.inputStyle.borderStyle,
      getColor('input', `${variant}Border`)
    ),
    ...(isFocused
      ? focusedStyle
      : {
        ':hover': {
          ...(!isLabelUp ? { backgroundColor: getColor('input', `${variant}HoverBg`), } : {}),
          borderColor: getColor('input', `${variant}HoverBorder`),
          color: getColor('input', `${variant}HoverText`),
          ...(isDisabled ? { cursor: 'not-allowed', } : {}),
        },
      }),
  };
}

const labelTextStyle = ({
  labelHidden,
  isContentEditable,
  isDisabled,
  isError,
  isFocused,
  isInputEmpty,
  isTextArea,
  theme,
  variant,
}) => ({
  ...(labelHidden ? { display: 'none', } : {
    whiteSpace: 'nowrap',
    marginInlineEnd: '1rem',
    color: isError ? theme.color('input', `${variant}ErrorTextLabel`) : isDisabled ? theme.color('input', `${variant}TextLabelDisabled`) : theme.color('input', `${variant}TextLabel`),
    fontWeight: theme.inputStyle.fontWeightLabel,
    transitionProperty: 'transform',
    extend: [
      { condition: isTextArea === true, style: { display: 'block', }, },
      {
        condition: !isTextArea && !isContentEditable,
        style: {
          bottom: '0.7rem',
          paddingRight: '1rem',
          paddingLeft: '1rem',
          marginInlineStart: '-1rem',
          ...(isFocused || !isInputEmpty ? {
            position: 'absolute',
            backgroundColor: theme.color('input', isFocused ? `${variant}FocusBg` : `${variant}Bg`),
            transform: 'translateY(-3.3rem) scale(.8)',
            paddingTop: '0.5rem',
          } : {}),
        },
      },
      theme.getTransition(-1, 'swiftOut'),
      {
        condition: isTextArea === true || isContentEditable === true,
        style: {
          flexGrow: 1,
          marginBottom: '1rem',
          ...borderBottom(`${theme.inputStyle.borderWidth}px`, theme.inputStyle.lines, theme.inputStyle.borderStyle, isFocused ? theme.color('input', `${variant}BorderTextLabel`) : 'transparent'),
        },
      },
    ],
  }),
});

const StyledLabelText = createComponent(labelTextStyle, 'span', [ 'id', ]);

const labelAndButtonsWrapperStyle = ({ isContentEditable, isTextArea, }) => ({
  ...(isTextArea || isContentEditable ? { width: '100%', } : {}),
  ...(isContentEditable ? { display: 'flex', justifyContent: 'space-between', } : {}),
});

const checkForEmailTypos = (email, setSuggestedEmail) => {
  const [ localPart, domain, ] = email.split('@');
  if (!domain) return null;

  const correctedDomain = correctEmailTypo(domain.toLowerCase());
  if (correctedDomain === domain.toLowerCase()) return null;

  const correctedEmail = `${localPart}@${correctedDomain}`;

  setSuggestedEmail(correctedEmail);
  return correctedEmail;
};

const checkForEmailTyposDebounced = debounce(checkForEmailTypos, 500);


const StyledLabelAndButtonsWrapper = createComponent(labelAndButtonsWrapperStyle);

function TextInput({
  attrs,
  boxModel,
  defaultValue,
  errorText,
  labelHidden,
  height,
  noteText,
  linkAfterText,
  isContentEditable,
  isError,
  isTextArea,
  isDisabled,
  label,
  maxLength,
  minLength,
  miscStyles,
  isCommentForm,
  wrapperStyle,
  onBlur,
  onChange,
  onContentEditableChange,
  onFocus,
  placeholder,
  refFunc,
  requiredText,
  type,
  value: propsValue,
  variant,
  noteStyle,
  icon,
  inputId: propsInputId,
  noteId: propsNoteId,
  labelId: propsLabelId,
}) {
  const { theme, } = useFela();
  const inputId = useRef(propsInputId || `input_${Math.random().toString(36).substr(2, 9)}`);
  const noteId = useRef(propsNoteId || (errorText || noteText ? `note_${Math.random().toString(36).substr(2, 9)}` : null));
  const labelId = useRef(propsLabelId || `label_${Math.random().toString(36).substr(2, 9)}`);
  const inputRef = useRef(null);
  const [ value, setValue, ] = useState(propsValue !== undefined ? propsValue : defaultValue || '');
  const [ isInputEmpty, setIsInputEmpty, ] = useState(!value);
  const [ isFocused, setIsFocused, ] = useState(false);
  const [ suggestedEmail, setSuggestedEmail, ] = useState(null);
  const [ boldActive, setBoldActive, ] = useState(false);
  const [ italicActive, setItalicActive, ] = useState(false);

  useEffect(() => {
    if (propsValue !== undefined) {
      setValue(propsValue);
      setIsInputEmpty(!propsValue);
    }
  }, [ propsValue, ]);


  const onInputChange = evt => {
    const email = evt.target.value;
    if (type === 'email') {
      checkForEmailTyposDebounced(email, setSuggestedEmail);
    }
    setValue(email);
    setIsInputEmpty(email.length === 0);
    if (onChange) onChange(evt);
  };


  const applySuggestedEmail = evt => {
    evt.preventDefault();
    const newValue = suggestedEmail;

    setValue(newValue);
    setSuggestedEmail(null);
    setIsInputEmpty(newValue.length === 0);

    if (onChange) {
      const syntheticEvent = {
        target: { name: inputRef.current.name, value: newValue, },
        currentTarget: { name: inputRef.current.name, value: newValue, },
        persist: () => {}, // mock the persist method to avoid errors
      };
      onChange(syntheticEvent);
    }

    if (inputRef.current) {
      inputRef.current.value = newValue;
      inputRef.current.focus();
    }
  };

  const handleInputFocusBlur = focused => {
    setIsFocused(focused);
    if (focused && onFocus) onFocus();
    else if (!focused && onBlur) onBlur();
  };


  const isLabelUp = !isTextArea && !isContentEditable && (isFocused || !isInputEmpty);

  const handleRef = el => {
    inputRef.current = el;
    const refFunctionOrObject = refFunc;
    if (typeof refFunctionOrObject === 'function') refFunctionOrObject(el);
    else if (refFunctionOrObject && 'current' in refFunctionOrObject) refFunctionOrObject.current = el;
  };

  const toggleCommand = command => {
    document.execCommand(command);
    if (command === 'bold') setBoldActive(!boldActive);
    else if (command === 'italic') setItalicActive(!italicActive);
    handleInputFocusBlur(true);
  };

  const currentNoteText = isError ? errorText : suggestedEmail ? theme.commonI18n.correctEmailTypoText : noteText;
  const currentLinkAfterText = isError ? null : suggestedEmail ? { link: '', text: suggestedEmail, onClick: applySuggestedEmail, suffixText: '?', } : linkAfterText;

  return (
    <FelaComponent style={wrapperStyle}>
      <StyledLabel
        htmlFor={inputId.current}
        variant={variant}
        miscStyles={miscStyles}
        boxModel={boxModel}
        isError={isError}
        isFocused={isFocused}
        isContentEditable={isContentEditable}
        isTextArea={isTextArea}
        isDisabled={isDisabled}
        isLabelUp={isLabelUp}
      >
        <StyledLabelAndButtonsWrapper isContentEditable={isContentEditable} isTextArea={isTextArea}>
          <StyledLabelText
            labelHidden={labelHidden}
            isContentEditable={isContentEditable}
            isDisabled={isDisabled}
            isError={isError}
            isFocused={isFocused}
            isInputEmpty={isInputEmpty}
            isTextArea={isTextArea}
            variant={variant}
            {...(isContentEditable ? { id: labelId.current, } : {})}
          >
            {label}
            {requiredText ? (
              <Abbr miscStyles={requiredText.miscStyles} requiredText={{ long: requiredText.long, short: requiredText.short, }} variant={variant} />
            ) : null}
          </StyledLabelText>
          {isContentEditable && (
            <FelaComponent style={{ direction: 'ltr', }}>
              <Button
                variant={boldActive ? 'primaryOpaque' : 'formattingOpaque'}
                attrs={{
                  'aria-checked': boldActive,
                  'aria-label': 'bold',
                  onMouseDown: e => {
                    e.preventDefault();
                    toggleCommand('bold');
                  },
                  role: 'switch',
                }}
                boxModel={{ hp: 0, vp: 0, }}
                miscStyles={{ display: isFocused ? 'initial' : 'none', height: '5rem', marginInlineEnd: '1rem', width: '5rem', }}
              >
                <IconBold />
              </Button>
              <Button
                variant={italicActive ? 'primaryOpaque' : 'formattingOpaque'}
                attrs={{
                  'aria-checked': italicActive,
                  'aria-label': 'italic',
                  onMouseDown: e => {
                    e.preventDefault();
                    toggleCommand('italic');
                  },
                  role: 'switch',
                }}
                boxModel={{ hp: 0, vp: 0, }}
                miscStyles={{ display: isFocused ? 'initial' : 'none', height: '5rem', width: '5rem', }}
              >
                <IconItalic />
              </Button>
            </FelaComponent>
          )}
        </StyledLabelAndButtonsWrapper>
        <InputElement
          ariaDescribedBy={noteId.current}
          ariaLabel={labelHidden ? label : null}
          attrs={attrs}
          defaultValue={defaultValue}
          height={isTextArea || isContentEditable ? height : null}
          inputId={inputId.current}
          isContentEditable={isContentEditable}
          isDisabled={isDisabled}
          isFocused={isFocused}
          isTextArea={isTextArea}
          onBlur={() => handleInputFocusBlur(false)}
          onFocus={() => handleInputFocusBlur(true)}
          onChange={!isContentEditable ? onInputChange : null}
          placeholder={placeholder}
          refFunc={handleRef}
          type={type}
          value={value}
          {...(isContentEditable
            ? {
              onContentEditableChange,
            }
            : {})}
        />
        {icon}
      </StyledLabel>
      {(isError || currentNoteText || currentLinkAfterText) && (
        <Note
          text={currentNoteText}
          linkAfterText={currentLinkAfterText}
          isError={isError}
          noteId={noteId.current}
          variant={variant}
          isCommentForm={isCommentForm}
          miscStyles={noteStyle}
        />
      )}
    </FelaComponent>
  );
}

TextInput.propTypes = {
  attrs: attrsPropType,
  boxModel: PropTypes.shape({
    /** horizontal padding */
    hp: PropTypes.number,
    /** vertical padding */
    vp: PropTypes.number,
  }),
  defaultValue: PropTypes.string,
  errorText: PropTypes.oneOfType([ PropTypes.string, PropTypes.element, PropTypes.node, ]),
  labelHidden: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: PropTypes.number.isRequired,
      })
    ),
  ]),
  noteText: PropTypes.oneOfType([ PropTypes.string, PropTypes.element, PropTypes.node, ]),
  linkAfterText: PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  isContentEditable: PropTypes.bool,
  isError: PropTypes.bool,
  isTextArea: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  miscStyles: stylesPropType,
  isCommentForm: PropTypes.bool,
  wrapperStyle: PropTypes.oneOfType([ PropTypes.func, PropTypes.object, ]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onContentEditableChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  refFunc: PropTypes.func,
  requiredText: PropTypes.shape({
    isSup: PropTypes.bool,
    long: PropTypes.string.isRequired,
    short: PropTypes.string.isRequired,
    miscStyles: stylesPropType,
  }),
  type: PropTypes.oneOf([ 'email', 'number', 'password', 'search', 'tel', 'text', 'url', ]),
  value: PropTypes.string,
  variant: PropTypes.oneOfType([
    textInputVariantType,
    PropTypes.arrayOf(
      PropTypes.shape({
        ...responsivePropBaseType,
        value: textInputVariantType.isRequired,
      })
    ),
  ]),
  noteStyle: stylesPropType,
  icon: PropTypes.element,
  inputId: PropTypes.string,
  noteId: PropTypes.string,
  labelId: PropTypes.string,
  toggleCommandBiCallBack: PropTypes.func,
};

TextInput.defaultProps = {
  attrs: null,
  boxModel: null,
  defaultValue: '',
  errorText: null,
  labelHidden: false,
  height: null,
  noteText: null,
  linkAfterText: null,
  isContentEditable: false,
  isError: false,
  isTextArea: false,
  isDisabled: false,
  maxLength: null,
  minLength: null,
  miscStyles: null,
  isCommentForm: false,
  wrapperStyle: {},
  onBlur: null,
  onChange: null,
  onContentEditableChange: null,
  onFocus: null,
  placeholder: null,
  refFunc: undefined,
  requiredText: null,
  type: 'text',
  value: undefined,
  variant: 'primary',
  noteStyle: null,
  icon: null,
  inputId: null,
  noteId: null,
  labelId: null,
  toggleCommandBiCallBack: null,
};

export default TextInput;
