// @flow
/* eslint-disable react/no-danger */
import React from 'react';
import { useFela, } from 'react-fela';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, ComponentPropResponsiveObject, } from '@haaretz/htz-css-tools';

type Props = {
  size: ?number | ComponentPropResponsiveObject<number>[],
  miscStyles: ?StyleProps,
};

const style = ({ theme, size, miscStyles, }) => ({
  extend: [
    parseComponentProp('size', size, theme.mq, setSize),
  ],
});

const style2 = ({ theme, size, miscStyles, }) => ({
  extend: [
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

function setSize(size, value) {
  return {
    fontSize: `${value}rem`,
  };
}

export default function Diver({ size, miscStyles, }: Props) {
  // TODO missing isomorphic ID solution is missing: https://github.com/reactjs/rfcs/pull/32
  const [ idSuffix, ] = React.useState('');
  const { css, } = useFela({ size, miscStyles, });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 545 515"
      width="1em"
      height="0.9449541284em"
      className={css(style, style2)}
    >
      <path
        fill="#5DC6FF"
        d="M276.365 255.584s-4.731 1.384-3.707 5.322 3.332 4.803 6.505 3.361c3.173-1.443 4.99-8.395-2.798-8.683zM264.742 171.626s-.923 3.865 2.235 2.812c3.159-1.053-.201-5.077-2.235-2.812zM254.287 232.317s-1.817 6.952 3.303 5.582c5.119-1.371 2.697-9.649-3.303-5.582zM368.606 237.352s-4.916 7.952 2.123 7.433c7.038-.519 2.247-9.611-2.123-7.433zM232.755 197.066s-7.673 7.125.057 8.971c7.731 1.846 10.154-10.932-.057-8.971zM332.596 244.616c-4.03 2.634-4.976 8.004 1.283 7.457s4.784-11.422-1.283-7.457zM297.107 225.554s-2.452 3.029 1.471 2.394c3.923-.634 1.717-4.226-1.471-2.394zM379.718 227.23s-3.242 4.201 1.05 4.978c4.293.776 2.718-6.69-1.05-4.978zM287.297 192.891s-3.242 4.201 1.05 4.978c4.293.776 6.08-7.13-1.05-4.978zM246.884 251.05c-1.397-.001-5.731 4.726-.822 5.526 4.909.799 4.598-5.523.822-5.526zM320.673 167.339s-3.927 6.028 1.69 4.384 1.484-6.736-1.69-4.384zM360.888 177.374c-3.389-3.245-7.211-5.337-13.168-2.438 0 0-6.346 2.884-5.481 11.105.865 8.221 10.211 8.567 15.36 6.418 5.149-2.148 6.678-11.84 3.289-15.085zm-4.473 1.309c-3.165-.515-1.104-2.429-1.104-2.429 3.563.53 4.27 2.944 1.104 2.429zM215.188 214.055s-5.733 6.932 1.117 11.089c9.237 5.606 14.478-15.45-1.117-11.089zm8.291 5.434c-2.092.951-1.914-3.612-2.341-4.667a21.343 21.343 0 0 0-.264-.207c.112-.051.196.037.264.207.997.795 4.433 3.716 2.341 4.667zM308.311 205.364s-7.673 7.125.057 8.971c7.731 1.846 10.154-10.933-.057-8.971zm3.464 3.975c-2.002.324.088-2.385.088-2.385 2.827-1.09 1.914 2.061-.088 2.385z"
      />
      <path
        fill="#146F89"
        d="M358.478 439.018s19.323-5.221 24.049-6.13c6.13-1.179 16.504-3.065 16.504-3.065s50.726-8.488 46.448-8.488-43.315-2.139-43.315-2.139-61.261 15.112-53.239 17.251 3.187 2.336 3.187 2.336"
        opacity=".35"
      />
      <path
        fill="none"
        stroke="#1D1D1B"
        strokeMiterlimit="10"
        strokeWidth=".75"
        d="M360.141 425.396c1.657.028 18.791-.231 25.944 1.55s-6.667 2.096-.047 2.762 30.392-.032 25.392 1.54"
      />
      <path
        fill="#FFF"
        stroke="#1D1D1B"
        strokeMiterlimit="10"
        strokeWidth=".75"
        d="M459.152 396.584c1.657.028 18.791-.231 25.944 1.55s-6.667 2.096-.047 2.762c6.619.666 30.392-.032 25.392 1.54M346.059 442.655c1.657.028 18.791-.23 25.944 1.55 7.153 1.78-6.667 2.096-.047 2.762s30.392-.032 25.392 1.54M275.641 474.481c1.657.028 18.791-.23 25.944 1.55 7.153 1.78-6.667 2.096-.047 2.762s30.392-.032 25.392 1.54M244.641 488.481c1.657.028 18.791-.23 25.944 1.55 7.153 1.78-6.667 2.096-.047 2.762s23.038.263 26.882.069M7.42 467.862c1.657.028 21.013.389 28.166 2.169 7.153 1.78-6.667 2.096-.047 2.762s23.038.263 26.882.069M195.337 499.481c1.657.028 18.791-.23 25.944 1.55 7.153 1.78-6.667 2.096-.047 2.762 6.619.666 23.038.263 26.882.069 0 0 2 2 14.667 1.333s31.333 2 31.333 2M37.308 495.481c1.657.028 18.791-.23 25.944 1.55 7.153 1.78-6.667 2.096-.047 2.762s23.038.263 26.882.069c0 0 2 2 14.667 1.333s37.333 3.333 37.333 3.333"
      />
      <path
        fill="none"
        stroke="#1D1D1B"
        strokeMiterlimit="10"
        strokeWidth=".75"
        d="M296.695 470.076c1.142.032 8.186 1.601 9.551 1.867M333.666 471.302l9.159.75M338.004 464.601l6.822 1.334M339.876 463.052l3.649.331M368.34 484.752s-9.211-1.646-6.514 1.207c2.697 2.852 9.289-1.13 6.514-1.207zM323.732 490.91s-9.25-.259-6.064 1.682c3.185 1.941 11.113-.152 6.064-1.682z"
      />
      <g>
        <path
          fill="#438CA1"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M338.549 345.974s-7.182-15.47-13.26-15.47c-6.077 0-75.691 39.779-71.823 43.094s85.083-27.624 85.083-27.624"
        />
        <path
          fill="#8AC5D6"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M244.087 239.862s-19.678 23.335-60.234 44.786c-36.934 19.536-39.666 38.115 2.41 27.186 26.437-6.867 67.436-33.133 77.651-46.574"
        />
        <path
          fill="#575756"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M272.294 325.142a9.07 9.07 0 0 1-4.994-1.459c-8.002-5.188 18.74-22.113 18.21-46.217-.23-10.471-28.311-26.723-30.687-29.131-.101.04-.249.11-.449.233l-3.148-5.108c2.823-1.74 5.008-1.238 6.342-.512 5.281 2.874 33.496 14.12 33.942 34.386.353 16.033-26.9 37.453-20.945 41.314.676.439 1.851.904 4.22-.093 4.453-1.875 13.651-12.163 25.217-59.988 7.442-30.779 13.141-65.484 16.546-86.22 1.785-10.87 2.667-16.172 3.201-17.909 3.169-10.3 27.281-7.409 34.755-6.391l-.5 4c-15.802-2.149-27.616 1.212-28.521 4.156-.438 1.424-1.575 8.349-3.015 17.117-3.419 20.818-9.14 55.662-16.635 86.658-6.012 24.86-15.529 58.554-28.721 64.108-1.813.762-3.419 1.056-4.818 1.056z"
        />
        <path
          fill="#575756"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M353.752 147.046s-10.25-3.75-11.5-2.5-3 4-2.25 6 1.25 3.5 2.75 3.5 11.25-2 11.25-2"
        />
        <path
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M182.154 256.71s-11.116-9.946-11.116-3.51 4.68 14.041 4.68 14.041-10.531 0-6.435 5.265c4.095 5.265 8.19 11.116 8.19 11.116s-15.211 7.605-7.605 8.19c7.605.585 18.136 8.19 21.061 6.435 2.925-1.755-.585-7.02-.585-7.02s9.946 5.85 8.776 2.925-10.327-13.661-10.327-13.661 8.222 7.011 7.812.39-12.696-20.661-14.451-24.171z"
        />
        <defs>
          <path
            id={`a${idSuffix}`}
            d="M323.762 193.165s-1.331-33.333 33.336-33.333c22.676 0 28.523 25.521 28 42.667-.265 8.688 1.998 109.999-6.669 112.666-8.667 2.667-54.667 3.333-57.333-1.333s1.999-112.667 2.666-120.667z"
          />
        </defs>
        <use fill="#34B2D6" overflow="visible" xlinkHref={`#a${idSuffix}`} />
        <clipPath id={`b${idSuffix}`}>
          <use overflow="visible" xlinkHref={`#a${idSuffix}`} />
        </clipPath>
        <path
          fill="#438CA1"
          d="M369.752 163.379s-16.079 3.957-13.999 6.816c5.333 7.333-1 27.667-1 27.667s9.522 5.973 12 8c29.333 24 25.5 42.333 24 41.333s-63-38-63-38l1.649-45.689 26.247-7.48 14.103 7.353z"
          clipPath={`url(#b${idSuffix}`}
          opacity=".56"
        />
        <use
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          overflow="visible"
          xlinkHref={`#a${idSuffix}`}
        />
        <defs>
          <path
            id={`c${idSuffix}`}
            d="M265.752 202.296s21.748-21.42 64.969-1.583c0 0 43.703-.581 55.364 45.999 5.833 23.301 6.478 49.399 6 58-.667 12-10.155 69.816-10.155 69.816s2.207-6.843 14.333-16.667c13.167-10.667 17.667-13 17.667-13l26.583-29.167 5.125-6.25s6.647-8.207 9.958-8.917c1.662-.356 15-1.333 18.667 3 .416.491-1.918 48.734-4.273 68.038-.447 3.662-2.039 22.17-2.294 22.936-.392 1.175-.534 3.302-.868 5.143-.21 1.157-2.116 6.823 1.471 6.818 5.353-.007 10.929-2.186 13.661-2.186 14.304 0 20.306 5.733 18.34 6.261-22.652 6.077-35.179 12.231-41.35 11.634s-65.743-1.609-62.525-5.017c9.392-9.945 38.147-60.27 36.608-58.338-1.728 2.17-33.853 48.644-70.548 78.551-5.552 4.525-10.895 4.585-15.561-1.415-5.86-7.535-8.172-117.908-31.505-117.241-13.722.392-64.368 88.613-75.335 90-27.304 3.451 32.883-106.998 32.883-106.998s-2.139-22.46 14.45-37.002c9.63-8.442-37.385-24.041-46.434-37.05-9.36-13.455 4.769-21.365 24.769-25.365z"
          />
        </defs>
        <use fill="#8AC5D6" overflow="visible" xlinkHref={`#c${idSuffix}`} />
        <clipPath id={`d${idSuffix}`}>
          <use overflow="visible" xlinkHref={`#c${idSuffix}`} />
        </clipPath>
        <path
          fill="#438CA1"
          d="M370.256 271.47s.893-2.448 3.915 1.74c7.288 10.099 3.11 7.853 15.916 4.651 5.333-1.333-9.39 8.399-9.39 8.399s4.057 25.601 4.057 32.268c0 26-16.283 97.66-10.95 98.327 5.333.667 77.794-90.327 82.461-86.993 7.824 5.588-80.178 108.667-84.844 109.333-4.667.667-132 6.667-132 6.667s21.333-136.667 23.333-138c2-1.333 4.123-32.298 4.123-32.298s27.253 8.087 66.839-15.838c0 0-4.785 8.265-1.305 6.96s-3.48 6.96-.87 6.09c0 0-2.175 8.7 1.74 6.96s5.655-2.175 5.655-2.175-29.277 10.309-27.406 12.18c6.09 6.09 29.581 3.045 43.501-7.395 9.075-6.806 8.7-13.05 8.7-13.05"
          clipPath={`url(#d${idSuffix}`}
          opacity=".46"
        />
        <path
          fill="#438CA1"
          d="M289.42 303.862s26.667 12.667 36.667 12c10-.667 11.333-9.333 11.333-9.333s-30.534 3.734-48-2.667zM278.753 348.528s30.666-34.966 36.666-29.816c6 5.15-16 25.15-16 25.15l-21.333 2"
          clipPath={`url(#d${idSuffix}`}
        />
        <path
          fill="#438CA1"
          d="M366.087 279.862s6.667 46.667 6.667 52.667-21.799 19.339-21.799 19.339l-1.535 28.328s36-23.667 41.333-29c5.333-5.333-6-61.333-9.333-65.333-3.333-4.001-15.333-6.001-15.333-6.001z"
          clipPath={`url(#d${idSuffix}`}
          opacity=".46"
        />
        <path
          fill="#146F89"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M387.352 344.654s-6.734 40.37-8.067 44.37l-.712 4.983c-1.333 4 4.463-4.328 3.796-1.661-.667 2.667-7.458 15.401-2.772 11.183 4.745-4.271 15-12.333 33.667-33.667 17.268-19.735 16.443-17.862 39-44l2.667-2.667-.333 5.667s-69.667 80-80.333 87.333c-3.203 2.202-.726 3.683-2.111-.519-.705-2.138 5.946-45.16 5.946-45.16.475-3.322 3.322-25.151 3.322-25.151"
          clipPath={`url(#d${idSuffix}`}
        />
        <path
          fill="#F8CC88"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M433.931 318.862s15.878-10.391 21 13.667c.857 4.026 4.583-4.833 4.583-4.833s-.583-5.833-2.917-9.167c-7.809-11.157-22.833-8.209-22.666.333z"
          clipPath={`url(#d${idSuffix}`}
        />
        <g clipPath={`url(#d${idSuffix}`}>
          <defs>
            <path
              id={`e${idSuffix}`}
              d="M456.862 291.431s-19.779 21.64-12.931 18.098c4.833-2.5 9.5.917 12.958 4.958 3.458 4.042 3.375 10.375 3.375 10.375s-1.67 3.685-2.913 4.928c-12.5 12.5-42.195 51.622-45.754 58.739-3.558 7.117-24.726 29.09-19.333 31.333 52.5 21.833 135.736-15.303 142.26-26.571 6.524-11.268-.182-51.813-1.961-55.372s-23.722-81.841-31.432-83.62c-7.71-1.779-51.595 4.151-53.374 7.117-1.779 2.965-3.141 28.598 9.105 30.015z"
            />
          </defs>
          <use fill="#34B2D6" overflow="visible" xlinkHref={`#e${idSuffix}`} />
          <clipPath id={`f${idSuffix}`}>
            <use overflow="visible" xlinkHref={`#e${idSuffix}`} />
          </clipPath>
          <path
            fill="#146F89"
            d="M460.49 325.255s-7.392 64.106-10.892 77.606-5.881 21.167-5.881 21.167l-49.619-6.667s50.444-74.282 59.017-88.46c2.193-3.626 5.935-9.232 1.649-17.97-.667-1.355 4.684 9.265 5.726 14.324z"
            clipPath={`url(#f${idSuffix}`}
            opacity=".38"
          />
          <use
            fill="none"
            stroke="#1D1D1B"
            strokeMiterlimit="10"
            overflow="visible"
            xlinkHref={`#e${idSuffix}`}
          />
        </g>
        <path
          fill="#146F89"
          d="M461.522 416.115c-.238.35-7.258 7.83 16.816-1.813 24.829-9.946 32.079-7.03 25.621-8.106-9-1.5-35.71-25.505-35.71-25.505s-1.451 27.675-6.727 35.424z"
          clipPath={`url(#d${idSuffix}`}
          opacity=".9"
        />
        <path
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M456.597 312.862s4.776-6.795 9.333-8c4.601-1.216 4.304 6.367 5.333 21 .607 8.633-4.076 79.061-3.481 68.697.522-9.1 12.148-100.03 9.814-101.697-2.333-1.667-24.667-8.667-29.667-5s-9.333 12-9.333 12-.094 12.053 1 10.667c5.001-6.334 17.668 2.333 17.001 2.333z"
          clipPath={`url(#d${idSuffix}`}
        />
        <use
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          overflow="visible"
          xlinkHref={`#c${idSuffix}`}
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M278.252 300.546s25.725 16.504 75.058 3.17"
        />
        <path
          fill="#8AC5D6"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M303.419 318.045c2 0 18 1.333 24 2"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M301.419 323.379s8-4.667 14-4.667 16 6.667 16 6.667M467.931 391.195c-1.463 15.688-7.308 26.042-7.893 28.382"
        />
        <path
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M194.753 260.046s-16.804-35.805-20.204-26.738c-1.755 4.68 12.929 37.581 19.365 36.996 6.435-.585.839-10.258.839-10.258z"
        />
        <defs>
          <path
            id={`g${idSuffix}`}
            d="M364.419 212.713c37.968 28.877 53.471 120.015 49.725 123.761-9.626 9.626-68.304 20.468-66.725 22.906 1.35 2.083-29.343-12.66-24-16 10.667-6.667 52.537-13.548 61-20.666 2.239-1.883-23.666-52.184-23.666-52.184"
          />
        </defs>
        <use fill="#8AC5D6" overflow="visible" xlinkHref={`#g${idSuffix}`} />
        <clipPath id={`h${idSuffix}`}>
          <use overflow="visible" xlinkHref={`#g${idSuffix}`} />
        </clipPath>
        <path
          fill="#146F89"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M405.053 275.511l-7.13 2.508s12.477 53.107 10.338 52.038-2.674 0-2.139 2.139 2.674 4.278 1.07 3.209-5.015 1.869-21.39 6.417c-9.626 2.674-42.554 12.757-42.554 12.757l.838 14.135s66.849-23.148 70.593-26.357 0-67.38-3.743-76.471-4.813-23.529-3.743-19.251-2.14 28.876-2.14 28.876z"
          clipPath={`url(#h${idSuffix}`}
        />
        <path
          fill="#FFF"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M392.692 249.289s-9.636 5.751-9.03 16.061c.535 9.091 7.487 18.717 16.578 13.904"
          clipPath={`url(#h${idSuffix}`}
        />
        <path
          fill="#00C800"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M391.684 255.725s-3.209 4.813-2.139 11.765 8.021 9.626 11.23 6.952c3.209-2.675-2.674-22.995-9.091-18.717z"
          clipPath={`url(#h${idSuffix}`}
        />
        <use
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          overflow="visible"
          xlinkHref={`#g${idSuffix}`}
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M294.423 275.685c1.755 0 13.456 1.755 26.327-2.925"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M464.654 407.212c-1.484 4.616-4.89 12.483-4.89 12.483 8-2 5.013-1.632 27.112-7.71M441.859 361.717c-2.674 10.16-14.41 39.01-16.782 41.975M449.346 362.252c0 2.372-5.884 38.475-7.663 42.034"
        />
        <path
          fill="#8AC5D6"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M383.882 322.261s9.524-3.31 17.546 3.642M379.882 324.927s9.524-3.31 17.546 3.642"
        />
        <path
          fill="#8AC5D6"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M290.615 276.58s9.091 3.743 18.717 3.743"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M294.087 306.388s14.973 2.674 27.807.535"
        />
        <path
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M355.059 357.63s7.333-19.333 6-21.333-4-8.667-5.333-6.667c-1.333 2-2.667-14.667-6-12.667s-5.333-16-8-14-19.667 49.167-16.334 48.5"
        />
        <path
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M341.597 302.641c-5.333 4.667-46.121 39.659-36.121 37.659s19.667-11.334 21.333-8.667-19.184 16.247-12.184 15.747 17.35-6.229 17.35-6.229"
        />
        <path
          fill="#C6C6C5"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M362.095 156.026l.322 3.545s4.435 1.875 7.335 3.808c1.707 1.138-4.978-2.184-12.999-2.184-5.121 0-9.291.46-12.667 1-4.633.741 8.34-2.624 9.629-3.269l1.934-2.9s.645-9.668 4.189-9.024c3.546.645 2.257 5.157 2.257 9.024z"
        />
        <path
          fill="#C6C6C5"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M352.05 146.937s-2.866 4.138-.711 6.534c2.155 2.396 10.529 1.972 12.134 1.829 1.605-.143 4.014-1.67 4.745-3.142 1.236-2.491-2.95-5.754-2.95-5.754s-11.378-.602-13.218.533z"
        />
        <path
          fill="#C6C6C5"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M367.895 140.879c-1.366.059-7.143 1.833-6.123 10.635.622 5.366 7.412 7.412 10.313 6.445 2.9-.967 4.189-5.801 4.189-8.701s-.966-8.701-8.379-8.379z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M380.753 382.712c0 .667-.667 10.667-4 18"
        />
        <path
          fill="#FFF"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M382.606 396.853c-1.898.712-5.694 11.626-8.779 15.897"
        />
        <path
          fill="#8AC5D6"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M339.252 310.046s12-2 15-3"
        />
        <path
          fill="#FFF"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M496.912 411.248s3.867-1.657 7.735-1.105l3.867.552 6.077 2.21M503.19 417.797s11.602 2.21 8.287 2.21 22.099 0 14.917 1.105 16.575 1.105 10.497 2.21"
        />
        <path
          fill="#FFF"
          d="M372.803 169.179s7.182 9.392 7.182 17.127-4.972-18.785-7.182-17.127zM380.538 197.355s-1.381 6.354 0 4.972c1.381-1.381 1.933-6.905 0-4.972zM365.621 142.659s-2.762 6.077-1.105 8.287"
        />
        <path
          fill="#438CA1"
          d="M293.346 289.778l.74-1.25s24.667-2 37.333-6c12.667-4 22-27.333 22-32.667 0-5.333 19.333-28.667 19.333-28.667s12.667 15.333 6.667 11.333-9.333 3.333-18 20-7.694 25.467-25.095 35.907c-9.835 5.901-41.885 2.685-41.885 2.685s-.945 3.232-2.237 3.679c-1.293.447-17.699-.547-18.295-.895-.597-.348-.348-4.723.298-5.27"
          opacity=".46"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M388.792 418.976c1.657.028 18.791-.23 25.944 1.55 7.153 1.78-6.667 2.096-.047 2.762 6.619.666 39.101.051 44.283.835M413.312 410.126c1 0 31.964 1.859 34.964 1.859"
        />
        <path
          fill="#8AC5D6"
          d="M291.753 302.695c0 2 22 5.5 35 .5 7.153-2.751-3.957-.275-15 .5-9.028.633-20-3-20-1zM304.253 300.695s9 1 16.5-.5-15 .5-16.5.5z"
        />
        <path
          fill="#FFF"
          d="M375.013 225.532s16.768 21.711 16.671 30.192M342.42 165.862s3.333-1.333 6.667 0c3.333 1.333-4.667-.667-6.667 0zM353.753 168.195s4.667 1.667 2 2c-2.666.333-2-2-2-2z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M459.316 326.781c.235-.352 2.817-2.23 4.46-3.286M457.79 329.011s5.517-.469 6.925-.822M455.912 331.476s5.164.47 6.808.235"
        />
        <path
          fill="#34B2D6"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M447.097 308.862c-.632.211-1.833-9.667-1.5-11 .333-1.333 8.667-.167 8.833.667s-2.5 11.667-2.5 11.667-4.333-1.501-4.833-1.334z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M413.931 344.862c3.333-.667 10.333.833 11.833 3.833"
        />
        <path
          fill="#575756"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M247.374 279.965c-1.903 1.126-14.119-2.414-14.233.669-.114 3.082 4.795 3.882 10.937 4.727"
        />
        <path
          fill="none"
          stroke="#E20613"
          strokeMiterlimit="10"
          d="M236.348 203.548s-27.198 30.679 20.009 36.566c57.884 7.218 93.457-13.374 89.236-26.343-7.75-23.82-67.219-41.971-109.245-10.223z"
        />
        <defs>
          <path
            id={`i${idSuffix}`}
            d="M320.753 268.045c-8 5.333-21.332 9.116-46.564 9.116-18.748 0-41.815-37.397-43.291-58.7-3.524-50.852 32.891-80.349 67.41-77.434 13.346 1.127 62.289 14.841 37.683 90.266"
          />
        </defs>
        <use fill="#8AC5D6" overflow="visible" xlinkHref={`#i${idSuffix}`} />
        <use
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          overflow="visible"
          xlinkHref={`#i${idSuffix}`}
        />
        <path
          fill="#F8CC88"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M232.897 236.973c.577 2.853 9.189 39.933 33.979 38.591 28.857-1.563 54.957-12.786 59.002-50.758 3.446-32.341-28.329-35.173-62.203-34.353-27.029.653-40.253-.342-30.778 46.52z"
        />
        <path
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M341.419 189.379c-2.59-.576-66.434.974-76.536.49-8.766-.42-18.969-5.798-32.131-5.157-16.098.785-28.804 5.174-29.386 10.202-1.058 9.138 9.244 56.636 14.914 58.964 5.67 2.328 20.236 3.942 24.574 3.396 4.337-.545 6.989-12.4 12.772-13.127 4.777-.601 14.396 13.343 20.092 14.966 5.696 1.623 36.685 6.579 48.219 3.166 2.703-.8 16.148-14.234 16.148-14.234s8.226-40.83 10-47.333c1.614-5.914 3.334-8.667-8.666-11.333z"
        />
        <defs>
          <path
            id={`j${idSuffix}`}
            d="M261.864 239.919s14.195 17.813 17.086 17.45c2.892-.363 37.529 6.054 40.575 2.823 1.766-1.874 14.583-51.675 14.261-55.922-.322-4.248-53.438-9.625-68.784-9.723-15.346-.098-57.976.014-58.542 2.347-.566 2.332 10.875 51.961 13.201 53.929s17.408 3.601 20.779 3.256c3.371-.346 4.416-6.346 9.362-13.363 3.178-4.511 9.344-5.133 12.062-.797z"
          />
        </defs>
        <use fill="#BCCF00" overflow="visible" xlinkHref={`#j${idSuffix}`} />
        <clipPath id={`k${idSuffix}`}>
          <use overflow="visible" xlinkHref={`#j${idSuffix}`} />
        </clipPath>
        <path
          fill="#8D9B00"
          d="M229.934 189.01s-9.072 24.643-2.894 48.957c8.846 34.816-28.891 12.696-28.891 12.696s-13.09-73.906-7.29-75.103c5.8-1.196 39.075 13.45 39.075 13.45zM308.817 192.596s24.599 19.786 11.765 66.845c-9.452 34.656 29.942.223 29.942.223s13.09-73.906 7.29-75.103c-5.8-1.197-48.997 8.035-48.997 8.035z"
          clipPath={`url(#k${idSuffix}`}
        />
        <g clipPath={`url(#k${idSuffix}`}>
          <path
            fill="#00C800"
            d="M284.044 198.884c7.823 1.587 15.484 4.993 21.198 10.272 5.775 5.242 9.073 11.827 10.463 18.301 1.398 6.533 1.185 12.401 1.256 18.328-.041 5.918.203 11.559-.956 17.36-1.151 5.752-3.983 11.058-8.992 14.762-4.965 3.756-11.596 5.574-18.5 6.229-6.975.643-13.58.428-20.728.46-7.146-.06-14.428-.002-22.313-1.616-7.821-1.586-15.484-4.992-21.198-10.272-5.777-5.241-9.073-11.829-10.463-18.301-1.401-6.534-1.185-12.399-1.256-18.328.041-5.915-.201-11.558.956-17.36 1.15-5.75 3.98-11.056 8.992-14.762 4.96-3.753 11.595-5.573 18.5-6.229 6.974-.643 13.578-.429 20.728-.46 7.141.06 14.429.003 22.313 1.616z"
            opacity=".53"
          />
          <path
            fill="#FFF"
            d="M293.542 211.804c-8.186-1.608-17.54-2.79-26.777-3.514-1.13 7.001-2.556 16.631-3.973 26.687-2.012-.184-4.018-.363-6.007-.537-2.716-10.387-5.09-20.136-6.534-26.966-8.75-.172-16.744.115-23.236.82-2.749 11.771-2.995 30.324-.673 46.75 4.296.927 9.347 1.799 14.809 2.589-1.691-12.467-2.45-26.901-2.028-38.465 3.021 11.587 8.132 26.871 13.496 39.938a466.958 466.958 0 0 0 18.113 1.697c3.328-12.106 6.751-26.696 9.101-38.651 1.526 12.449 2.479 27.312 2.535 39.36 5.589.262 10.838.386 15.472.352.981-15.536-.585-36.103-4.298-50.06z"
          />
        </g>
        <use
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          overflow="visible"
          xlinkHref={`#j${idSuffix}`}
        />
        <path
          fill="#D9D9D9"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M260.347 280.7s19.005-4.561 21.644-11.052c2.639-6.491-44.682-12.525-34.83 4.445 8.845 15.234 13.186 6.607 13.186 6.607z"
        />
        <path
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M251.666 262.388s-13.205 3.318-12.994 14.108c.165 8.478 7.926 19.12 20.859 19.599 9.592.356 17.494-3.315 18.246-13.443.957-12.907-14.563-21.247-26.111-20.264z"
        />
        <path
          fill="#6F6F6E"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M250.544 270.057s-8.577 2.155-8.44 9.163c.107 5.506 5.148 12.418 13.548 12.73 6.23.231 11.363-2.153 11.851-8.731.622-8.384-9.458-13.801-16.959-13.162z"
        />
        <path
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M252.312 274.603s-5.018 1.261-4.938 5.362c.063 3.222 3.012 7.266 7.927 7.448 3.645.135 6.648-1.26 6.934-5.109.364-4.906-5.534-8.075-9.923-7.701z"
        />
        <defs>
          <path
            id={`l${idSuffix}`}
            d="M254.604 243.407c-7.075 2.703-12.811 13.456-13.439 14.719-1.282 2.579-1.05 2.713-.233 3.674 2.974 3.496 7.654 6.626 11.584 6.763 7.934.277 16.081-6.863 17.228-7.384 1.148-.522 8.443-.872 5.975-2.064s-18.76-16.608-21.115-15.708z"
          />
        </defs>
        <use fill="#D0F0FF" overflow="visible" xlinkHref={`#l${idSuffix}`} />
        <clipPath id={`m${idSuffix}`}>
          <use overflow="visible" xlinkHref={`#l${idSuffix}`} />
        </clipPath>
        <path
          fill="#00C800"
          d="M233.536 256.168s24.986-5.575 37.613 5.275c11.007 9.459 7.801-4.074 7.801-4.074 2.035.094-17.152-17.035-22.701-17.241-3.662-.136-22.713 16.04-22.713 16.04z"
          clipPath={`url(#m${idSuffix}`}
        />
        <path
          fill="#FFF"
          d="M247.009 249.942s12.204-.726 23.471 3.775c3.956 1.581-8.008-13.016-15.876-10.31-2.085.717-7.595 6.535-7.595 6.535z"
          clipPath={`url(#m${idSuffix}`}
        />
        <use
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          overflow="visible"
          xlinkHref={`#l${idSuffix}`}
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M274.895 290.402s12.514 1.45 15.854 1.348 6.451-7.719 1.352-10.489c-2.937-1.595-15.165-1.46-15.165-1.46s-3.512.013-3.966 5.077c-.151 1.679.051 3.757 1.925 5.524z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M291.434 281.125s-3.541.8-3.838 4.946c-.2 2.789.708 5.713 3.364 5.596"
        />
        <path
          fill="#85ACAB"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M267.605 184.117s5.509-32.57 29.903-39.858"
        />
        <path
          fill="#C6C6C5"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M254.707 182.345s10.128 5.293 15.171 4.461c5.043-.832 17.3-4.509 19.146-4.643 1.846-.135 1.221-21.429 9.552-28.067 8.331-6.638 12.574-8.917 14.454-8.59 1.88.327-15.698-8.484-28.451-5.235s-23.549 14.243-26.636 22.818-3.236 19.256-3.236 19.256z"
        />
        <path fill="#FFF7A1" d="M273.692 285.84s14.792 1.831 11.609-.317" />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M296.004 150.235c-12.193-4.156-24.021-1.508-24.021-1.508"
        />
        <path
          fill="#FFF"
          d="M299.536 200.679s-38.736-4.214-31.921-.72M249.73 249.455s-6.873 10.549-5.68 11.673M259.257 238.079c.078.481-3.334-1.667-4.612-.48-1.279 1.187 4.361-1.073 4.612.48z"
        />
        <path
          fill="#DFF0F2"
          d="M321.805 170.328s-1.37 5.408 2.567 4.385c3.938-1.024 2.25-8.105-2.567-4.385zM341.752 173.379c-4.03 2.634-4.976 8.004 1.283 7.457s4.785-11.422-1.283-7.457z"
        />
        <path
          fill="#DFF0F2"
          d="M314.585 235.958s-5.049 8.281 2.357 8.055c7.563-.231 4.47-13.113-2.357-8.055z"
        />
        <path
          fill="#FFF"
          d="M217.171 191.444s-4.298 3.185-1.021 4.978 6.03-4.792 1.021-4.978zM248.836 171.717s-4.298 3.185-1.021 4.978 6.03-4.792 1.021-4.978z"
        />
        <path
          fill="#DFF0F2"
          d="M308.15 188.96s-4.521 5.389.411 5.571c4.932.183 4.521-5.388-.411-5.571zM322.603 211.257s-4.521 5.389.411 5.571c4.932.183 4.521-5.388-.411-5.571zM309.931 207.546s-3.265 4.818 1.073 4.361c4.338-.456 1.484-6.735-1.073-4.361z"
        />
        <path
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M373.935 145.209s-3.668-3.064-5.414.602c-2.005 4.211.476 12.634 5.251 6.518"
        />
        <path
          fill="#FFF"
          d="M224.416 120.42s-2.091 2.709-.088 2.385 2.914-3.474.088-2.385z"
        />
        <g>
          <path
            fill="#575756"
            stroke="#1D1D1B"
            strokeMiterlimit="10"
            d="M297.012 289.944c-1.815 0-4.759-.265-5.405-.862 0 0-.681-1.74-.696-2.337-.05-2.038 1.044-2.734 1.044-2.734 44.661 1.014 48.826-9.256 57.296-29.454 2.456-5.857 5.239-12.497 9.325-19.74 13.473-23.882 34.53-39.627 49.906-51.124 10.57-7.903 19.698-14.729 19.229-19.397-.25-2.484-3.399-5.173-9.359-7.991-13.389-6.33-24.788-7.074-31.993-6.586-7.818.531-12.539 2.588-12.586 2.609 0 0-1.642-.502-2.444-2.708-1.388-3.816.802-3.81.802-3.81.844-.378 20.548-7.173 48.357 5.975 7.872 3.721 11.748 7.538 12.197 12.011.752 7.481-7.984 14.014-21.21 23.902-15.02 11.23-35.591 26.61-48.545 49.576-3.942 6.989-6.667 13.485-9.069 19.216-9.346 22.289-15.922 33.453-56.849 33.454z"
          />
        </g>
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M258.163 180.162s8.414 3.727 9.636 3.971c1.222.244 15.647-3.423 16.625-3.667M293.098 282.569c-.348-.05-2.834-.994-3.629 2.933-.795 3.928 1.74 5.966 3.232 4.524"
        />
        <path fill="#DEF8FF" d="M309.42 162.528s-19.333-4-25.333-2 25.333 2 25.333 2z" />
        <g
          fill="#D69AB4"
          stroke="#1D1D1B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth=".5"
        >
          <path d="M125.377 355.71s-4.058-9.371-2.174-10.552c1.885-1.182.703-3.066.806-6.766s-1.298-19.785.5-17.884c1.798 1.902 1.716-17.233 2.898-15.348s.858-8.616 2.022-6.115c1.164 2.501.958 9.901.871 12.985-.086 3.083 2.381 3.152 1.026 7.435-1.354 4.282-1.371 4.899-2.091 8.582-.72 3.683 1.609 8.685.923 11.135-.686 2.449-1.406 6.132-1.457 7.982-.052 1.85.306 11.117.306 11.117" />
          <path d="M126.665 319.867s1.696 2.766.587 4.391-.378 5.073.166 6.743M127.591 337.508c-.361.226-1.622 3.028-.962 4.82.659 1.792-.92 7.54-.271 9.687M127.895 305.479s-.828 4.233.389 7.222c1.217 2.99.895 6.054.622 7.347M124.65 341.209c.01-.354-.322-13.841-1.422-12.571-1.099 1.27.998 15.042 1.224 15.403M129.068 335.421c-.108-.358-1.036-7.933.168-7.376s1.541 9.67.189 11.523" />
        </g>
      </g>
      <g>
        <path
          fill="#A1736F"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M201.835 333.072l-105.14 23.806 15.781 93.214s174.819 24.947 179.018 24.228c4.199-.718 61.829-29.196 64.381-30.796 2.356-1.477 8.691-95.409 8.691-95.409l-5.711-.797-157.02-14.246z"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M283.955 473.289l1.835-97.073 8.88.787 5.893-1.069-.805 95.02-9.06 3.344z"
        />
        <defs>
          <path
            id={`n${idSuffix}`}
            d="M198.973 337.576c-2.94.658-96.965 19.276-91.11 20.058 5.856.782 179.297 14.361 183.474 14.323s65.921-21.536 65.921-21.536-146.612-15.458-158.285-12.845z"
          />
        </defs>
        <use fill="#FD0" overflow="visible" xlinkHref={`#n${idSuffix}`} />
        <clipPath id={`o${idSuffix}`}>
          <use overflow="visible" xlinkHref={`#n${idSuffix}`} />
        </clipPath>
        <path
          fill="#673C38"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M107.716 367.633s25.264-6.816 20.659-6.527-.277-5.023 9.838-8.083c10.115-3.06 15.738 2.148 15.738 2.148s2.607 2.872.78.551c-6.053-7.691 6.59-6.554 9.512-6.986 7.134-1.054 10.435 7.744 12.016 3.679 2.251-5.788 16.478-8.074 20.265-7.373 13.304 2.461 22.666-3.545 22.666-3.545l-46.663-5.066-66.989 19.44 2.178 11.762zM232.887 344.805c1.241.453 6.178 3.237 12.882 2.589 6.704-.648 5.096-2.922 14.689-2.236 9.592.686 10.646 7.839 10.775 3.227.07-2.505 8.445-3.107 12.145-.914s-.917 2.9 3.7 2.193c4.617-.707 7.645-4.383 7.969-1.031.324 3.352 8.34.651 6.611 2.692-1.729 2.041 1.717-1.624 8.004-2.284 6.287-.66 9.152 1.51 9.522 3.192.371 1.682 6.334-2.33 9.233-1.413s2.319 6.751 8.223 4.827c5.904-1.924 11.438-5.531 14.314-3.779 2.876 1.752 5.777 1.029 7.637-1.229 4.199-5.099-57.637-18.975-70.976-20.183-13.339-1.209-65.48 10.288-57.163 11.775"
          clipPath={`url(#o${idSuffix}`}
        />
        <use
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          overflow="visible"
          xlinkHref={`#n${idSuffix}`}
        />
        <path
          fill="#A1736F"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M285.79 376.216c7.562 2.391 5.503 3.518 5.759 7.84 1.218 20.561 1.146 82.991-27.262 105.083-13.19 10.258-22.845 13.035-41.288 15.026-18.443 1.992-154.153-17.926-164.103-19.632-17.468-2.996-48.326-65.15-44.01-70.044s79.406-55.112 81.937-55.545c4.563-.78 188.967 17.272 188.967 17.272z"
        />
        <path
          fill="#673C38"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M283.319 380.143c.302 2.188-72.61 55.423-76.832 56.976-4.222 1.554-184.364-23.156-181.835-23.922 2.529-.765 73.589-50.426 73.589-50.426s184.776 15.183 185.078 17.372z"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M120.916 444.045l87.937 8.874-1.625-8.416-5.039.08-37.019-3.891s-107.802-14.33-145.166-19.715c-1.372-.198-4.449-1.675-4.449-1.675l3.903 11.415 101.458 13.328z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M207.39 445.226s12.652 59.348 30.359 56.21"
        />
        <path
          fill="#673C38"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M98.869 363.724s21.324 53.09 22.743 57.126M97.954 366.437s-61.295 42.083-64.706 44.495"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M14.886 414.489s-1.642 5.478 5.016 6.06c7.696.673 178.171 26.596 188.068 23.422 3.182-1.021 82.8-63.983 82.8-63.983M204.131 461.57c-.047 1.67 6.892 32.51 18.397 39.518"
        />
        <path
          fill="#FD0"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M271.234 348.384s-.367 3.537 9.345 3.387M280.268 355.35s-2.917-1.348-3.591.111c-.674 1.458.972 2.982 2.682 2.185 1.711-.796 2.59-2.038.909-2.296zM290.606 355.428s-2.788 1.61-.047 1.687c2.741.077 1.945-1.634.047-1.687z"
        />
        <path
          fill="#FFF7A1"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M268.278 353.96c-.665.055-4.657.503-2.156 1.628s4.059-1.786 2.156-1.628zM186.918 350.419s-.574-2.126-2.483-1.758c-1.91.369-1.6 4.387-.335 4.422s4.651-.292 4.464-1.141-.369-1.909-1.646-1.523zM298.787 364.731c.121-.621-4.381-1.811-4.428-.124-.047 1.687 4.182 1.384 4.428.124zM308.126 355.285c-1.054-.029-11.856 1.357-7.451 2.324 4.404.967 14.62-2.124 7.451-2.324zM143.32 354.898s2.467-5.207-.72-4.452-7.709 4.005-5.196 4.708 5.916-.256 5.916-.256zM180.808 357.634c-1.054-.029-6.121.04-4.253 1.147s5.939-1.1 4.253-1.147zM205.991 355.197c-.825-.309-15.782-3.506-13.092-.087s22.741 3.7 13.092.087z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M295.159 394.465l-3.162 78.123"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M322.152 412.716s18.924-6.849 19.551-7.243c.628-.393 1.51-27.37.677-26.982s-20.282 7.097-20.528 8.529c-.244 1.432.3 25.696.3 25.696zM94.058 432.847l24.888 3.297s3.581 16.514 3.526 18.514-23.543-1.505-24.299-2.017c-.756-.512-4.115-19.794-4.115-19.794z"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M100.009 450.069c-.964-.027-1.829-.15-2.549-.388-1.772-.586-2.218-1.639-2.28-2.42-.218-2.728 3.247-4.926 6.164-6.442 0 0 1.021.573 1.159.84.182.35.12 1.624.12 1.624-6.761 3.171-3.719 3.845-3.577 3.893 4.43 1.465 13.57-2.04 16.583-4.111 0 0 1.306.204 1.509.551.194.332-.246 1.518-.246 1.518-1.701 2.144-10.638 5.109-16.883 4.935z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M364.435 349.709s-66.133 24.31-68.921 24.695-7.863-.22-9.713-.272"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M351.067 445.85l5.328-2.936 4.915-87.5-6.853 2.277z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M296.716 381.072s-1.302 2.432.248 2.167c1.55-.265.386-2.766-.248-2.167zM298.219 404.566s-1.028 3.674.222 3.092c1.251-.582 1.072-5.216-.222-3.092zM297.469 431.392s-.369 2.15.257 1.859c.625-.292 2.253-3.332-.257-1.859zM298.117 452.393c-.026.925-1.302 2.432-.069 2.467 1.233.034 1.469-2.89.043-1.542M286.234 469.033c-.026.925-1.302 2.432-.069 2.467 1.233.034 1.469-2.89.043-1.542M286.244 457.616c-.026.925-1.302 2.432-.069 2.467 1.233.034 1.469-2.89.043-1.542"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M323.969 388.623s-1.311 2.741.548 2.484c1.858-.257.086-3.084-.548-2.484zM340.76 381.3s-1.628 3.04-.377 2.458c1.25-.582 1.619-2.731.377-2.458z"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M334.266 402.766s-.016.16-2.554-.748c-2.538-.907-2.016 2.502.142 2.562"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M330.825 435.864c-1.621-.045-3.286-.365-4.813-1.079-2.53-1.182-5.595-3.878-5.832-10.001-.378-9.762 7.888-18.636 9.605-19.437l1.491 3.197c.029-.014.067-.031.11-.06-1.202.806-7.982 8.388-7.681 16.165.134 3.491 1.414 5.826 3.8 6.942 3.139 1.466 7.485.492 8.804-.529 2.365-1.826 7.914-6.958 8.392-15.159.241-4.145-2.207-6.722-3.688-7.89-2.17-1.711-4.524-2.233-5.725-1.872l-1.022-3.374c2.543-.771 6.046.203 8.929 2.477 3.439 2.711 5.27 6.67 5.025 10.864-.561 9.647-7.009 15.624-9.755 17.744-1.473 1.137-4.473 2.1-7.64 2.012z"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M331.712 402.019c-1.253.447-4.711 9.787 1.585 8.658 6.296-1.129 7.906-12.047-1.585-8.658z"
        />
        <path
          fill="#673C38"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M105.869 386.228l11.524 29.329M124.27 368.228l156.378 13.629"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M208.853 452.919l82.414-64.593-.075-8.334-83.904 64.718z"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M213.735 449.273s2.294 45.594 26.647 46.657c6.819.297 15.225-3.855 25.968-12.31 22.41-17.638 22.236-92.925 22.236-92.925l2.681-2.37s7.768 75.996-28.521 102.256c-36.289 26.26-44.399 1.061-53.781-37.922"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M31.739 437.767s13.08 33.537 28.128 43.987"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M202.087 448.695s-.514 1.299.812.858c1.325-.44 1.116-1.52-.812-.858zM163.587 445.195c-.358-.01-2.413.888-.027.954s1.458-.914.027-.954zM133.149 442.225c-.607.574.202 1.319 1.538.521s-1.048-.984-1.538-.521zM21.181 426.851c-.607.574.202 1.319 1.538.521s-1.047-.985-1.538-.521zM78.783 433.793s-.347 1.721.696.736c1.043-.986.021-1.278-.696-.736zM47.614 429.708s-.347 1.721.696.736c1.042-.986.021-1.278-.696-.736zM96.36 435.439s-1.104 1.044.338.726c1.442-.318.981-.928-.338-.726zM116.604 437.318s-.023.835.812.858c.835.024.381-.824-.812-.858zM113.796 443.806s-.295-2.277 1.508-2.704"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M118.608 442.508s1.965 2.323-.468 3.927c-1.276.841-2.512.169-2.512.169M101.011 444.165s1.385 1.71 2.843.796M104.895 441.886s-.848-3.844-3.744-2.731M98.654 451.621s.212.961.818.62-.096-.839-.818-.62zM119.398 452.678s-.517 1.418.689.974c1.206-.443-.324-1.202-.689-.974z"
        />
        <path
          fill="#FFF"
          d="M112.669 445.685s-3.51 1.693-6.267 2.093M96.838 448.227s.686 1.094 3.314 1.048M99.855 442.819c-.358-.01-2.549 1.481-2.549 1.481"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M256.59 425.62l8.164 39.109M218.779 453.569s2.67 14.887 4.485 16.172"
        />
        <path
          fill="#9C9B9B"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M286.289 386.897s-1.236 1.973-.036 1.298.643-1.871.036-1.298zM254.087 413.862s-.761 1.868.328.954c1.088-.915.635-1.636-.328-.954zM217.92 441.695s-.777 2.458.311 1.544-.177-2.131-.311-1.544zM211.981 456.867s-.777 2.458.311 1.544c1.089-.915-.176-2.131-.311-1.544zM219.065 480.613s-.777 2.458.311 1.544c1.088-.914-.177-2.13-.311-1.544zM237.036 497.634s-.777 2.458.311 1.544c1.088-.915-.177-2.131-.311-1.544zM258.838 490.354s-.777 2.458.311 1.544-.176-2.131-.311-1.544zM279.661 465.714s-.777 2.458.311 1.544c1.088-.914-.177-2.131-.311-1.544zM288.406 429.895s-.777 2.458.311 1.544c1.089-.914-.177-2.131-.311-1.544zM289.707 399.178s-.777 2.458.311 1.544c1.089-.915-.176-2.131-.311-1.544z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M259.743 433.516l.838 3.802M263.103 448.49c.105.475 2.394 11.522 2.394 11.522M324.506 438.396s6.323-2.696 6.319-2.533M328.046 435.949c-.694-.019-7.259 3.037-7.259 3.037M341.951 410.417l-1.107 14.781M340.299 419.86c-.019.694-.669 7.387-.669 7.387M335.696 435.469s8.21-3.936 9.373-4.135"
        />
        <path
          fill="#FFF"
          d="M333.347 402.509s-3.581 5.022-1.307 6.474c2.273 1.452-1.904-.345 1.307-6.474zM321.367 426.505s.802 4.42 2.164 5.384M341.704 405.473s2.732 1.619 2.697 2.853"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M358.853 368.769s-1.69 2.499-.302 2.537c1.387.039 1.015-3.212.302-2.537zM357.043 400.425s-.071 2.544.642 1.869c.713-.674.553-3.224-.642-1.869zM355.494 439.263s-1.671 1.805-.508 1.606c1.163-.199 2.602-2.011.508-1.606zM278.685 471.368l10.599 1.685M280.362 469.332l9.244.49M282.059 466.602l7.85.682M282.843 463.384c.687.251 9.244.49 9.244.49M284.36 458.797l6.482-.05M290.707 455.272l-4.631.102M290.072 453.171l-3.707.128M290.162 449.934l-2.081-.059M289.839 461.496l-6.482.051"
        />
        <path fill="#FFF" d="M295.018 416.974c-.026.925-.601 21.507-.351 20.819" />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M339.872 402.028l-.088-13.426"
        />
        <path
          fill="#FD0"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M231.526 361.312c-.713-.357-9.256-1.854-6.126.361 3.129 2.214 10.06 1.61 6.126-.361zM162.286 351.855s-8.886-5.567-8.739-1.308c.147 4.259 10.731 6.15 11.034 4.829.303-1.321-.974-3.218-2.295-3.521zM279.865 363.461c-1.063-.03-23.214 2.808-10.482 3.962 12.732 1.154 24.374-6.233 9.419-3.991"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M259.791 357.758s4.82-1.195 6.4-.619c1.579.576 4.746 1.462 3.941 1.706-.805.243 7.706.215 7.706.215"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M261.517 353.907c1.055.295 6.325 2.038 7.639 2.607M285.387 356.169s-5.641 1.803-6.028 1.477l4.647.346c-1.321-.303-10.636-.031-11.433-.054M299.13 359.213s9.374-2.397 10.969-2.353M316.004 354.898s5.285 1.211 3.934 1.971l-1.351.76M308.904 352.04s-2.878-1.676-2.915-.347l-.037 1.329M241.092 361.579s5.344-.914 4.251.119 2.678.877 2.678.877M198.349 359.055c.797.022 4.554-1.202 6.149-1.158 1.594.045 4.805-.663 4.805-.663M194.437 356.286l-5.026-.938M200.785 357.527s-9.566-.267-10.37-.024M195.08 352.315l-3.986-.111M282.025 366.123l6.444-2.213M269.383 367.423s-10.045-2.142-11.374-2.179M155.775 356.148s3.101-.53 4.925.446c1.824.977 10.757 1.535 10.757 1.535M160.699 356.594c2.184-.865 9.285-.975 9.285-.975M179.055 351.245l5.045 1.839-4.797.328 7.409-.102"
        />
        <path
          fill="#FFFBDE"
          d="M276.77 364.038c-.474-.013-9.058 1.487-7.007 1.702M282.93 364.369s-5.919 2.365-10.331 1.767M274.696 347.688c1.774-1.216 6.796.19 7.266.361M316.839 350.922s-2.331-1.489-6.625-.66M328.825 352.207s-1.55-1.151-3.31-.409c-1.761.741 4.882.769 3.31.409zM229.068 361.597s-3.929-.901-2.687-.075c1.243.826 4.263.278 2.687.075zM250.191 358.55s-2.783-2.292-2.818-1.028 2.818 1.028 2.818 1.028zM264.122 358.148s-5.541.161-3.973.68c1.567.518 5.22-.012 3.973-.68zM256.713 345.92s6.335-.297 8.192 1.178c1.857 1.475-7.393-1.472-8.192-1.178zM160.803 353.52c-.474-.013-5.312-2.363-4.403-.914.909 1.449 3.925 1.059 3.929.901M166.287 349.719s3.92 1.217 2.494 1.335-2.336-1.331-2.494-1.335z"
        />
        <path
          fill="#FFF"
          d="M141.393 351.87c-.45.15-3.363 1.488-1.616 1.22 1.747-.267 3.372-1.804 1.616-1.22z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M151.371 357.052s1.453-1.067 2.393-.724 4.079 1.221 4.079 1.221M141.291 355.505s4.913-.337 5.703-.315M143.32 354.898c.698-.74 3.077-.99 3.077-.99M275.097 367.313l7.898.379M302.906 362.713s.554-2.832 1.963-2.318c1.409.514.909 1.449.909 1.449M307.231 360.777s2.56-1.036 2.863-.553M255.626 362.182s-.801-2.474-1.613-1.706l-.812.768s-2.157-.653-2.766-.077M260.35 362.946s.387-2.52 1.462-1.383l1.075 1.137M210.769 359.82s1.331-2.335 2.103-1.681c.773.654 1.378 1.62 1.378 1.62M206.826 365.088s1.616-1.22 2.713-.873l1.098.347s1.901-.105 2.538-.245"
        />
        <path
          fill="#FFFBDE"
          d="M195.846 361.776s-3.626-.418-2.217.096 3.626.418 2.217-.096zM252.579 363.678s-3.17.228-1.273.281c1.896.053 1.892.211 1.273-.281z"
        />
        <path
          fill="#FD0"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M288.582 357.639c.364-.062 3.196-.417 4.209-.389M292.381 356.37s1.189-1.125 1.754-.602c.564.522.211.223.564.522M293.183 350.965s-.685-1.394-1.426-.764c-.741.631-.611 1.141-.611 1.141M231.536 363.354s1.095-.331 1.806.123c.711.454 1.065.754 1.065.754M224.507 361.059c-.217-.006-1.498-.766-2.017-.346-.518.42-1.314.398-1.314.398"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M243.158 362.844c-.547-.262-3.858.499-1.837.556 2.022.056 3.634.304 1.837-.556zM189.208 360.122s-5.06.061-2.645.533 3.038-.118 2.645-.533z"
        />
        <path
          fill="#673C38"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M93.793 376.208l1.24 3.133"
        />
        <path
          fill="#673C38"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M97.525 385.163l7.869 19.247M90.485 399.568c-.037 1.326 4.15 9.851 4.15 9.851M201.665 379.667l12.798 1.243M220.198 381.512l50.294 5.389M222.907 411.235l-7.504-.652M222.11 408.116l-14.124-1.28M319.042 391.356l.82 18.165M302.46 398.857l-.92 64.579M303.116 375.423l36.209-13.147"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M213.864 502.579l-107.032-12.432M90.49 483.79c1.769.049 82.304 10.561 82.304 10.561"
        />
      </g>
      <g>
        <defs>
          <path
            id={`p${idSuffix}`}
            d="M191.308 356.857c-1.374-3.891-26.842-64.115-24.447-70.212 2.394-6.097 67.814-3.773 73.733 1.693l23.32 72.467s-2.861 1.592-4.5 1.886-6.462-4.774-9.359-3.653c-2.805 1.086-2.589-.751-2.962.935-.373 1.685-18.113-5.593-18.189-2.882-.076 2.711 1.219 3.086-2.348 2.308s-2.13-5.485-8.428-1.931c-6.298 3.554-7.2.816-7.2.816s-6.472-1.877-8.783-.924c-2.31.952-10.837-.503-10.837-.503"
          />
        </defs>
        <use fill="#9C9B9B" overflow="visible" xlinkHref={`#p${idSuffix}`} />
        <clipPath id={`q${idSuffix}`}>
          <use overflow="visible" xlinkHref={`#p${idSuffix}`} />
        </clipPath>
        <path
          fill="#FFF1B7"
          d="M189.051 355.21c1.881.47 29.161 1.646 30.808 1.646 1.646 0 33.865 1.411 35.041 1.411 1.176 0 9.642-3.528 9.642-2.117s.706 8.701-.47 8.701-73.139-5.409-74.785-5.409-.236-4.232-.236-4.232z"
          clipPath={`url(#q${idSuffix}`}
        />
        <use
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          overflow="visible"
          xlinkHref={`#p${idSuffix}`}
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M233.174 291.182s-17.043-3.02-39.076-2.618-26.951 1.281-26.951 1.281"
        />
        <path
          fill="#FFF"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M237.433 296.388l14.667 46.701"
        />
        <path
          fill="#FFF"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M240.594 288.337l-4.878 3.126M245.131 311.148c.284.979 5.122 17.299 5.122 17.299M251.116 331.659l.686 1.945"
        />
        <path
          fill="#868686"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M174.942 293.525s-2.629 1.274-1.773 3.801c.855 2.527 2.663 4.118 4.149 3.967s9.714-.306 10.381-.287c1.296.036 2.438-1.087 1.234-4.394-.839-2.303-2.32-3.146-3.24-3.364-.92-.219-10.751.277-10.751.277z"
        />
        <path
          fill="#FFF"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M184.349 294.943s.426 4.633 1.553 4.087c1.126-.547-.389-5.98-1.553-4.087z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M175.687 293.353s-1.507 1.188-1.033 3.822c.301 1.669.986 2.955 2.886 2.776 3.157-.297 8.095.323 10.19-.1"
        />
        <path
          fill="#FFF"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".75"
          d="M176.537 294.553s-1.439 1.881-.107 3.839c1.331 1.958 4.108 1.796 5.289.868s-.499-6.977-5.182-4.707z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M175.162 285.637s-4.676 1.602-3.195 1.644c1.481.041 15.029-.928 15.59-1.104.561-.177 1.333-1.31 1.333-1.31l-13.728.77z"
        />
        <path
          fill="#FFF"
          d="M238.529 297.101c0 .403 13.823 41.067 13.689 43.751M230.611 289.049c.852.459-38.92-2.416-34.088-1.879s32.343.939 34.088 1.879zM233.295 286.901c-.403-.134-17.726-2.173-16.507-1.61 1.745.805 16.105 1.476 16.105 1.476"
        />
        <path
          fill="#FFF1B7"
          d="M251.371 355.21c-.706-.235-15.992-1.176-11.994-.235M225.502 354.034s-5.879-.706-4.468 0c1.411.705 4.468.235 4.468 0zM194.46 353.093c2.677-.892 12.229.235 9.172.235-3.058 0-10.583.235-9.172-.235z"
        />
      </g>
      <g>
        <g stroke="#1D1D1B" strokeMiterlimit="10">
          <path
            fill="#00C800"
            d="M395.689 306.604s1.277-6.35 4.004-10.522 12.351-9.787 15.98-9.057c3.629.73 15.24 4.009 12.696 7.273-2.544 3.264-8.545 14.328-17.44 16.315s-20.678-.629-15.24-4.009z"
          />
          <path
            fill="#FD0"
            d="M429.18 293.258s17.789-3.974 13.796-2.889c0 0-.897-9.62-3.441-6.356-2.545 3.264-10.355 9.245-10.355 9.245z"
          />
          <path
            fill="#FFF"
            strokeWidth=".5"
            d="M405.602 307.158s6.001-.436 7.645-2.076-6.064.751-5.874-.196c.191-.947-1.771 2.272-1.771 2.272z"
          />
          <path
            fill="#1D1D1B"
            d="M401.947 299.196s2.145 2.402-.129 2.274-1.954-3.35.129-2.274z"
          />
          <path
            fill="none"
            d="M402.266 295.975s7.904-8.266 13.208-6.871c5.304 1.395 9.786 3.611 9.786 3.611"
          />
        </g>
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M384.607 454.336s-.716.677.812.859c1.529.182 1.29-1.219-.812-.859zM453.274 429.983s-.716.677.812.859c1.529.182 1.289-1.219-.812-.859zM463.452 432.836s-.716.677.812.859c1.529.182 1.289-1.219-.812-.859zM273.941 500.336s-.716.677.812.859 1.289-1.219-.812-.859zM42.607 486.336s-.716.677.812.859 1.29-1.219-.812-.859zM321.274 482.336s-.716.677.812.859c1.529.182 1.289-1.219-.812-.859zM357.42 449.195s-.716.677.812.859c1.529.182 1.289-1.218-.812-.859z"
        />
        <path
          fill="#386388"
          d="M388.239 464.215c5.052 2.523 41.448.633 44.533.63S422.3 479.662 426 477.807c3.699-1.855 16.029-10.505 19.729-11.126s42.57 2.421 40.717.573c-1.853-1.849-36.709-10.122-35.326-10.595 1.383-.473 30.525-2.849 25.215-4.612-7.194-2.389-38.023 2.304-38.023 2.304s-35.928-15.646-33.548-9.656c2.379 5.99 26.846 17.542 23.104 15.457-3.741-2.084-39.629 4.063-39.629 4.063"
          opacity=".49"
        />
        <path
          fill="#5DC6FF"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          d="M427.868 460.152c-2.408.876-54.175 10.06-39.63 4.062 17.995-7.42 42.67-10.473 42.67-10.473s-39.494-12.37-25.211-10.901 30.478 8.546 34.461 7.806 27.918.229 37.354-.371c6.669-.424-26.393 6.383-26.393 6.383s39.756 6.907 29.9 7.089c-9.856.182-37.423-3.219-37.423-3.219s-19.553 23.411-24.482 23.502c-4.926.091 27.283-30.615 8.754-23.878z"
        />
        <path
          fill="#5DC6FF"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M427.873 474.362s-1.531 1.674-.417 1.255c1.114-.42 1.67-1.814.417-1.255zM410.993 459.468c-.438-.048-2.926.979-.974 1.116 1.951.137 2.229-.978.974-1.116zM394.413 463.249s-3.762.98-1.532.838c2.23-.142 2.508-.56 1.532-.838zM463.803 450.491s-3.204.979-1.253.838c1.95-.141 2.925-.7 1.253-.838zM454.047 450.502c-.441 0-3.345.143-2.09.56 1.255.417 2.787-.561 2.09-.56zM464.51 460.664s-.556.976.837.696.697-.279-.837-.696z"
        />
        <path
          fill="none"
          stroke="#1D1D1B"
          strokeMiterlimit="10"
          strokeWidth=".5"
          d="M14.264 450.695s-7 .5-4.5 1.5 8-1 4.5-1.5zM492.264 435.695c-1.5-.5-11.5-.5-7 1s7-1 7-1z"
        />
        <g fill="#93C01F" stroke="#1D1D1B" strokeMiterlimit="10">
          <path
            strokeWidth=".75"
            d="M150.628 394.348c7.248.954 15.181 9.329 12.519 11.8-.642.596-2.311 1.423-3.548 1.88-8.575 3.163-22.584-4.475-23.716-8.843-.717-2.764 7.279-5.82 14.745-4.837z"
          />
          <path
            strokeWidth=".75"
            d="M137.049 400.703c-.347-.132-8.154 1.143-9.84 1.525-1.686.382 5.166-3.117 4.88-3.608-.847-1.449-4.505-3.924-5.509-4.534-1.004-.61 10.709 3.413 10.709 3.413"
          />
          <path
            strokeWidth=".5"
            d="M160.839 401.317s.836 2.057-1.173 1.721c-2.008-.336-1.109-3.504 1.173-1.721z"
          />
          <path
            strokeWidth=".5"
            d="M160.688 402.016c.071.1.204 1.061-.605.809-.809-.253.02-1.522.676-.709"
          />
          <path
            strokeWidth=".75"
            d="M150.518 407.95c-2.77 1.619-12.056-.81-10.078-1.417 1.978-.607 3.488-.018 2.476-1.437"
          />
          <path
            strokeWidth=".75"
            d="M155.435 405.941c-1.702 1.215-10.457 8.746-13.457 8.245-.891-.149 2.784-2.7 1.717-2.795-1.067-.095-4.836 1.524-4.66.97.176-.554 1.959-1.255 1.608-2.147-.202-.513-3.539.741-1.228-1.122.527-.425 11.05-2.817 13.387-4.342"
          />
        </g>
        <g fill="#963395" stroke="#1D1D1B" strokeMiterlimit="10" strokeWidth=".75">
          <path d="M321.905 471.812l-4.227 1.14c-4.227 1.14-2.41-3.843-2.41-3.843l-6.707-.188c-6.707-.188 3.482-4.517 3.098-5.787 0 0-7.929-1.48-4.075-4.309l3.855-2.829s-1.536-5.077 2.248-5.391c3.784-.314 4.145 1.794 4.145 1.794s-1.478-7.173 3.122-6.625c4.599.548 4.936 3.494 4.936 3.494s3.995-7.859 6.475-6.531c2.48 1.328 1.152 3.808 1.152 3.808s5.508-1.944 6.253 1.433c.745 3.377-2.237 4.972-2.237 4.972s7.569-.627 4.972 2.237l-2.597 2.864s4.122 2.632 1.979 4.25-5.112 2.794-5.112 2.794 7.487 2.307 3.667 3.878c-3.82 1.571-6.777 2.328-6.777 2.328s2.864 2.597-1.328 2.48c-4.192-.117-6.672-1.445-6.672-1.445s-2.514-.069-3.76-.524z" />
          <path d="M322.222 460.494s-3.585-6.812.176-6.288c3.761.525 2.723 7.627 2.723 7.627M329.908 455.675s-.255-5.88 2.248-5.391 1.07 6.742 1.07 6.742M317.704 457.012s-2.829-3.855-3.307-1.77c-.478 2.084 1.967 4.67 1.967 4.67M328.8 465.293c0-.003.188-6.706 2.644-4.541 2.457 2.166.651 6.73.651 6.73M323.327 465.979s-3.993-7.243-4.529-3.063 1.955 5.089 1.955 5.089M335.964 449.132s3.075-4.948 4.094-3.871c1.019 1.077-.217 7.755-.217 7.755M337.383 458.401s6.51-7.789 5.124-3.213c-1.386 4.576-3.96 6.601-3.96 6.601M329.723 447.28s.607-6.695-1.979-4.25c-2.585 2.445-1.853 6.241-1.853 6.241s.536-4.18-4.936-3.494c-5.473.686 1.932 5.927 1.932 5.927M326.979 461.723s-2.151-2.849-.352-2.38 1.339 1.989 1.339 1.989M327.031 454.893s-2.023-2.427-.348-2.519c1.676-.093 1.617 1.997 1.617 1.997M315.091 463.064s-1.458-2.69-2.469-1.463 1.18 2.682 1.18 2.682M312.406 459.364s-.986-4.628-1.869-2.98.197 2.933.197 2.933M315.113 452.329s-.066-3.227-1.424-1.722c-1.358 1.505.143 2.662.143 2.662M327.195 468.979s-2.581-2.442-1.04-2.678 2.736 1.889 2.736 1.889" />
        </g>
        <g stroke="#1D1D1B" strokeMiterlimit="10">
          <path
            fill="#00C800"
            d="M168.2 325.994s5.693-10.632 16.476-10.331c10.783.301 13.807 7.94 13.656 13.331-.151 5.391-7.849 10.572-17.614 12.457-9.766 1.886-11.711-5.722-12.518-15.457z"
          />
          <path
            fill="#FD0"
            d="M172.392 330.428s-10.331-16.476-11.59-10.036c-1.259 6.44-.543 19.409 2.783 16.265s8.807-6.229 8.807-6.229z"
          />
          <path
            fill="#FFF"
            strokeWidth=".5"
            d="M190.753 329.862s-2.307 5.331-7.699 5.18c-5.391-.151 7.699-5.18 7.699-5.18z"
          />
        </g>
        <g stroke="#1D1D1B" strokeMiterlimit="10">
          <path fill="#FD0" d="M139.973 276.476s-5-7-4.5-3.5 1.5 8.5 1.5 8.5l3-5z" />
          <path
            fill="#00C800"
            d="M138.765 269.195s-1.5 22 3.5 16.5c1.815-1.996 4.15-4.324 4-8-.263-6.454-4.633-13.915-7.5-8.5z"
          />
          <path
            fill="#FFF"
            strokeWidth=".5"
            d="M141.542 278.046c-.854 0-5.338 1.281-3.63 0l1.708-1.281 1.922 1.281z"
          />
        </g>
      </g>

      <g fill="#5DC6FF" className={css(style)}>
        <path
          className={css({
            opacity: '0',
            animationDelay: '1s',
            animationDuration: '6s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(10%,-10%)', },
              '40%': { transform: 'translate(8%,-20%)', },
              '60%': { transform: 'translate(13%,-30%)', },
              '80%': { transform: 'translate(10%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(16%,-50%)', },
            },
          })}
          d="M256 276s-5 1-3 5c1 4 3 5 6 3 3-1 5-8-3-8z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '0s',
            animationDuration: '4.5s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(-5%,-10%)', },
              '40%': { transform: 'translate(-4%,-20%)', },
              '60%': { transform: 'translate(-8%,-30%)', },
              '80%': { transform: 'translate(-6%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(-11%,-50%)', },
            },
          })}
          d="M255 282s-1 4 3 3c3-1-1-5-3-3z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '1.5s',
            animationDuration: '5s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(1%,-10%)', },
              '40%': { transform: 'translate(-1%,-20%)', },
              '60%': { transform: 'translate(1.5%,-30%)', },
              '80%': { transform: 'translate(-1%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(2%,-50%)', },
            },
          })}
          d="M254 279s-2 7 3 6 3-10-3-6z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '.5s',
            animationDuration: '8s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(5%,-10%)', },
              '40%': { transform: 'translate(3%,-20%)', },
              '60%': { transform: 'translate(9%,-30%)', },
              '80%': { transform: 'translate(8%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(17%,-50%)', },
            },
          })}
          d="M255 277s-5 8 2 8 2-10-2-8z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '2.5s',
            animationDuration: '5s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(-8%,-10%)', },
              '40%': { transform: 'translate(-6%,-20%)', },
              '60%': { transform: 'translate(-13%,-30%)', },
              '80%': { transform: 'translate(-10%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(-17%,-50%)', },
            },
          })}
          d="M255 276s-7 7 0 9 11-11 0-9z"
        />
        <path
          className={css({
            opacity: '0',
            animationDuration: '9s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(9%,-10%)', },
              '40%': { transform: 'translate(7%,-20%)', },
              '60%': { transform: 'translate(14%,-30%)', },
              '80%': { transform: 'translate(10%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(20%,-50%)', },
            },
          })}
          d="M256 277c-4 3-5 8 1 8 7-1 5-12-1-8z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '4s',
            animationDuration: '6s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(-8%,-10%)', },
              '40%': { transform: 'translate(-6%,-20%)', },
              '60%': { transform: 'translate(-13%,-30%)', },
              '80%': { transform: 'translate(-11%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(-19%,-50%)', },
            },
          })}
          d="M256 282s-3 3 1 3c4-1 2-4-1-3z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '3s',
            animationDuration: '7s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(1%,-10%)', },
              '40%': { transform: 'translate(-1%,-20%)', },
              '60%': { transform: 'translate(1.5%,-30%)', },
              '80%': { transform: 'translate(-1%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(2%,-50%)', },
            },
          })}
          d="M256 280s-3 4 1 5 3-7-1-5z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '3s',
            animationDuration: '8s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(3%,-10%)', },
              '40%': { transform: 'translate(1%,-20%)', },
              '60%': { transform: 'translate(5%,-30%)', },
              '80%': { transform: 'translate(6%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(9%,-50%)', },
            },
          })}
          d="M255 280s-3 4 1 5c5 1 6-7-1-5z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '1.9s',
            animationDuration: '6s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(-3%,-10%)', },
              '40%': { transform: 'translate(-1%,-20%)', },
              '60%': { transform: 'translate(-5%,-30%)', },
              '80%': { transform: 'translate(-3%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(-7%,-50%)', },
            },
          })}
          d="M257 279c-1 0-6 5-1 6s5-6 1-6z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '1.2s',
            animationDuration: '6.8s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(1%,-10%)', },
              '40%': { transform: 'translate(-1%,-20%)', },
              '60%': { transform: 'translate(1.5%,-30%)', },
              '80%': { transform: 'translate(-1%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(2%,-50%)', },
            },
          })}
          d="M255 280s-3 6 2 5 2-7-2-5z"
        />
        <path
          className={css({
            opacity: '0',
            animationDuration: '5.3s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0%,0)', },
              '20%': { opacity: '0.6', transform: 'translate(-5%,-10%)', },
              '40%': { transform: 'translate(-2%,-20%)', },
              '60%': { transform: 'translate(-10%,-30%)', },
              '80%': { transform: 'translate(-9%,-39%)', },
              '95%': { opacity: '0.6', },
              '100%': {
                opacity: '0',
                transform: 'translate(-17%,-45%)',
              },
            },
          })}
          d="M266 269c-4-4-8-6-14-3 0 0-6 3-5 11 1 9 10 9 15 7s7-12 4-15zm-5 1c-3 0-1-2-1-2 3 0 4 3 1 2z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '3.1s',
            animationDuration: '6.1s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(1%,-10%)', },
              '40%': { transform: 'translate(-1%,-20%)', },
              '60%': { transform: 'translate(1.5%,-30%)', },
              '80%': { transform: 'translate(-1%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(2%,-50%)', },
            },
          })}
          d="M266 269c-4-4-8-6-14-3 0 0-6 3-5 11 1 9 10 9 15 7s7-12 4-15zm-5 1c-3 0-1-2-1-2 3 0 4 3 1 2z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '1.4s',
            animationDuration: '5.3s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(5%,-10%)', },
              '40%': { transform: 'translate(8%,-20%)', },
              '60%': { transform: 'translate(15%,-30%)', },
              '80%': { transform: 'translate(10%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(20%,-50%)', },
            },
          })}
          d="M266 269c-4-4-8-6-14-3 0 0-6 3-5 11 1 9 10 9 15 7s7-12 4-15zm-5 1c-3 0-1-2-1-2 3 0 4 3 1 2z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '1s',
            animationDuration: '6s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(1%,-10%)', },
              '40%': { transform: 'translate(-1%,-20%)', },
              '60%': { transform: 'translate(1.5%,-30%)', },
              '80%': { transform: 'translate(-1%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(2%,-50%)', },
            },
          })}
          d="M253 273s-6 7 1 11c10 6 15-15-1-11zm8 5c-2 1-1-3-2-4 1 0 5 3 2 4z"
        />
        <path
          className={css({
            opacity: '0',
            animationDelay: '2s',
            animationDuration: '8s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: {
              '0%': { opacity: '0', transform: 'translate(0,0)', },
              '20%': { opacity: '0.6', transform: 'translate(1%,-10%)', },
              '40%': { transform: 'translate(-1%,-20%)', },
              '60%': { transform: 'translate(1.5%,-30%)', },
              '80%': { transform: 'translate(-1%,-40%)', },
              '95%': { opacity: '0.6', },
              '100%': { opacity: '0', transform: 'translate(2%,-50%)', },
            },
          })}
          d="M255 276s-7 7 0 9c8 2 11-11 0-9zm4 4c-2 0 0-3 0-3 3-1 2 2 0 3z"
        />
      </g>
    </svg>
  );
}
