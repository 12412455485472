const entitlementEndPageStyle = Object.freeze({
  htz: Object.freeze({
    maxWidthMobile: '45rem',
  }),
  tm: Object.freeze({
    maxWidthMobile: '53rem',
  }),
  hdc: Object.freeze({
    maxWidthMobile: '49rem',
  }),
});

export default entitlementEndPageStyle;
