import React from 'react';
// htz: Object.freeze({

// }),
// tm: Object.freeze({

// }),
// hdc: Object.freeze({

// }),
export const commonI18n = Object.freeze({
  htz: Object.freeze({
    correctEmailTypoText: 'האם התכוונת ל: ',
  }),
  tm: Object.freeze({
    correctEmailTypoText: 'האם התכוונת ל: ',
  }),
  hdc: Object.freeze({
    correctEmailTypoText: 'Did you mean: ',
  }),
});

export const linkToHomePage = Object.freeze({
  htz: Object.freeze({
    href: 'https://www.haaretz.co.il/',
  }),
  tm: Object.freeze({
    href: 'https://www.themarker.com/',
  }),
  hdc: Object.freeze({
    href: 'https://www.haaretz.com/',
  }),
});

export const emailInputTexts = Object.freeze({
  htz: Object.freeze({
    emailInputLabel: 'דוא"ל',
    emailInputRequiredLong: 'שדה חובה',
    emailInputRequiredShort: '*',
    noteText: 'אנא הזינו כתובת דוא”ל',
    buttonText: 'המשך',
    buttonExternalLoginText: 'להרשמה',
    generalError: 'אירעה שגיאה. אנא נסו שנית במועד מאוחר יותר',
    noEmailError: 'אנא הזינו כתובת דוא”ל',
    notValidEmailError: 'אנא הזינו כתובת דוא”ל תקינה',
  }),
  tm: Object.freeze({
    emailInputLabel: 'דוא"ל',
    emailInputRequiredLong: 'שדה חובה',
    emailInputRequiredShort: '*',
    noteText: 'אנא הזינו כתובת דוא”ל',
    buttonText: 'המשך',
    buttonExternalLoginText: 'להרשמה',
    generalError: 'אירעה שגיאה. אנא נסו שנית במועד מאוחר יותר',
    noEmailError: 'אנא הזינו כתובת דוא”ל',
    notValidEmailError: 'אנא הזינו כתובת דוא”ל תקינה',
  }),
  hdc: Object.freeze({
    emailInputLabel: 'Email',
    emailInputRequiredLong: 'Required',
    emailInputRequiredShort: '*',
    noteText: 'Please enter email address',
    buttonText: 'Continue',
    buttonExternalLoginText: 'Registration',
    generalError: 'Error submitting, please try again later',
    noEmailError: 'Please enter your email',
    notValidEmailError: 'Please enter a valid email address',
  }),
});

export const phoneInputTexts = Object.freeze({
  htz: Object.freeze({
    phoneInputLabel: 'מספר טלפון נייד',
    phoneInputRequiredLong: 'אנא הזינו מספר טלפון נייד',
    phoneInputRequiredShort: '*',
    phoneNoteText: 'אנא הזינו מספר טלפון נייד',
    buttonText: 'המשך',
    noPhoneError: 'אנא הזינו מספר טלפון נייד',
    invalidPhoneError: 'אנא הזינו מספר טלפון נייד תקין',
    logiInWithPassword: 'לא כרגע. כניסה באמצעות סיסמה',
    phoneAndMailNotConfirmed: 'בשביל להשתמש בשירות כניסה באמצעות SMS, יש לאמת כתובת דוא"ל ומספר סלולרי',
  }),
  tm: Object.freeze({
    phoneInputLabel: 'מספר טלפון נייד',
    phoneInputRequiredLong: 'אנא הזינו מספר טלפון נייד',
    phoneInputRequiredShort: '*',
    phoneNoteText: 'אנא הזינו מספר טלפון נייד',
    buttonText: 'המשך',
    noPhoneError: 'אנא הזינו מספר טלפון נייד',
    invalidPhoneError: 'אנא הזינו מספר טלפון נייד תקין',
    logiInWithPassword: 'לא כרגע. כניסה באמצעות סיסמה',
    phoneAndMailNotConfirmed: 'בשביל להשתמש בשירות כניסה באמצעות SMS, יש לאמת כתובת דוא"ל ומספר סלולרי',
  }),
  hdc: Object.freeze({
    countryCodeInputLabel: 'Country code',
    phoneInputLabel: 'Phone number',
    countryCodeInputRequiredLong: 'Please enter country code',
    phoneInputRequiredLong: 'Please enter your mobile phone number',
    countryCodeInputRequiredShort: '*',
    phoneInputRequiredShort: '*',
    countryCodeNoteText: 'Please enter country code',
    phoneNoteText: 'Please enter your mobile phone number',
    buttonText: 'Continue',
    noCountryCodeError: 'Please enter country code',
    noPhoneError: 'Please enter your mobile phone number',
    invalidPhoneError: 'Please enter a valid mobile phone number',
    logiInWithPassword: 'Not now. Log in using password',
    phoneAndMailNotConfirmed: 'To use a sign-in service via SMS. An email address and a mobile number must be verified',
  }),
});

export const passwordInputTexts = Object.freeze({
  htz: Object.freeze({
    passwordLabel: 'סיסמה',
    passwordNote: 'אנא הזינו סיסמה',
    requiredLong: 'שדה חובה',
    requiredShort: '*',
    noPasswordError: 'אנא הזינו סיסמה',
    invalidPasswordError: 'ש להזין סיסמה בת 6 תווים ומעלה',
  }),
  tm: Object.freeze({
    passwordLabel: 'סיסמה',
    passwordNote: 'אנא הזינו סיסמה',
    requiredLong: 'שדה חובה',
    requiredShort: '*',
    noPasswordError: 'אנא הזינו סיסמה',
    invalidPasswordError: 'ש להזין סיסמה בת 6 תווים ומעלה',
  }),
  hdc: Object.freeze({
    passwordLabel: 'Password',
    passwordNote: 'Please enter a password',
    requiredLong: 'required',
    requiredShort: '*',
    noPasswordError: 'Please enter a password',
    invalidPasswordError: 'Please enter a password that is at least 6 characters in length',
  }),
});

export const nameInputTexts = Object.freeze({
  htz: Object.freeze({
    firstNameLabel: 'שם פרטי',
    firstNameNote: 'אנא הזינו שם פרטי',
    noFirstNameError: 'אנא הזינו שם פרטי',
    invalidFirstNameError: 'יש להזין שם המורכב מ-2 אותיות לפחות וללא מספרים',
    lastNameLabel: 'שם משפחה',
    lastNameNote: 'אנא הזינו שם משפחה',
    noLastNameError: 'אנא הזינו שם משפחה',
    invalidLastNameError: 'יש להזין שם המורכב מ-2 אותיות לפחות וללא מספרים',
    requiredLong: 'שדה חובה',
    requiredShort: '*',
  }),
  tm: Object.freeze({
    firstNameLabel: 'שם פרטי',
    firstNameNote: 'אנא הזינו שם פרטי',
    noFirstNameError: 'אנא הזינו שם פרטי',
    invalidFirstNameError: 'יש להזין שם המורכב מ-2 אותיות לפחות וללא מספרים',
    lastNameLabel: 'שם משפחה',
    lastNameNote: 'אנא הזינו שם משפחה',
    noLastNameError: 'אנא הזינו שם משפחה',
    invalidLastNameError: 'יש להזין שם המורכב מ-2 אותיות לפחות וללא מספרים',
    requiredLong: 'שדה חובה',
    requiredShort: '*',
  }),
  hdc: Object.freeze({
    firstNameLabel: 'First name',
    firstNameNote: 'Please enter your first name',
    noFirstNameError: 'Please enter your first name',
    invalidFirstNameError: 'Please enter a name from at least 2 characters and with no digits',
    lastNameLabel: 'Last name',
    lastNameNote: 'Please enter your last name',
    noLastNameError: 'Please enter your last name',
    invalidLastNameError: 'Please enter a name from at least 2 characters and with no digits',
    requiredLong: 'required',
    requiredShort: '*',
  }),
});

export const footerMobileListsI18n = Object.freeze({
  MobileList: [
    Object.freeze({
      text: 'רכשו מנוי',
      link: 'https://www.haaretz.co.il/offers',
    }),
    Object.freeze({
      text: 'מדיניות פרטיות',
      link: 'https://www.haaretz.co.il/misc/privacy-policy',
    }),
    Object.freeze({
      text: 'תנאי שימוש',
      link: 'https://www.haaretz.co.il/misc/terms-of-use',
    }),
    Object.freeze({
      text: 'צרו קשר',
      link: 'https://www.haaretz.co.il/misc/contact-us',
    }),
    Object.freeze({
      text: 'פרסמו באתר',
      link: 'https://www.haaretz.co.il/st/c/prod/global/mediaKit/haaretzHeb.html',
    }),
    Object.freeze({
      text: 'שירות למנויים',
      link: 'https://www.haaretz.co.il/personal-area/my-account',
    }),
  ],
  ButtonName: Object.freeze({
    text: 'להורדת האפליקציה',
    androidAppUrl: 'https://kg3g3.app.goo.gl/4UAu',
    iosAppUrl: 'https://kg3g3.app.goo.gl/4UAu',
  }),
  Copyright: Object.freeze({
    text: '© כל הזכויות שמורות',
  }),
});

export const footer = Object.freeze({
  htz: Object.freeze({
    homePageLink: Object.freeze({
      text: 'דף הבית',
    }),
    links: [
      // when adding links make sure to add a uniqe id
      {
        text: 'שאלות ותשובות',
        href: 'https://www.haaretz.co.il/misc/faq',
        hrefMobile: 'https://www.haaretz.co.il/misc/faq',
        id: 'q&a',
      },
      {
        text: 'צרו קשר',
        href: 'https://www.haaretz.co.il/misc/contact-us',
        hrefMobile: 'https://www.haaretz.co.il/misc/contact-us',
        id: 'contact',
      },
    ],
  }),
  tm: Object.freeze({
    homePageLink: Object.freeze({
      text: 'דף הבית',
    }),
    links: [
      // when adding links make sure to add a uniqe id
      {
        text: 'שאלות ותשובות',
        href: 'https://www.themarker.com/misc/faq',

        hrefMobile: 'https://www.themarker.com/misc/faq',

        id: 'q&a',
      },
      {
        text: 'צרו קשר',
        href: 'https://www.themarker.com/misc/contact-us',
        hrefMobile: 'https://www.themarker.com/mobile/contact-us',
        id: 'contact',
      },
    ],
  }),
  hdc: Object.freeze({
    homePageLink: Object.freeze({
      text: 'Home Page',
    }),
    links: [
      // when adding links make sure to add a uniqe id
      {
        text: 'FAQ',
        href: 'https://www.haaretz.com/misc/faq',
        hrefMobile: 'https://www.haaretz.com/misc/faq',
        id: 'q&a',
      },
      {
        text: 'contact us',
        href: 'https://www.haaretz.com/contact-us',
        hrefMobile: 'https://www.haaretz.com/mobile/contact-us',
        id: 'contact',
      },
    ],
  }),
});

export const footerDesktopI18n = Object.freeze({
  ExpandedButton: Object.freeze({
    close: 'סגור',
    showMore: 'הצג עוד',
  }),
  Copyright: Object.freeze({
    firstRow:
      'חדשות, ידיעות מהארץ והעולם - הידיעות והחדשות בעיתון הארץ. סקופים, מאמרים, פרשנויות ותחקירי עומק באתר האיכותי בישראל',
    secondRow: '© כל הזכויות שמורות להוצאת עיתון הארץ בע"מ',
  }),
});

export const headerI18n = Object.freeze({
  htz: Object.freeze({
    login: 'התחברות',
    loginExternal: 'מינוי דיגיטלי',
    register: 'הרשמה',
    resetPassword: 'איפוס סיסמה',
    entitlementCheck: 'בדיקת זכאות',
    abuse: 'פעילות חריגה',
  }),
  tm: Object.freeze({
    login: 'התחברות',
    loginExternal: 'מינוי דיגיטלי',
    register: 'הרשמה',
    resetPassword: 'איפוס סיסמה',
    entitlementCheck: 'בדיקת זכאות',
    abuse: 'פעילות חריגה',
  }),
  hdc: Object.freeze({
    login: 'Log In',
    loginExternal: 'Log In',
    register: 'Sign-up',
    resetPassword: 'Reset password',
    entitlementCheck: 'Entitlement Check',
    abuse: 'פעילות חריגה',
  }),
});

export const bottomLinksI18n = Object.freeze({
  htz: Object.freeze({
    signUpBeforeLink: 'עדיין לא רשומים?',
    signUpLinkText: 'הירשמו',
    loginBeforeLink: 'כבר רשומים?',
    loginLinkText: 'התחברו',
  }),
  tm: Object.freeze({
    signUpBeforeLink: 'עדיין לא רשומים?',
    signUpLinkText: 'הירשמו',
    loginBeforeLink: 'כבר רשומים?',
    loginLinkText: 'התחברו',
  }),
  hdc: Object.freeze({
    signUpBeforeLink: "Don't have an account?",
    signUpLinkText: 'Sign up',
    loginBeforeLink: 'Already have an account?',
    loginLinkText: 'Login',
  }),
});

export const indexFormI18n = Object.freeze({
  htz: Object.freeze({
    title: 'לכניסה או להרשמה לאתר הזינו כתובת דוא”ל',
  }),
  tm: Object.freeze({
    title: 'לכניסה או להרשמה לאתר הזינו כתובת דוא”ל',
  }),
  hdc: Object.freeze({
    title: 'To log in or sign up, please enter your email address',
  }),
});

export const indexFormExternalI18n = Object.freeze({
  htz: Object.freeze({
    title: 'הירשמו בחינם וקבלו גישה לעד 6 כתבות בחודש',
    benefits: "חשיפות ותחקירים • סיקור חדשותי בזמן אמת • כתבות מגזין • בינג'ים מעמיקים • כתבות אינטראקטיביות •  ביקורות והמלצות קולינריות",
    instruction: 'להרשמה לאתר הזינו כתובת דוא”ל',
  }),
  tm: Object.freeze({
    title: 'הירשמו בחינם וקבלו גישה לעד 6 כתבות בחודש',
    benefits: 'חדשות ומידע פיננסי • חשיפות ותחקירים • עדכונים משוק ההון • כתבות מגזין • ראיונות עומק • טיפים ומדריכים',
    instruction: 'להרשמה לאתר הזינו כתובת דוא”ל',
  }),
  hdc: Object.freeze({
    title: 'To log in or sign up, please enter your email address',
    benefits: '',
    instruction: '',
  }),
});

export const registerFormI18n = Object.freeze({
  htz: Object.freeze({
    title: 'הרשמה',
    titleEntitled: 'בעוד רגע תוכלו לגלוש באתר הארץ ללא הגבלה.',
    titleEntitledMobile: 'בעוד רגע תוכלו לגלוש ללא הגבלה.',
    subtitleEntitled: 'אנא השלימו את תהליך ההרשמה',
    subtitleEntitledMobile: null,
    buttonText: 'הרשמה',
    termsTextBeforeLink: 'אני מאשר/ת את',
    termsTextLink: 'תנאי השימוש',
    termsTextAfterLink:
      'וכן קבלת המלצות קריאה, הצעות לרכישת מינוי ודיוור מהארץ-TheMarker',
    link: 'https://www.haaretz.co.il/misc/terms-of-use',
    termsNoteText: 'יש לאשר את תנאי השימוש',
  }),
  tm: Object.freeze({
    title: 'הרשמה',
    titleEntitled: 'בעוד רגע תוכלו לגלוש באתר TheMarker ללא הגבלה.',
    titleEntitledMobile: 'בעוד רגע תוכלו לגלוש ללא הגבלה.',
    subtitleEntitled: 'אנא השלימו את תהליך ההרשמה',
    subtitleEntitledMobile: null,
    buttonText: 'הרשמה',
    termsTextBeforeLink: 'אני מאשר/ת את',
    termsTextLink: 'תנאי השימוש',
    termsTextAfterLink:
      'וכן קבלת המלצות קריאה, הצעות לרכישת מינוי ודיוור מהארץ-TheMarker',
    link: 'https://www.themarker.com/misc/site-policy',
    termsNoteText: 'יש לאשר את תנאי השימוש',
  }),
  hdc: Object.freeze({
    title: 'Sign up',
    titleEntitled: 'Create your Haaretz account today.',
    titleEntitledMobile: null,
    subtitleEntitled: 'Complete your details to sign up',
    subtitleEntitledMobile: null,
    buttonText: 'Sign up',
    termsTextBeforeLink: 'I have read and agreed to Haaretz',
    termsTextLink: 'terms and conditions',
    termsTextAfterLink: '',
    link: 'https://www.haaretz.com/misc/terms-and-conditions',
    termsNoteText: 'Please confirm terms of use',
  }),
});

export const loginFormsI18n = Object.freeze({
  htz: Object.freeze({
    entitled: Object.freeze({
      full: Object.freeze({
        title: 'בעוד רגע תוכלו לגלוש באתר הארץ ללא הגבלה.',
        titleMobile: 'בעוד רגע תוכלו לגלוש ללא הגבלה.',
        subtitle: 'להשלמת התהליך, אנא התחברו עם דוא"ל וסיסמה',
        subtitleMobile: 'אנא התחברו עם דוא"ל וסיסמה',
      }),
      partial: Object.freeze({
        title: 'ברכות, אתם זכאים להטבה מיוחדת!',
        titleMobile: null,
        subtitle: 'להשלמת התהליך הזינו סיסמה',
        subtitleMobile: null,
      }),
    }),
    smsTabTitle: 'כניסה עם קוד SMS',
    passwordTabTitle: 'כניסה עם סיסמה',
    emailInputLabel: 'דוא"ל',
    passwordEmailNote: 'אנא הזינו כתובת דוא”ל',
    passwordPasswordNote: 'אנא הזינו סיסמה',
    forgotPassword: 'שכחתי סיסמה',
    noEmail: 'אנא הזינו כתובת דוא”ל',
    invalidEmail: 'אנא הזינו כתובת דוא”ל תקינה',
    noPassword: 'אנא הזינו סיסמה',
    invalidPassword: 'אנא הזינו סיסמה תקינה',
    checkBoxNoteText: 'יש לאשר את תנאי השימוש באתר',
    phoneFormsMessage: 'להתחברות הזינו את הקוד ששלחנו למספר',
    termsTextBeforeLink: 'אני מאשר/ת את',
    termsTextLink: 'תנאי השימוש',
    termsTextAfterLink:
      'וכן קבלת המלצות קריאה, הצעות לרכישת מינוי ודיוור מהארץ-TheMarker',
    link: 'https://www.haaretz.co.il/misc/terms-of-use',
    termsNoteText: 'יש לאשר את תנאי השימוש',
    loginButton: 'התחברות',
    loginWithoutPasswordButton: 'התחברות ללא סיסמה',
    loginWithoutPasswordButtonUpdate: 'לקבלת קישור מעודכן',
    loginWithoutPasswordBackToHomePage: 'בחזרה לעמוד הבית',
    loginWithoutPasswordBeforeEmail: 'שלחנו לכתובת',
    loginWithoutPasswordAfterEmail: 'הוראות התחברות ללא סיסמה',
    loginWithoutPasswordGeneralError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
    loginWithoutPasswordBackToLoginPage: 'בחזרה לעמוד ההתחברות',
    changePasswordTitle: 'החלפת סיסמה',
    changePasswordButton: 'המשך',
    changePasswordInstructions: 'הוראות לאיפוס הסיסמה נשלחו לתיבת הדוא”ל שלך',
    changePasswordCloseButton: 'סגור',
    forgotPasswordTitle: 'לא מצליחים להתחבר?',
    forgotPasswordBeforeEmail: 'שלחנו לדוא"ל',
    forgotPasswordAfterEmail: 'הוראות לאיפוס הסיסמה',
    wrongNumber: 'לא המספר שלכם?',
  }),
  tm: Object.freeze({
    title: 'באפשרותכם להתחבר לאתר בשתי דרכים',
    entitled: Object.freeze({
      full: Object.freeze({
        title: 'בעוד רגע תוכלו לגלוש באתר TheMarker ללא הגבלה.',
        titleMobile: 'בעוד רגע תוכלו לגלוש ללא הגבלה.',
        subtitle: 'להשלמת התהליך, אנא התחברו עם דוא"ל וסיסמה',
        subtitleMobile: 'אנא התחברו עם דוא"ל וסיסמה',
      }),
      partial: Object.freeze({
        title: 'ברכות, אתם זכאים להטבה מיוחדת!',
        titleMobile: null,
        subtitle: 'להשלמת התהליך הזינו סיסמה',
        subtitleMobile: null,
      }),
    }),
    smsTabTitle: 'כניסה עם קוד SMS',
    passwordTabTitle: 'כניסה עם סיסמה',
    emailInputLabel: 'דוא"ל',
    passwordEmailNote: 'אנא הזינו כתובת דוא”ל',
    passwordPasswordNote: 'אנא הזינו סיסמה',
    forgotPassword: 'שכחתי סיסמה',
    noEmail: 'אנא הזינו כתובת דוא”ל',
    invalidEmail: 'אנא הזינו כתובת דוא”ל תקינה',
    noPassword: 'אנא הזינו סיסמה',
    invalidPassword: 'אנא הזינו סיסמה תקינה',
    checkBoxNoteText: 'יש לאשר את תנאי השימוש באתר',
    phoneFormsMessage: 'להתחברות הזינו את הקוד ששלחנו למספר',
    termsTextBeforeLink: 'אני מאשר/ת את',
    termsTextLink: 'תנאי השימוש',
    termsTextAfterLink:
      'וכן קבלת המלצות קריאה, הצעות לרכישת מינוי ודיוור מהארץ-TheMarker',
    link: 'https://www.themarker.com/misc/terms-of-use',
    termsNoteText: 'יש לאשר את תנאי השימוש',
    loginButton: 'התחברות',
    loginWithoutPasswordButton: 'התחברות ללא סיסמה',
    loginWithoutPasswordButtonUpdate: 'לקבלת קישור מעודכן',
    loginWithoutPasswordBackToHomePage: 'בחזרה לעמוד הבית',
    loginWithoutPasswordBeforeEmail: 'שלחנו לכתובת',
    loginWithoutPasswordAfterEmail: 'הוראות התחברות ללא סיסמה',
    loginWithoutPasswordGeneralError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
    loginWithoutPasswordBackToLoginPage: 'בחזרה לעמוד ההתחברות',
    changePasswordTitle: 'החלפת סיסמה',
    changePasswordButton: 'המשך',
    changePasswordInstructions: 'הוראות לאיפוס הסיסמה נשלחו לתיבת הדוא”ל שלך',
    changePasswordCloseButton: 'סגור',
    forgotPasswordTitle: 'לא מצליחים להתחבר?',
    forgotPasswordBeforeEmail: 'שלחנו לדוא"ל',
    forgotPasswordAfterEmail: 'הוראות לאיפוס הסיסמה',
    wrongNumber: 'לא המספר שלכם?',
  }),
  hdc: Object.freeze({
    title: 'Log in to the site in one of two ways',
    smsTabTitle: 'Log in with SMS',
    entitled: Object.freeze({
      full: Object.freeze({
        title: 'Ready to enjoy Haaretz unlimited access?',
        titleMobile: null,
        subtitle: 'Please log in with your email & password',
        subtitleMobile: null,
      }),
      partial: Object.freeze({
        title: 'Ready to enjoy Haaretz unlimited access?',
        titleMobile: null,
        subtitle: 'Please log in with your email & password',
        subtitleMobile: null,
      }),
    }),
    passwordTabTitle: 'Log in with password',
    emailInputLabel: 'Email',
    passwordEmailNote: 'Please enter email address',
    passwordPasswordNote: 'Please enter password',
    forgotPassword: 'Forgot password',
    noEmail: 'Please enter email address',
    invalidEmail: 'Please enter a valid email address',
    noPassword: 'Please enter password',
    invalidPassword: 'Please enter a valid password',
    checkBoxNoteText: 'Please confirm terms of use',
    phoneFormsMessage: 'To log in, please enter the code sent to',
    termsTextBeforeLink: 'I have read and agreed to Haaretz',
    termsTextLink: 'terms and conditions',
    termsTextAfterLink: '',
    link: 'https://www.haaretz.com/misc/terms-and-conditions',
    termsNoteText: 'Please confirm terms of use',
    loginButton: 'Log In',
    loginWithoutPasswordButton: 'Log In Without Password',
    loginWithoutPasswordButtonUpdate: 'Get a new link',
    loginWithoutPasswordBackToHomePage: 'Back to homepage',
    loginWithoutPasswordBeforeEmail: 'An email was sent to',
    loginWithoutPasswordAfterEmail: 'with further instructions to log in without password',
    loginWithoutPasswordGeneralError: 'An error has occurred, please try again later',
    loginWithoutPasswordBackToLoginPage: 'Log in to Haaretz',
    changePasswordTitle: 'Change password',
    changePasswordButton: 'Continue',
    changePasswordInstructions: 'Instructions to reset password have been sent to your email',
    changePasswordCloseButton: 'Close',
    forgotPasswordTitle: "Can't Log In?",
    forgotPasswordBeforeEmail: 'An email was sent to: ',
    forgotPasswordAfterEmail: 'with instructions to reset your password',
    wrongNumber: 'Wrong number?',
  }),
});

export const emailValidationSentI18n = Object.freeze({
  htz: Object.freeze({
    title: 'נשלח אליכם דוא"ל לכתובת:',
    confirmText: 'יש ללחוץ על כפתור האישור בדוא"ל שקיבלתם להמשך הגלישה באתר',
    notRightEmail: 'טעות בכתובת? ',
    mailFailText: 'הדוא"ל לא הגיע? ',
    tryAgain: 'אנא נסו שנית',
    purchase: 'או רכשו מינוי',
    updateEmail: 'להחלפה לחצו כאן',
    updateEmailLink: 'https://haaretz.co.il/personal-area/personal-page',
  }),
  tm: Object.freeze({
    title: 'נשלח אליכם דוא"ל לכתובת:',
    confirmText: 'יש ללחוץ על כפתור האישור בדוא"ל שקיבלתם להמשך הגלישה באתר',
    notRightEmail: 'טעות בכתובת? ',
    mailFailText: 'הדוא"ל לא הגיע? ',
    tryAgain: 'אנא נסו שנית',
    purchase: 'או רכשו מינוי',
    updateEmail: 'להחלפה לחצו כאן',
    updateEmailLink: 'https://themarker.com/personal-area/personal-page',
  }),
  hdc: Object.freeze({
    title: 'An email was sent to',
    confirmText: 'Check your inbox and confirm your email address to finish registering.',
    confirmSubText: (
      <>
        Once you do, you&lsquo;ll get six free articles a month from
        {' '}
        <a href="https://haaretz.com"><b><u>Haaretz.com</u></b></a>
        , Israel&lsquo;s leading newspaper.
      </>
    ),
    notRightEmail: 'Not your email address? ',
    mailFailText: "Didn't get the email? ",
    tryAgain: 'Try again',
    purchase: ' Buy a subscription for full access',
    updateEmail: 'Click here',
    updateEmailLink: 'https://haaretz.com/account/personal-area',
  }),
});

export const emailValidationSent2I18n = Object.freeze({
  htz: Object.freeze({
    title: 'שלחנו אליכם דוא"ל נוסף',
    didntArrive: 'לא הגיע?',
    contactCustomerService: 'פניה לשירות לקוחות',
    logInWithPassword: 'כניסה באמצעות סיסמה',
  }),
  tm: Object.freeze({
    title: 'שלחנו אליכם דוא"ל נוסף',
    didntArrive: 'לא הגיע?',
    contactCustomerService: 'פניה לשירות לקוחות',
    logInWithPassword: 'כניסה באמצעות סיסמה',
  }),
  hdc: Object.freeze({
    title: 'We sent you another email',
    didntArrive: "Didn't get the email?",
    contactCustomerService: 'Contact our customer service',
    logInWithPassword: 'Log in with a password',
  }),
});

export const phoneMailSentI18n = Object.freeze({
  htz: Object.freeze({
    title: 'נשלח אליכם דוא"ל שבאמצעותו ניתן לוודא את מספר הטלפון',
    message:
      'יש ללחוץ על כפתור האישור בהודעת הדוא"ל שתישלח אליכם ולאחר מכן, יישלח קוד SMS.',
    didntArrive: 'לא הגיע?',
    resend: 'לשליחה חוזרת',
    resendLinkText: 'לחצו כאן',
    logInWithPassword: 'להתחברות באמצעות סיסמה',
    otpLabel: 'קוד אימות',
    otpLong: 'אנא הזינו את הקוד ששלחנו אליכם',
    otpShort: '*',
    otpNote: 'אנא הזינו את הקוד ששלחנו אליכם',
    otpSendAgainButton: 'שלח שוב',
    otpLogInButton: 'התחברות',
    otpSmsCodeError: 'אנא הזינו את הקוד ששלחנו אליכם',
  }),
  tm: Object.freeze({
    title: 'נשלח אליכם דוא"ל שבאמצעותו ניתן לוודא את מספר הטלפון',
    message:
      'יש ללחוץ על כפתור האישור בהודעת הדוא"ל שתישלח אליכם ולאחר מכן, יישלח קוד SMS. אנא, הזינו אותו כאן:',
    didntArrive: 'לא הגיע?',
    resend: 'לשליחה חוזרת',
    resendLinkText: 'לחצו כאן',
    logInWithPassword: 'להתחברות באמצעות סיסמה',
    otpLabel: 'קוד אימות',
    otpLong: 'אנא הזינו את הקוד ששלחנו אליכם',
    otpShort: '*',
    otpNote: 'אנא הזינו את הקוד ששלחנו אליכם',
    otpSendAgainButton: 'שלח שוב',
    otpLogInButton: 'התחברות',
    otpSmsCodeError: 'אנא הזינו את הקוד ששלחנו אליכם',
  }),
  hdc: Object.freeze({
    title: 'An email was sent to you to verify your phone number',
    message:
      'Please click the confirmation button in the email sent to you. A SMS message will be then sent to your phone, please enter it here',
    didntArrive: "Didn't get an SMS?",
    resend: 'Try again by',
    resendLinkText: 'clicking here',
    logInWithPassword: 'Log in with a password',
    otpLabel: 'Verification code',
    otpLong: 'Please enter the SMS verification code',
    otpShort: '*',
    otpNote: 'Please enter the SMS verification code',
    otpSendAgainButton: 'Send again',
    otpLogInButton: 'Log in',
    otpSmsCodeError: 'Please enter the SMS verification code we sent you',
  }),
});

export const phoneMailSent2I18n = Object.freeze({
  htz: Object.freeze({
    title: 'שלחנו שוב את הדוא"ל',
    didntGet: 'לא הגיע?',
    contactCustomerService: 'פנו לשירות הלקוחות שלנו',
    logInWithPassword: 'להתחברות באמצעות הסיסמה שברשותכם',

  }),
  tm: Object.freeze({
    title: 'שלחנו שוב את הדוא"ל',
    didntGet: 'לא הגיע?',
    contactCustomerService: 'פנו לשירות הלקוחות שלנו',
    logInWithPassword: 'להתחברות באמצעות הסיסמה שברשותכם',
  }),
  hdc: Object.freeze({
    title: 'We have resent the email.',
    didntGet: 'Didn’t get the mail?',
    contactCustomerService: 'Please contact Customer Services',
    logInWithPassword: 'To connect using your password',
  }),
});

export const otpValidationI18n = Object.freeze({
  htz: Object.freeze({
    title: 'להתחברות הזינו את הקוד שנשלח למספר',
    enterCode: 'אנא הזינו את הקוד שנשלח אליכם',
    sendAgain: 'שלח בשנית',
    logInWithPassword: 'כניסה באמצעות סיסמה',
    notYourPhone: 'לא הטלפון שלך?',
  }),
  tm: Object.freeze({
    title: 'להתחברות הזינו את הקוד שנשלח למספר',
    enterCode: 'אנא הזינו את הקוד שנשלח אליכם',
    sendAgain: 'שלח בשנית',
    logInWithPassword: 'כניסה באמצעות סיסמה',
    notYourPhone: 'לא הטלפון שלך?',
  }),
  hdc: Object.freeze({
    title: 'To connect, please enter the code we sent to',
    enterCode: 'Please enter the code we sent you',
    sendAgain: 'Send again',
    notYourPhone: 'Is this not your phone number?',
    logInWithPassword: 'Log in using password',
  }),
});

export const otpValidation2I18n = Object.freeze({
  htz: Object.freeze({
    title: 'שלחנו שוב את הקוד אנא הזינו את הקוד ששלחנו למספר',
    beforeContactCustomerService: 'או',
    contactCustomerService: 'פנו לשירות לקוחות שלנו',
  }),
  tm: Object.freeze({
    title: 'שלחנו שוב את הקוד אנא הזינו את הקוד ששלחנו למספר',
    beforeContactCustomerService: 'או',
    contactCustomerService: 'פנו לשירות לקוחות שלנו',
  }),
  hdc: Object.freeze({
    title: 'Please enter the code that has been resent to',
    beforeContactCustomerService: 'or',
    contactCustomerService: 'contact our customer service',
  }),
});


export const selectAriaLabel = Object.freeze({
  hdc: Object.freeze({
    text: 'Please enter country code',
  }),
});


export const resetPasswordFormI18n = Object.freeze({
  htz: Object.freeze({
    title: 'החלפת סיסמה',
    subTitle: 'נא להזין סיסמה חדשה לחשבון',
    expiredToken: Object.freeze({
      errorText: 'תוקף הקישור לקביעת הסיסמה החדשה פג. אנא בצעו שוב את פעולת בקשת הסיסמה החדשה.',
      buttonText: 'שלחו לי קישור נוסף',
      mailSended: 'שלחנו לך דוא"ל עם לינק חדש',
    }),
    success: Object.freeze({
      text: 'הסיסמה הוחלפה בהצלחה',
      buttonText: 'המשך',
      href: 'https://haaretz.co.il',
    }),
    passwordInput: Object.freeze({
      label: 'סיסמה חדשה',
      requiredLong: 'שדה חובה',
      requiredShort: '*',
      noteText: 'אנא הזינו סיסמה בת 6 תווים לפחות',
      buttonText: 'המשך',
      generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
      notValidError: 'אנא הזינו כתובת דוא”ל תקינה',
      errorTextNoPassword: 'נא למלא את הסיסמה שאיתה נרשמתם בעבר לאתר',
      errorTextUnderFiveChars: 'יש להזין סיסמה בת 6 תווים ומעלה',
    }),
    passwordConfirmationInput: Object.freeze({
      label: 'אימות סיסמה חדשה',
      requiredLong: 'שדה חובה',
      requiredShort: '*',
      noteText: 'אנא הזינו את הסיסמה שוב',
      buttonText: 'המשך',
      generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
      notValidError: 'אנא הזינו כתובת דוא”ל תקינה',
      notEqual: 'הסיסמאות שהזנת אינן זהות',
    }),
    errors: Object.freeze({
      usedPassword: 'יש לבחור סיסמה חדשה שלא היתה בשימוש בעבר',
    }),
  }),
  tm: Object.freeze({
    title: 'החלפת סיסמה',
    subTitle: 'נא להזין סיסמה חדשה לחשבון',
    expiredToken: Object.freeze({
      errorText: 'תוקף הקישור לקביעת הסיסמה החדשה פג. אנא בצעו שוב את פעולת בקשת הסיסמה החדשה.',
      buttonText: 'שלחו לי קישור נוסף',
      mailSended: 'שלחנו לך דוא"ל עם לינק חדש',
    }),
    success: Object.freeze({
      text: 'הסיסמה הוחלפה בהצלחה',
      buttonText: 'המשך',
      href: 'https://themarker.com',
    }),
    passwordInput: Object.freeze({
      label: 'סיסמה חדשה',
      requiredLong: 'שדה חובה',
      requiredShort: '*',
      noteText: 'אנא הזינו סיסמה בת 6 תווים לפחות',
      buttonText: 'המשך',
      generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
      notValidError: 'אנא הזינו כתובת דוא”ל תקינה',
      errorTextNoPassword: 'נא למלא את הסיסמה שאיתה נרשמתם בעבר לאתר',
      errorTextUnderFiveChars: 'יש להזין סיסמה בת 6 תווים ומעלה',
    }),
    passwordConfirmationInput: Object.freeze({
      label: 'אימות סיסמה חדשה',
      requiredLong: 'שדה חובה',
      requiredShort: '*',
      noteText: 'אנא הזינו את הסיסמה שוב',
      buttonText: 'המשך',
      generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
      notValidError: 'אנא הזינו כתובת דוא”ל תקינה',
      errorTextNoPassword: 'נא למלא את הסיסמה שאיתה נרשמתם בעבר לאתר',
      notEqual: 'הסיסמאות שהזנת אינן זהות',
    }),
    errors: Object.freeze({
      usedPassword: 'יש לבחור סיסמה חדשה שלא היתה בשימוש בעבר',
    }),
  }),
  hdc: Object.freeze({
    title: 'Forgot Your Password',
    subTitle: 'Please enter a new password for your account',
    expiredToken: Object.freeze({
      errorText: 'The link for updating your password expired. Please redo the previous steps for resetting your password.',
      buttonText: 'Resend new link',
      mailSended: 'A new email has been sent',
    }),
    success: Object.freeze({
      text: 'Password updated successfully!',
      buttonText: 'Continue',
      href: 'https://haaretz.com',
    }),
    passwordInput: Object.freeze({
      label: 'New Password',
      requiredLong: 'Required',
      requiredShort: '*',
      noteText: 'Please enter a password that is at least 6 characters in length',
      buttonText: 'Continue',
      generalError: 'Error submitting, please try again later',
      notValidError: 'Please enter your email',
      errorTextNoPassword: 'Enter the password you use on Haaretz.com',
      errorTextUnderFiveChars: 'Please enter a password that is at least 6 characters in length',
    }),
    passwordConfirmationInput: Object.freeze({
      label: 'Retype New Password',
      requiredLong: 'Required',
      requiredShort: '*',
      noteText: 'Please retype the same password again',
      buttonText: 'Continue',
      generalError: 'Error submitting, please try again later',
      notValidError: 'Please enter your email',
      errorTextNoPassword: 'Enter the password you use on Haaretz.com',
      notEqual: 'The passwords you have inserted is not identical',
    }),
    errors: Object.freeze({
      usedPassword: 'Please choose a new password that hasn\'t been used before',
    }),
  }),
});

export const appValidationDialogI18n = Object.freeze({
  htz: Object.freeze({
    success: Object.freeze({
      title: 'תודה!',
      text: 'כעת ניתן לחזור לאפליקציה ולהמשיך להשתמש בה',
    }),
    failed: Object.freeze({
      title: 'שגיאת תקשורת',
      text: 'נסו שוב מאוחר יותר',
    }),
  }),
});

export const entitlementEndPageI18n = Object.freeze({
  htz: Object.freeze({
    firstParagraph: variant => `${variant === 'register' ? 'ההרשמה' : 'ההתחברות'} בוצעה בהצלחה. ברוכים הבאים לאתר הארץ!`,
    secondParagraph: 'התחילו את ההיכרות שלכם עם כמה המלצות קריאה',
    homepageLinkText: 'אני רוצה לשוטט באתר בעצמי',
  }),
  tm: Object.freeze({
    firstParagraph: variant => `${variant === 'register' ? 'ההרשמה' : 'ההתחברות'} בוצעה בהצלחה. ברוכים הבאים לאתר TheMarker!`,
    secondParagraph: 'התחילו את ההיכרות שלכם עם כמה המלצות קריאה',
    homepageLinkText: 'אני רוצה לשוטט באתר בעצמי',
  }),
  hdc: Object.freeze({
    firstParagraph: variant => `You have successfully signed ${variant === 'register' ? 'up' : 'in'} to Haaretz online!`,
    secondParagraph: 'Start off with our recent must-read articles:',
    homepageLinkText: 'I prefer to navigate the site on my own',
  }),
});

const getEntitlementContactHref = (domain, email) => (
  `https://www.${domain}/misc/contact-us?issue=digitalGeneral${email ? '&email=' : ''}${email || ''}`
);

export const entitlementCheckI18n = Object.freeze({
  htz: Object.freeze({
    emailInputStage: () => ({
      firstParagraph: 'איזה כיף, יכול להיות שמגיעה לכם הטבה!',
      secondParagraph: 'הזינו כתובת דוא"ל כדי לבדוק את זה',
    }),
    errorStage: (errorCount, email) => ({
      firstParagraph: 'אופס, לא הצלחנו לגלות האם אתם זכאים להטבה',
      secondParagraph: (
        <>
          אנא נסו שוב או
          {' '}
          <a href={getEntitlementContactHref('haaretz.co.il', email)}>
            צרו קשר עם שירות הלקוחות
          </a>
        </>
      ),
    }),
    emailSent: (email, isMailResent) => ({
      greetings: isMailResent ? null : 'ברכות, אתם זכאים להטבה מיוחדת!',
      emailSent: (
        <>
          {`שלחנו לכם מייל אימות${isMailResent ? ' נוסף' : ''} לכתובת הדוא״ל:`}
          <br />
          <span className="underlined underlined--thin">{email}</span>
        </>
      ),
      seeMail: 'המשיכו את התהליך שם.',
      mailNotReceived: 'המייל לא הגיע? ',
      sendAgain: 'שלחו לי שוב',
      or: ' או ',
      contactUs: 'צרו קשר עם שירות הלקוחות',
      contactHref: getEntitlementContactHref('haaretz.co.il', email),
    }),
    notEntitled: (promotionsUrl, email) => ({
      firstParagraph: 'אנחנו מצטערים, לצערנו אתם לא זכאים להטבה',
      secondParagraph: (
        <>
          אל תהיו עצובים,
          {' '}
          <a href={getEntitlementContactHref('haaretz.co.il', email)}>
            פנו לשירות הלקוחות
          </a>
          {' או '}
          <a href={promotionsUrl}>
            רכשו מינוי
          </a>
        </>
      ),
      purchaseButton: 'לרכישת מינוי',
      contact: 'צרו קשר',
      contactHref: getEntitlementContactHref('haaretz.co.il', email),
    }),
    notEntitledPrint: email => ({
      firstParagraph: 'אנחנו מתנצלים, אך לא אותרה הטבה במערכת עבורכם',
      secondParagraph: (
        <>
          אנא
          {' '}
          <a
            className="underlined"
            href={getEntitlementContactHref('haaretz.co.il', email)}
          >
            צרו קשר עם שרות הלקוחות
          </a>
        </>
      ),
      contact: 'צרו קשר',
      contactHref: getEntitlementContactHref('haaretz.co.il', email),
    }),
  }),
  tm: Object.freeze({
    emailInputStage: () => ({
      firstParagraph: 'איזה כיף, יכול להיות שמגיעה לכם הטבה!',
      secondParagraph: 'הזינו כתובת דוא"ל כדי לבדוק את זה',
    }),
    errorStage: (errorCount, email) => ({
      firstParagraph: 'אופס, לא הצלחנו לגלות האם אתם זכאים להטבה',
      secondParagraph: (
        <>
          אנא נסו שוב או
          {' '}
          <a href={getEntitlementContactHref('themarker.com', email)}>
            צרו קשר עם שירות הלקוחות
          </a>
        </>
      ),
    }),
    emailSent: (email, isMailResent) => ({
      greetings: isMailResent ? null : 'ברכות, אתם זכאים להטבה מיוחדת!',
      emailSent: (
        <>
          {`שלחנו לכם מייל אימות${isMailResent ? ' נוסף' : ''} לכתובת הדוא״ל:`}
          <br />
          <span className="underlined underlined--thin">{email}</span>
        </>
      ),
      seeMail: 'המשיכו את התהליך שם.',
      mailNotReceived: 'המייל לא הגיע? ',
      sendAgain: 'שלחו לי שוב',
      or: ' או ',
      contactUs: 'צרו קשר עם שירות הלקוחות',
      contactHref: getEntitlementContactHref('themarker.com', email),
    }),
    notEntitled: (promotionsUrl, email) => ({
      firstParagraph: 'אנחנו מצטערים, לצערנו אתם לא זכאים להטבה',
      secondParagraph: (
        <>
          אל תהיו עצובים,
          {' '}
          <a href={getEntitlementContactHref('themarker.com', email)}>
            פנו לשירות הלקוחות
          </a>
          {' או '}
          <a href={promotionsUrl}>
            רכשו מינוי
          </a>
        </>
      ),
      purchaseButton: 'לרכישת מינוי',
      contact: 'צרו קשר',
      contactHref: getEntitlementContactHref('themarker.com', email),
    }),
    notEntitledPrint: email => ({
      firstParagraph: 'אנחנו מתנצלים, אך לא אותרה הטבה במערכת עבורכם',
      secondParagraph: (
        <>
          אנא
          {' '}
          <a
            className="underlined"
            href={getEntitlementContactHref('themarker.com', email)}
          >
            צרו קשר עם שרות הלקוחות
          </a>
        </>
      ),
      contact: 'צרו קשר',
      contactHref: getEntitlementContactHref('themarker.com', email),
    }),
  }),
  hdc: Object.freeze({
    emailInputStage: () => ({
      firstParagraph: null,
      secondParagraph: null,
    }),
    errorStage: (errorCount, email) => ({
      firstParagraph: null,
      secondParagraph: null,
    }),
    emailSent: (email, isMailResent) => ({
      greetings: null,
      emailSent: null,
      seeMail: null,
      mailNotReceived: null,
      sendAgain: null,
      or: null,
      contactUs: null,
      contactHref: null,
    }),
    notEntitled: (promotionsUrl, email) => ({
      firstParagraph: null,
      secondParagraph: null,
      purchaseButton: null,
      contact: null,
      contactHref: null,
    }),
  }),
});

export const downloadApplicationPopupI18n = Object.freeze({
  htz: Object.freeze({
    title: Object.freeze({
      text: 'הכי טוב לקרוא אותנו',
      subText: 'באפליקציה',
    }),
    advantages: [
      'עקבו אחר הכותבים המועדפים עליכם',
      'קבלו פושים רק על מה שחשוב ומעניין',
      'האזינו לפודקאסט יומי',
      'גלשו במסך כהה במצב לילה',
    ],
    downloadButton: Object.freeze({
      text: 'קחו אותי לאפליקציה',
      link: Object.freeze({
        android: 'https://play.google.com/store/apps/details?id=com.haaretz&hl=en_US&gl=US',
        ios: 'https://apps.apple.com/il/app/haaretz-%D7%94%D7%90%D7%A8%D7%A5/id521559643',
      }),
    }),
    closeButtonText: 'קרא בדפדפן',
  }),
  tm: Object.freeze({
    title: Object.freeze({
      text: 'הכי טוב לקרוא אותנו',
      subText: 'באפליקציה',
    }),
    advantages: [
      'עקבו אחר הכותבים המועדפים עליכם',
      'קבלו פושים רק על מה שחשוב ומעניין',
      'האזינו לפודקאסט יומי',
      'גלשו במסך כהה במצב לילה',
    ],
    downloadButton: Object.freeze({
      text: 'קחו אותי לאפליקציה',
      link: Object.freeze({
        android: 'https://play.google.com/store/apps/details?id=com.themarker&hl=iw&gl=US',
        ios: 'https://apps.apple.com/il/app/themarker-%D7%93%D7%94%D7%9E%D7%A8%D7%A7%D7%A8/id360938308',
      }),
    }),
    closeButtonText: 'קרא בדפדפן',
  }),
});

export const abuseI18n = Object.freeze({
  htz: Object.freeze({
    textTop: email => [
      'נמצאה פעילות חריגה עם שם המשתמש שלך באתר. גישתך לאתר הארץ נחסמה וסיסמתך לאתר אופסה.',
      `להחלפת הסיסמה, תשלח הודעה לכתובת ${email} באמצעותה ניתן יהיה להגדיר סיסמה חדשה.`,
    ],
    button: {
      text: 'שלח מייל להחלפת סיסמה',
    },
    link: {
      text: 'ליצירת משתמש חדש',
    },
    textBottom: [
      'יש לזכור, כי השימוש במינוי הינו אישי, וניתן לגשת לאתר מדי חודש מחמישה מכשירים אלקטרוניים שונים. חריגה ממסגרת זו תגרום לחסימת הגישה לאתר.',
    ],
    forgetPassword: {
      success: 'ההודעה נשלחה.',
      failed: 'אירעה שגיאה, נסו שוב',
    },
  }),
  tm: Object.freeze({
    textTop: email => [
      'נמצאה פעילות חריגה עם שם המשתמש שלך באתר. גישתך לאתר הארץ נחסמה וסיסמתך לאתר אופסה.',
      `להחלפת הסיסמה, תשלח הודעה לכתובת ${email} באמצעותה ניתן יהיה להגדיר סיסמה חדשה.`,
    ],
    button: {
      text: 'שלח מייל להחלפת סיסמה',
    },
    link: {
      text: 'ליצירת משתמש חדש',
    },
    textBottom: [
      'יש לזכור, כי השימוש במינוי הינו אישי, וניתן לגשת לאתר מדי חודש מחמישה מכשירים אלקטרוניים שונים. חריגה ממסגרת זו תגרום לחסימת הגישה לאתר.',
    ],
    forgetPassword: {
      success: 'ההודעה נשלחה.',
      failed: 'אירעה שגיאה, נסו שוב',
    },
  }),
  hdc: Object.freeze({
    textTop: email => [
      'נמצאה פעילות חריגה עם שם המשתמש שלך באתר. גישתך לאתר הארץ נחסמה וסיסמתך לאתר אופסה.',
      `להחלפת הסיסמה, תשלח הודעה לכתובת ${email} באמצעותה ניתן יהיה להגדיר סיסמה חדשה.`,
    ],
    button: {
      text: 'שלח מייל להחלפת סיסמה',
    },
    link: {
      text: 'ליצירת משתמש חדש',
    },
    textBottom: [
      'יש לזכור, כי השימוש במינוי הינו אישי, וניתן לגשת לאתר מדי חודש מחמישה מכשירים אלקטרוניים שונים. חריגה ממסגרת זו תגרום לחסימת הגישה לאתר.',
    ],
    forgetPassword: {
      success: 'ההודעה נשלחה.',
      failed: 'אירעה שגיאה, נסו שוב',
    },
  }),
});

export const resetPasswordModalI18n = Object.freeze({
  htz: Object.freeze({
    email: Object.freeze({
      form: Object.freeze({
        email: Object.freeze({
          label: 'דוא"ל',
          requiredLong: 'שדה חובה',
          requiredShort: '*',
          noteText: 'אנא הזינו כתובת דוא”ל',
          buttonText: 'המשך',
          generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
          error: 'אנא הזינו כתובת דוא”ל',
          invalidError: 'אנא הזינו כתובת דוא”ל תקינה',
        }),
        result: Object.freeze({
          success: 'נשלח אלייך מייל לאימות',
          failed: 'נשלח אלייך מייל לאימות',
        }),
      }),
    }),
    password: Object.freeze({
      resetPassword: Object.freeze({
        button: {
          text: 'שכחתי סיסמה',
        },
        header: 'החלפת סיסמה',
        resetPasswordButton: 'המשך',
        successMessage: 'הוראות לאיפוס הסיסמה נשלחו לתיבת הדוא"ל שלך',
        successButtonText: 'סגור',
        result: {
          success: 'מייל לעדכון סיסמה נשלח לכתובתך',
          failed: 'אירעה שגיאה, נסו שוב',
        },
      }),
    }),
  }),
  tm: Object.freeze({
    email: Object.freeze({
      form: Object.freeze({
        email: Object.freeze({
          label: 'דוא"ל',
          requiredLong: 'שדה חובה',
          requiredShort: '*',
          noteText: 'אנא הזינו כתובת דוא”ל',
          buttonText: 'המשך',
          generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
          error: 'אנא הזינו כתובת דוא”ל',
          invalidError: 'אנא הזינו כתובת דוא”ל תקינה',
        }),
        result: Object.freeze({
          success: 'נשלח אלייך מייל לאימות',
          failed: 'נשלח אלייך מייל לאימות',
        }),
      }),
    }),
    password: Object.freeze({
      resetPassword: Object.freeze({
        button: {
          text: 'שכחתי סיסמה',
        },
        header: 'החלפת סיסמה',
        resetPasswordButton: 'המשך',
        successMessage: 'הוראות לאיפוס הסיסמה נשלחו לתיבת הדוא"ל שלך',
        successButtonText: 'סגור',
        result: {
          success: 'מייל לעדכון סיסמה נשלח לכתובתך',
          failed: 'אירעה שגיאה, נסו שוב',
        },
      }),
    }),
  }),
  hdc: Object.freeze({
    email: Object.freeze({
      form: Object.freeze({
        email: Object.freeze({
          label: 'דוא"ל',
          requiredLong: 'שדה חובה',
          requiredShort: '*',
          noteText: 'אנא הזינו כתובת דוא”ל',
          buttonText: 'המשך',
          generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
          error: 'אנא הזינו כתובת דוא”ל',
          invalidError: 'אנא הזינו כתובת דוא”ל תקינה',
        }),
        result: Object.freeze({
          success: 'נשלח אלייך מייל לאימות',
          failed: 'נשלח אלייך מייל לאימות',
        }),
      }),
    }),
    password: Object.freeze({
      resetPassword: Object.freeze({
        button: {
          text: 'שכחתי סיסמה',
        },
        header: 'החלפת סיסמה',
        resetPasswordButton: 'המשך',
        successMessage: 'הוראות לאיפוס הסיסמה נשלחו לתיבת הדוא"ל שלך',
        successButtonText: 'סגור',
        result: {
          success: 'מייל לעדכון סיסמה נשלח לכתובתך',
          failed: 'אירעה שגיאה, נסו שוב',
        },
      }),
    }),
  }),
});

export const mailVerification = Object.freeze({
  htz: Object.freeze({
    success: Object.freeze({
      title: 'האימות הצליח!',
      text: 'כעת ניתן לחזור לאפליקציה ולהמשיך בהתחברות',
    }),
    failed: Object.freeze({
      title: 'שגיאת תקשורת',
      text: 'נסו שוב מאוחר יותר',
    }),
  }),
  tm: Object.freeze({
    success: Object.freeze({
      title: 'האימות הצליח!',
      text: 'כעת ניתן לחזור לאפליקציה ולהמשיך בהתחברות',
    }),
    failed: Object.freeze({
      title: 'שגיאת תקשורת',
      text: 'נסו שוב מאוחר יותר',
    }),
  }),
  hdc: Object.freeze({
    success: Object.freeze({
      title: 'Validation Succeeded!',
      text: 'Now you can open the app again and login',
    }),
    failed: Object.freeze({
      title: 'There was a communication error',
      text: 'Please try again later',
    }),
  }),
});
