// @flow
function correctEmailTypo(domain: string) {
  switch (domain) {
    // Gmail typos
    case 'gamil.com':
    case 'gmil.com':
    case 'gami.com':
    case 'gmai.com':
    case 'gsmil.com':
    case 'gmail.con':
    case 'gmail.cim':
    case 'gmal.com':
    case 'gmial.com':
    case 'gmail.co':
    case 'gmail.cm':
    case 'gmaik.com':
    case 'gmial.con':
    case 'gmaiil.com':
    case 'gmaill.com':
    case 'gemail.com':
      return 'gmail.com';

    // Hotmail typos
    case 'hotmial.com':
    case 'hotmil.com':
    case 'hoitmail.com':
    case 'hotmaill.com':
    case 'hotmai.com':
    case 'hotmal.com':
    case 'hotmil.con':
    case 'hotnail.com':
    case 'hotmali.com':
      return 'hotmail.com';

    // Yahoo typos
    case 'yaho.com':
    case 'yhoo.com':
    case 'yahho.com':
    case 'yaoo.com':
    case 'yahooo.com':
    case 'yahoo.con':
    case 'yahoo.cm':
    case 'yahool.com':
      return 'yahoo.com';

    // Outlook typos
    case 'outllok.com':
    case 'outlok.com':
    case 'outook.com':
    case 'otulook.com':
    case 'outlook.con':
    case 'outlok.cm':
      return 'outlook.com';

    // iCloud typos
    case 'icloud.con':
    case 'iclod.com':
    case 'icoud.com':
    case 'icloid.com':
    case 'ilcoud.com':
      return 'icloud.com';

    // AOL typos
    case 'ail.com':
    case 'aol.con':
    case 'aol.co':
    case 'aoll.com':
      return 'aol.com';

    // Comcast typos
    case 'comast.net':
    case 'comcost.net':
    case 'comcas.net':
    case 'comacst.net':
    case 'comcast.con':
      return 'comcast.net';

    // Haaretz typos
    case 'haaretz.coil':
    case 'haertz.co.il':
    case 'haarets.co.il':
    case 'haretz.co.il':
      return 'haaretz.co.il';

    // TheMarker typos
    case 'themarker.con':
    case 'themarkr.com':
    case 'thmarker.com':
    case 'themarke.com':
    case 'themarer.com':
    case 'themarker.cm':
      return 'themarker.com';

    default:
      return domain;
  }
}

export default correctEmailTypo;
