// @flow
import React, { Fragment, } from 'react';
import Head from 'next/head';
import { useFela, } from 'react-fela';
import { IconHaaretzLogo, IconMarkerLogo, IconHaaretzComLogoFull, useMedia, } from '@haaretz/htz-components';

type HeaderType = 'login' | 'register' | 'resetPassword' | 'entitlementCheck';
type Site = 'hdc' | 'tm' | 'htz';

type Props = {
  site: Site,
  headerVariant: HeaderType | (Site => HeaderType),
  hide?: boolean,
}

const logoMap = {
  tm: IconMarkerLogo,
  htz: IconHaaretzLogo,
  hdc: IconHaaretzComLogoFull,
};

HeaderBySite.defaultProps = {
  headerVariant: 'login',
  hide: false,
};

function HeaderBySite({ site, headerVariant, hide, }: Props) {
  const { css, theme, } = useFela();
  const isMobile = useMedia({ query: { until: 's', }, matchOnServer: true, });

  const HeaderLogo = logoMap[site];

  const variant = typeof headerVariant === 'function'
    ? headerVariant(site)
    : headerVariant;

  const headerText = theme.headerI18n[variant] || theme.headerI18n.login;

  return (
    <Fragment>
      <Head>
        <title>{site === 'hdc' ? 'Log In' : 'התחברות'}</title>
      </Head>

      {hide ? null : (
        <div
          className={css({
            marginBottom: '25px',
            paddingTop: '2rem',
            color: theme.color('white'),
            textAlign: 'center',
            backgroundColor: theme.color('header', 'bg'),
            fontFamily: theme.fontStacks.webfonts,
            fontWeight: 300,
          })}
        >
          <HeaderLogo size={isMobile ? 2 : 1.75} />
          <h2
            className={css({
              marginTop: '1rem',
              marginBottom: '1rem',
              fontWeight: '300',
              extend: [ theme.type(4), ],
            })}
          >
            {headerText}
          </h2>
          <div className={css({ position: 'relative', })}>
            <div className={css({ width: '100%', marginTop: '-20px', transform: 'translateY(50%)', })}>
              <div
                className={css({
                  width: '50px',
                  height: '50px',
                  margin: '0 auto',
                  background: theme.color('white'),
                  padding: '9px',
                  borderRadius: '27px',
                  border: `1px solid ${theme.color('header', 'icon')}`,
                })}
              >
                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 256 256">
                  <path
                    fill={theme.color('header', 'icon')}
                    d="M69.7 69c0-33.2 26.9-60.2 60.2-60.2S190 35.8 190 69c0 33.2-26.9 60.2-60.2 60.2S69.7 102.2 69.7 69zM142.8 155.6h-25.9c-52.6 0-96 41.1-99.9 92.7h225.8C238.8 196.7 195.4 155.6 142.8 155.6z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default HeaderBySite;
