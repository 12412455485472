// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

export default function PageWrapper({ children, }: { children: React.Node, }) {
  const { css, } = useFela();
  return (
    <div className={css({ flex: '1 0 auto', display: 'flex', })}>
      {children}
    </div>
  );
}
