/* global window document */
import React, { Fragment, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { Form, TextInput, Button, useFocusFirstEmptyInput, } from '@haaretz/htz-components';
import { UserTransformations, } from '@haaretz/htz-user-utils';
import isEmail from 'validator/lib/isEmail';
import Router, { useRouter, } from 'next/router';
import objTransform from '../../../util/objectTransformationUtil';
import { storeFlowNumber, } from '../../FlowDispenser/flowStorage';
import {
  saveUserData,
  getDataFromUserInfo,
  saveUserEmail,
  validateMailWithPhone,
  validateMailConfirmation,
  sendMailConfirmation,
  saveIsEnterWithSms,
  saveEntitlementData,
  getUserProducts,
  getType,
  getUserType,
  isEntitlement,
} from '../../../queryutil/userDetailsOperations';
import { writeMetaDataToApollo, parseRouteInfo, } from '../../../queryutil/flowUtil';
import {
  LoginContentStyles,
  LoginMiscLayoutStyles,
} from '../../StyleComponents/LoginStyleComponents';
import entitlementStatuses from '../../Entitlement/consts/entitlementStatuses';
import { LoginMiscLayoutStylesThemed, } from '../../StyleComponents/LoginStyleComponentsByTheme';
import { getFacebookLoginUrl, getFacebookParams, getFacebookPixel, getFacebookPixelFetch, loadFacebookPixelImage, getPostPixelRedirection, isFacebookLogin, } from '../../../util/facebookLoginUtil';
import { sendTrackingEvents, } from '../../../util/trackingEventsUtil';
import { getReferrerUrl, } from '../../../util/referrerUtil';
import { getAndSaveDebtParams, getDebtReferrer, } from '../../../util/debtCheckUtil';
import { getHost, } from '../../../util/requestUtil';
import { getHomepageUrl, extractHostname, getTextByHost, isEngSite, getSiteByHost, } from '../../../util/siteUtil';

// Styling Components -----------------
const { FormWrapper, ItemCenterer, } = LoginContentStyles;
const { ErrorBox, } = LoginMiscLayoutStyles;
const { TopSpacer, } = LoginMiscLayoutStylesThemed(0, { spacing: 12, });
// ------------------------------------

// Methods ----------------------------
const getFacebookLogin = (user, force) => {
  if (user.amp && user.amp.length > 0) {
    return decodeURIComponent(user.amp);
  }
  const facebookParams = getFacebookParams(user);
  if (force) {
    facebookParams.force = true;
  }
  return facebookParams ? getFacebookLoginUrl(facebookParams) : false;
};

const isEligebleForRediect = () => {
  const { utm, } = getUrlParams();
  return !utm.utm_source || utm.utm_source !== 'amp-login';
};

const b64DecodeUnicode = str => (str
  ? decodeURIComponent(
    // eslint-disable-next-line no-undef
    atob(str)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )
  : null);

const getParamsData = params => {
  // TODO add fields to retreive from params
  if (params) {
    const decodedParams = JSON.parse(b64DecodeUnicode(params));
    return {
      email: decodedParams.email,
      phone: decodedParams.phone,
      prefix: decodedParams.prefix,
    };
  }
  return { email: '', phone: '', };
};

const getUrlParams = () => {
  // eslint-disable-next-line no-undef
  const pageUrl = new URL(window.location.href);
  return {
    confirmation: pageUrl.searchParams.get('confirmation')
      ? pageUrl.searchParams
        .get('confirmation')
        .split(' ')
        .join('+')
      : null,
    type: pageUrl.searchParams.get('type'),
    ...getParamsData(pageUrl.searchParams.get('params')),
    facebook: {
      token: pageUrl.searchParams.get('account_linking_token'),
      redirect: pageUrl.searchParams.get('redirect_uri'),
      utm: pageUrl.searchParams.get('utm_source'),
      amp: pageUrl.searchParams.get('returnTo'),
      __typename: 'facebookLogin',
    },
    utm: {
      utm_source: pageUrl.searchParams.get('utm_source'),
      utm_campign: pageUrl.searchParams.get('utm_campign'),
    },
    returnTo: pageUrl.searchParams.get('returnTo') || undefined,
  };
};

const getProducts = client => getUserProducts(client)();

const checkIfLoggedin = ({
  client,
  userDispenser: { user, ...rest },
  host,
  logout,
}) => {
  const isLoggedIn = user && user.anonymousId && rest.isLoggedIn;
  if (isLoggedIn && isEntitlement(client)) {
    // User will proceed to login to complete the entitlement flow.
    // Logging out first to avoid any conflicts
    (logout || (() => {}))();
  }
  else if (isLoggedIn) {
    const pageURL = new URL(window.location.href);
    const { facebook, returnTo, } = getUrlParams();
    const facebookUser = {
      amp: facebook.amp,
      utm: facebook.utm,
      subscription: user.type,
      ssoId: user.id,
    };

    if (getAndSaveDebtParams(client)) {
      getProducts(client, user.id, user.token).then(
        res => {
          window.location.href = getDebtReferrer(res, host) || getHomepageUrl(window.location);
        },
        res => {
          window.location.href = getHomepageUrl(window.location);
        }
      );
    }
    else {
      // eslint-disable-next-line no-undef
      // eslint-disable-next-line no-lonely-if
      if (isFacebookLogin(user, pageURL, client)) {
        getFacebookPixelFetch(user, pageURL, client).then(data => {
          if (data) {
            loadFacebookPixelImage(data, () => {
              window.location = pageURL.host.includes('themarker')
                ? 'https://www.themarker.com/' : pageURL.host.includes('haaretz.com')
                  ? 'https://www.haaretz.com/' : 'https://www.haaretz.co.il/';
            });
          }
          else {
            window.location = user && user.facebook && user.facebook.redirect ? user.facebook.redirect : document.referrer;
          }
        });
      }
      else if (isEligebleForRediect()) {
        window.location = (getFacebookLogin(facebookUser) || (getReferrerUrl(client, returnTo) || getHomepageUrl(window.location))) || false;
      }
    }
  }
};

const generateEmailError = message => [ { name: 'email', order: 1, errorText: message, }, ];


const hasValidatedEmail = dataSaved => dataSaved
  && dataSaved.userData
  && dataSaved.userData.userStatus
  && dataSaved.userData.userStatus.isMailValidated;

const setFacebookParamsOnApollo = client => {
  const { facebook, } = getUrlParams();
  if ((facebook && facebook.token && facebook.redirect) || (facebook && facebook.utm)) {
    saveUserData(client)({ userData: { facebook, __typename: 'SsoUser', }, });
  }
};

const handleResponseFromGraphql = ({
  client,
  getFlowByData,
  email,
  phone,
  res,
  showError,
  setPreloader,
  eventsTrackers,
}) => {
  getAndSaveDebtParams(client);
  const dataSaved = saveUserData(client)({ userData: res.user, });
  const transformedObj = objTransform(res);
  const flow = getFlowByData(transformedObj.user);
  storeFlowNumber(client)(flow.flowNumber);
  const eventsHandler = sendTrackingEvents(eventsTrackers, {
    page: 'Main Login',
    flowNumber: flow.flowNumber,
    label: 'proceedEmail',
    email,
  });
  const { route, metadata, } = parseRouteInfo(flow.initialTransition);
  writeMetaDataToApollo(client, metadata);

  setFacebookParamsOnApollo(client);

  const mobilePhone = phone || (dataSaved && dataSaved.userData ? dataSaved.userData.mobilePhone : null);
  const firstName = dataSaved && dataSaved.userData ? dataSaved.userData.firstName : null;
  const ssoId = res && res.userByMail ? res.userByMail.ssoId : null;

  saveUserData(client)({
    userData: { phone: mobilePhone, ssoId, __typename: 'SsoUser', },
  });
  const host = extractHostname(getHost(client));

  if (dataSaved && dataSaved.userData && !hasValidatedEmail(dataSaved) && !getUserType(dataSaved.userData)) {
    const validationType = host.includes('themarker.com')
      ? 'THEMARKER_MAIL_VALIDATION' : host.includes('haaretz.com')
        ? 'ENG_MAIL_VALIDATION' : 'HTZ_MAIL_VALIDATION';
    const prefix = /(https?:\/\/.*).(haaretz.co.il|themarker.com|haaretz.com).*/.exec(
      window.location.origin
    )[1];

    sendMailConfirmation(client)({
      email,
      url: `${prefix}.${host}`,
      paramString: JSON.stringify({ email, }),
      confirmationType: validationType,
    }).then(
      () => {
        eventsHandler();
        Router.push({
          pathname: route,
          query: {
            ...Router.query,
          },
        });
      },
      error => {
        showError(error.message || getTextByHost({
          host,
          he: 'אירעה שגיאה',
          en: 'An error has occurred',
        }));
      }
    );
  }
  else {
    eventsHandler();
    Router.push({
      pathname: route,
      query: {
        ...Router.query,
      },
    });
  }
};

// ------------------------------------
IndexForm.propTypes = {
  client: PropTypes.shape({}).isRequired,
  getFlowByData: PropTypes.func.isRequired,
  gaAction: PropTypes.func,
  biAction: PropTypes.func,
};

IndexForm.defaultProps = {
  client: null,
  getFlowByData: null,
  gaAction: null,
  biAction: null,
};

const LOADING_TYPES = {
  form: 'form',
  page: 'page',
  default: 'form',
  none: null,
};

function IndexForm(props) {
  const { theme, } = useFela();
  const router = useRouter();
  const [ showError, setShowError, ] = React.useState(false);
  const [ errorMessage, setErrorMessage, ] = React.useState('');
  const [ loadingType, setLoadingType, ] = React.useState(LOADING_TYPES.none);
  const host = extractHostname(getHost(props.client));
  const site = getSiteByHost(host);

  const onSubmit = (
    client,
    getFlowByData,
    createError,
    hideError,
    setPreloader,
    eventsTrackers,
    confirmation
  ) => ({ email, phone, }) => {
    const emailToSave = (email || '').toLowerCase();
    hideError();
    setPreloader(true);
    saveUserEmail(client)(emailToSave);
    // mockDataFromUserInfo(client)(email)
    return getDataFromUserInfo(client)(emailToSave, site)
      .then(res => {
        handleResponseFromGraphql({
          email: emailToSave,
          client,
          getFlowByData,
          phone,
          res,
          showError,
          setPreloader,
          eventsTrackers,
          confirmation,
        });
      })
      .catch(err => {
        setPreloader(false);
        createError(theme.emailInputTexts.generalError);

        throw err;
      });
  };

  const validateEmailInput = ({ email, }) => (!email
    ? generateEmailError(theme.emailInputTexts.noEmailError)
    : !isEmail(email)
      ? generateEmailError(theme.emailInputTexts.notValidEmailError)
      : []); // email is valid

  const setPreloader = (isLoadingStatus, isEntirePage = false) => {
    const nextLoadingType = isLoadingStatus
      ? isEntirePage ? LOADING_TYPES.page : LOADING_TYPES.default
      : null;
    setLoadingType(nextLoadingType);
  };

  const hideError = () => {
    setShowError(false);
    setErrorMessage('');
  };

  const showNewError = errorMsg => {
    setShowError(true);
    setErrorMessage(errorMsg);
  };

  const setReferrer = client => {
    const referrerUrl = typeof window.Cypress !== 'undefined' ? '' : document.referrer;
    saveUserData(client)({ loginReferrer: referrerUrl, });
  };

  /**
   * the autoSubmit method runs when the user returns to the login page from a confirmation email
   */
  const autoSubmit = ({ client, getFlowByData, }) => {
    const { email: entitlementEmail, } = router.query;
    const {
      confirmation,
      email,
      phone,
      facebook,
      type,
      amp,
      prefix: phonePrefix,
    } = getUrlParams();
    const eventsTrackers = { gaAction: props.gaAction, biAction: props.biAction, };
    const { prefix, suffix, } = UserTransformations.mobileNumberParser(phone, phonePrefix);
    if (isEntitlement(client)) {
      onSubmit(
        client,
        getFlowByData,
        showNewError,
        hideError,
        isLoading => setPreloader(isLoading, true),
        eventsTrackers
      )({
        email: email || entitlementEmail,
      });
    }
    else if (confirmation && type === 'phoneEmailConnect') {
      saveIsEnterWithSms(client)(true);
      validateMailWithPhone(client)({
        email,
        confirmation,
        mobilePrefix: prefix,
        mobileNum: suffix,
      }).then(
        data => onSubmit(
          client,
          getFlowByData,
          showError,
          hideError,
          setPreloader,
          eventsTrackers,
          theme
        )({
          email,
          phone,
        }).then(() => {
          if ('appValidation' in router.query) {
            const status = data?.data?.validateMobileEmailConnection?.success ? 'success' : 'failed';

            router.push(
              {
                pathname: '/phoneEmailConnectApp',
                query: {
                  appValidation: status,
                },
              },
              `/phoneEmailConnectApp?appValidation=${status}`,);
          }
        }),
        fail => {
          if ('appValidation' in router.query) {
            router.push(
              {
                pathname: '/phoneEmailConnectApp?phoneEmailConnectApp=failed',
                query: {
                  appValidation: 'failed',
                },
              },
              `/phoneEmailConnectApp?appValidation=${router.query.appValidation}`,
            );
          }
          else {
            showNewError(fail.message);
          }
        }
      );
    }
    else if (confirmation && type === 'mailValidation') {
      validateMailConfirmation(client)({ email, confirmation, }).then(
        () => onSubmit(client, getFlowByData, showNewError, hideError, setPreloader, eventsTrackers)({
          email,
          phone,
        }),
        fail => showNewError(fail.message)
      );
    }
    else if (type === 'reevaluate') {
      onSubmit(client, getFlowByData, showNewError, hideError, setPreloader, eventsTrackers)({
        email,
        phone,
      });
    }
    if (facebook && facebook.token && facebook.redirect) {
      saveUserData(client)({ userData: { facebook, __typename: 'SsoUser', }, });
    }
    else {
      const facebookEmpty = { token: null, redirect: null, __typename: 'facebookLogin', };
      saveUserData(client)({ userData: { facebook: facebookEmpty, __typename: 'SsoUser', }, });
    }
  };

  React.useEffect(() => {
    setReferrer(props.client);
    const entitlementStatus = router.query['entitlement-partial'] === 'true'
      ? entitlementStatuses.PARTIAL_ENTITLED
      : router.query.entitlement === 'true'
        ? entitlementStatuses.FULL_ENTITLED
        : null;
    // early save to avoid redirection in checkedIfLoggedIn
    saveEntitlementData(props.client)(
      entitlementStatus,
      entitlementStatus && router.query.subsNo ? router.query.subsNo : null
    );
    autoSubmit(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    checkIfLoggedin({ ...props, host, });
  // eslint-disable-next-line react-hooks/exhaustive-deps, react/prop-types
  }, [ props?.userDispenser?.user?.anonymousId, ]);

  const { client, getFlowByData, gaAction, biAction, } = props;
  const eventsTrackers = { gaAction, biAction, };
  const getRefFunc = useFocusFirstEmptyInput();
  return (
    <FormWrapper site={getSiteByHost(host)} isLoading={loadingType === LOADING_TYPES.page}>
      <TopSpacer />
      <ItemCenterer>
        <h5>{theme.indexFormI18n.title}</h5>
      </ItemCenterer>
      <Form
        useNativeOnSubmit
        clearFormAfterSubmit={false}
        validate={data => validateEmailInput({ ...data, theme, })}
        onSubmit={onSubmit(
          client,
          getFlowByData,
          showNewError,
          hideError,
          setPreloader,
          eventsTrackers
        )}
        render={({ getInputProps, handleSubmit, clearForm, }) => (
          <Fragment>
            <TextInput
              type="email"
              {...getInputProps({
                label: theme.emailInputTexts.emailInputLabel,
                noteText: theme.emailInputTexts.noteText,
                requiredText: {
                  long: theme.emailInputTexts.emailInputRequiredLong,
                  short: theme.emailInputTexts.emailInputRequiredShort,
                },
                name: 'email',
                type: 'email',
                attrs: {
                  name: 'userName',
                },
                refFunc: getRefFunc('email'),
              })}
            />

            <ErrorBox className={showError ? '' : 'hidden'}>
              <span>{errorMessage}</span>
            </ErrorBox>

            <ItemCenterer>
              <Button
                isSubmit
                isBusy={loadingType !== LOADING_TYPES.none}
                miscStyles={{
                  ...(isEngSite(host) ? theme.type(-1) : {}),
                }}
              >
                {theme.emailInputTexts.buttonText}
              </Button>
            </ItemCenterer>
          </Fragment>
        )}
      />
    </FormWrapper>
  );
}

export default IndexForm;
