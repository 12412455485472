/* global fetch */
/* ----------------------------------- */
/* LOGIN WITH FACEBOOK INSTANT ARTICLE */
/* ----------------------------------- */
import gql from 'graphql-tag';
import config from 'config';
import { getType, } from '../queryutil/userDetailsOperations';

const fbInstantPaywallUrl = config.get('service.fbInstantPaywall');

/** Config --------------------------------------------- */

const FACEBOOK_LOGIN_PATH = `${fbInstantPaywallUrl}/subscribe/facebook-redirect`;
const LOGIN_SERVICE_URL = `${fbInstantPaywallUrl}/subscribe/facebook-instant-pixel-signature-login`;

const GET_FB_URL = gql`
  query getFbLogInPixelSignature(
    $subscription_id: String!
    $is_subscriber: Boolean!
    $expiry_time: DateTime
    $site: Site!
  ) {
    getFbLogInPixelSignature(
      subscription_id: $subscription_id
      is_subscriber: $is_subscriber
      expiry_time: $expiry_time
      site: $site
    )
  }
`;

const GET_FB_PAYLOAD = gql`
  query FB_PAYLOAD(
    $account_linking_token: String!
    $subscription_status: Int!
    $publisher_user_id: ID
    $facebook_user_id: ID
    $subscribe_from_server: Boolean
    $site: Site!
  ) {
    fbSubscribePayload(
      account_linking_token: $account_linking_token
      subscription_status: $subscription_status
      publisher_user_id: $publisher_user_id
      facebook_user_id: $facebook_user_id
      subscribe_from_server: $subscribe_from_server
      site: $site
    )
  }
`;

/** Misc ----------------------------------------------- */

/**
 * check if the user has an actve TM / HTZ product
 */
const getUserType = userCrmStatus => {
  if (userCrmStatus != null) {
    return userCrmStatus.isActiveTm || userCrmStatus.isActiveHeb ? 'paying' : 'registered';
  }
  return 'anonymous';
};

/**
 * builds and returns the full url for facebook login
 */
const buildRedirectUrl = ({ token, subscription, userid, redirect, }) => `${FACEBOOK_LOGIN_PATH}?account_linking_token=${token}&subscription_status=${subscription}&publisher_user_id=${userid}&redirect_uri=${redirect}`;

/** Exported ------------------------------------------- */

/**
 * returns the facebook's login url for redirection (when reaching login via instant article)
 */
const getFacebookLoginUrl = params => {
  if (params && ((params.token && params.subscription && params.userid && params.redirect) || params.force)) {
    return buildRedirectUrl(params);
  }
  console.warn('could not redirect to facebook login - missing params');
  return false;
};

/**
 * returns the required params for getFacebookLoginUrl
 */
const getFacebookParams = user => {
  if (user && user.facebook) {
    return {
      token: user.facebook.token,
      redirect: user.facebook.redirect,
      subscription: getUserType(user.userCrmStatus),
      userid: user.ssoId,
      amp: user.facebook.amp,
    };
  }
  console.warn('could not login with facebook - missing user');
  return false;
};

const isFacebookLogin = (user, url, client) => {
  const data = getFbPixelRequiredData(user, url, client);
  return (data && data.utmSrc === 'facebook') || data.redirect;
};

/**
 * builds a JSON that contains the required data for creating an account linking pixel
 */
const getFbPixelRequiredData = (user, url, client) => {
  const src = (user && user.facebook && user.facebook.utm) ? user.facebook.utm : url ? url.searchParams.get('utm_source') : false;
  const redirectUri = (user && user.facebook && user.facebook.redirect) ? user.facebook.redirect : null;
  return {
    id: user.ssoId || user.id,
    type: getType(client),
    utmSrc: src,
    redirect: redirectUri,
  };
};

/**
 * uses facebook's api to retrieve an account linking pixel's url
 * this url will be used a an img tag source
 */
const getFacebookPixel = (user, pageURL, client) => {
  const facebookUserData = getFbPixelRequiredData(user, pageURL, client);
  const site = pageURL.host.includes('themarker') ? 'TM' : pageURL.host.includes('haaretz.com') ? 'HDC' : 'HTZ';

  if (facebookUserData && (facebookUserData.utmSrc === 'facebook' || facebookUserData.redirect)) {
    const facebookPixelUrl = async client => {
      const { data, loading, error, } = await client.query({
        query: GET_FB_URL,
        variables: {
          subscription_id: facebookUserData.id,
          is_subscriber: facebookUserData.type === 'paying',
          expiry_time: -1,
          site,
        },
      });
      return data.getFbLogInPixelSignature || '';
    };
    return facebookPixelUrl(client);
  }
  return Promise.resolve(false);
};

const getFacebookPixelFetch = (user, pageURL, client) => {
  const facebookUserData = getFbPixelRequiredData(user, pageURL, client);
  const site = pageURL.host.includes('themarker') ? 'TM' : pageURL.host.includes('haaretz.com') ? 'HDC' : 'HTZ';
  // const site = 'HTZ';
  let promise = null;

  if (facebookUserData && (facebookUserData.utmSrc === 'facebook' || facebookUserData.redirect)) {
    const facebookPixelUrl = async () => {
      promise = await fetch(`${LOGIN_SERVICE_URL
      }?subscription_id=${facebookUserData.id
      }&is_subscriber=${facebookUserData.type === 'paying'
      }&expiry_time=-1`
        + `&site=${site}`)
        .then(response => response.json());

      return promise.url;
    };
    return facebookPixelUrl();
  }
  return Promise.resolve(false);
};

/**
 * uses facebook's api to retrieve an account linking pixel's url
 * this url will be used a an img tag source
 */
const getFacebookPixelWithPayload = (user, pageURL, client) => {
  const facebookUserData = getFbPixelRequiredData(user, pageURL, client);
  const { token, redirect, } = getFacebookParams(user);
  const site = pageURL.host.includes('themarker') ? 'TM' : pageURL.host.includes('haaretz.com') ? 'HDC' : 'HTZ';

  if (facebookUserData && (facebookUserData.utmSrc === 'facebook' || facebookUserData.redirect)) {
    const facebookPixelUrl = async client => {
      const { data, loading, error, } = await client.query({
        query: GET_FB_PAYLOAD,
        variables: {
          account_linking_token: token,
          subscription_status: facebookUserData.type === 'paying' ? 1 : 0,
          subscription_id: facebookUserData.id,
          subscribe_from_server: facebookUserData.type === 'paying',
          site,
        },
      });
      console.log(`fbpixel-data: ${data}`);
      return data || 'failed';
    };
    return facebookPixelUrl(client);
  }
  return Promise.resolve(false);
};

/**
 * redirect the user after injecting the pixel
 * paying users will be redirected the the uri received from facebook
 * non paying users will be redirected to promotion page
 */
const getPostPixelRedirection = (user, data, type, client) => {
  type = type || getType(client);
  const { token, redirect, } = getFacebookParams(user);

  if (type === 'paying') {
    const decodedRedirect = redirect ? decodeURIComponent(redirect) : '';
    if (decodedRedirect && token) {
      if (data && data.fbSubscribePayload) {
        return `${decodedRedirect}?account_linking_token=${token}&subscription_payload=${encodeURIComponent(data.fbSubscribePayload)}`;
      }
      const params = `${decodedRedirect.includes('?') ? '&' : '?'}account_linking_token=${token}`;
      return decodedRedirect + params;
    }
    const referrer = decodedRedirect || document.referrer;
    return referrer.includes('facebook') ? referrer : 'https://www.facebook.com/';
  }

  // if not paying - pass query params received from facebook, to continue the account linking flow on promotions page
  return `https://promotions.haaretz.co.il/promotions-page/product?account_linking_token=${token}&redirect_uri=${redirect}`;
};

/**
 * creates a new image, then sets its source attribute
 * this will trigger the image's onload event
 */
const loadFacebookPixelImage = (data, callback) => {
  const pixelImage = new Image();
  if (typeof callback === 'function') {
    pixelImage.onload = callback;
  }
  pixelImage.src = data;
};

export { getFacebookLoginUrl, getFacebookParams, getFacebookPixel, getFacebookPixelFetch, getFacebookPixelWithPayload, getPostPixelRedirection, loadFacebookPixelImage, isFacebookLogin, };
