// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

export default function PageWrapper({ children, }: { children: React.Node, }) {
  const { css, } = useFela();
  return (
    <div className={css({ display: 'flex', minHeight: '100vh', flexDirection: 'column', })}>
      {children}
    </div>
  );
}
