// Base named colors
/* eslint-disable */
import { baseColors as tmBaseColors } from '@haaretz/tm-theme';
import { baseColors as htzBaseColors } from '@haaretz/htz-theme';
import { baseColors as hdcBaseColors } from '@haaretz/hdc-theme';

const siteColorOverrides = Object.freeze({
  htz: Object.freeze({
    variants: {
      button: {},
      checkBox: {},
      input: {},
    },
    misc: {
      link: {
        base: ['primary', 'base'],
        a11yOnDark: ['primary', '-2'],
        a11yOnLight: ['primary', '+1'],
      },
      header: {
        bg: ['primary', '+1'],
        icon: ['primary', '-1'],
      },
      footer: {
        bg: ['primary', '+1'],
        border: 'white',
        text: 'white',
      },
      tabsForm: {
        border: ['primary', 'base'],
      },
    },
    downloadAplicationPopUp: {
      gradientStart: '#169fd1',
      gradientEnd: '#105676',
    },
  }),
  hdc: Object.freeze({
    variants: {
      button: {},
      checkBox: {},
      input: {
        primaryErrorBorder: ['tertiary', 'base'],
      },
    },
    misc: {
      link: {
        base: ['primary', 'base'],
        a11yOnDark: ['primary', '-2'],
        a11yOnLight: ['primary', '+1'],
      },
      header: {
        bg: ['primary', 'base'],
        icon: ['primary', 'base'],
      },
      footer: {
        bg: ['primary', 'base'],
        border: 'white',
        text: 'white',
      },
      tabsForm: {
        border: ['primary', 'base'],
      },
    },
  }),

  tm: Object.freeze({
    variants: {
      button: {
        primaryText: ['secondary', '+1'],
        primaryActiveText: ['secondary', '+1'],
        primaryFocusBg: ['secondary', '+1'],
        primaryHoverBg: ['primary', '-5'],
        primaryHoverText: ['secondary', '+1'],

        // opaque: {
        //   primaryOpaqueBg: ['secondary', 'base'],
        //   primaryOpaqueHoverBg: ['secondary', '+1'],
        // },
      },
      input: {
        primaryBg: ['neutral', '-7'],
        primaryBorder: ['neutral', '-6'],
        primaryTextLabel: ['secondary', '+1'],
        primaryFocusBorder: ['secondary', 'base'],
        primaryHoverBg: ['primary', '-6'],
        primaryHoverBorder: ['primary', '-4'],
        primaryHoverText: ['bodyText', 'base'],
      },
      checkBox: {
        primaryBgChecked: ['secondary', 'base'],
        primaryBorder: ['secondary', 'base'],
        primaryRipple: ['secondary', 'base'],
      },
    },
    misc: {
      link: {
        base: ['secondary', 'base'],
        a11yOnDark: ['primary', 'base'],
        a11yOnLight: ['link', 'base'],
      },
      header: {
        bg: ['neutral', '-1'],
        icon: ['secondary', '+1'],
      },
      footer: {
        bg: ['neutral', '-1'],
        text: 'white',
        border: 'white',
      },
      tabsForm: {
        border: ['secondary', 'base'],
      },
      icon: {
        base: ['secondary', 'base'],
      }
    },
    downloadAplicationPopUp: {
      gradientStart: '#3bb08b',
      gradientEnd: '#0f480f',
    },
  }),
});

const baseSiteColors = {
  htz: htzBaseColors,
  tm: tmBaseColors,
  hdc: hdcBaseColors,
};
const colors = site => {
  const baseColors = baseSiteColors[site];
  const colorOverrides = siteColorOverrides[site];
  return {
    ...baseColors,
    // semantic use-cases
    accent: {
      base: ['tertiary', 'base'],
    },
    bg: {
      base: ['primary', '-6'],
      '+1': ['primary', '-5'],
    },
    bodyText: {
      base: ['neutral', '-1'],
      inverse: ['neutral', '-6'],
    },
    button: {
      // Primary
      primaryBg: 'white',
      primaryBorder: ['button', 'primaryText'],
      primaryText: ['primary', 'base'],
      primaryActiveBg: '#fff !important',
      primaryActiveBorder: ['button', 'primaryText'],
      primaryActiveText: ['primary', 'base'],
      primaryFocusBg: ['primary', 'base'],
      primaryFocusBorder: ['button', 'primaryFocusBg'],
      primaryFocusText: 'white',
      primaryHoverBg: ['primary', '-6'],
      primaryHoverBorder: ['button', 'primaryHoverText'],
      primaryHoverText: ['primary', '+1'],

      // Primary Opaque
      primaryOpaqueBg: [ 'primary', 'base', ],
      primaryOpaqueBorder: 'transparent',
      primaryOpaqueText: 'white',
      primaryOpaqueActiveBg: [ 'primary', '+1', ],
      primaryOpaqueActiveBorder: 'transparent',
      primaryOpaqueActiveText: 'white',
      primaryOpaqueFocusBg: [ 'secondary', 'base', ],
      primaryOpaqueFocusBorder: [ 'neutral', '+2', ],
      primaryOpaqueFocusText: 'white',
      primaryOpaqueHoverBg: [ 'primary', '+1', ],
      primaryOpaqueHoverBorder: 'transparent',
      primaryOpaqueHoverText: 'white',

      // Opaque Sales button
      salesOpaqueBg: [ 'sales', 'base', ],
      salesOpaqueBorder: 'transparent',
      salesOpaqueText: [ 'neutral', '-1', ],
      salesOpaqueActiveBg: [ 'sales', '+1', ],
      salesOpaqueActiveBorder: 'transparent',
      salesOpaqueActiveText: [ 'button', 'salesOpaqueText', ],
      salesOpaqueFocusBg: [ 'sales', '+2', ],
      salesOpaqueFocusBorder: [ 'neutral', '+2', ],
      salesOpaqueFocusText: [ 'button', 'salesOpaqueText', ],
      salesOpaqueHoverBg: [ 'sales', '+2', ],
      salesOpaqueHoverBorder: 'transparent',
      salesOpaqueHoverText: [ 'button', 'salesOpaqueText', ],

      ...colorOverrides.variants.button,

      // Inverse
      inverseBg: 'transparent',
      inverseBorder: 'white',
      inverseText: 'white',
      inverseActiveBg: 'transparent',
      inverseActiveBorder: 'white',
      inverseActiveText: 'white',
      inverseFocusBg: 'rgba(255,255,255,0.1)',
      inverseFocusBorder: 'white',
      inverseFocusText: 'white',
      inverseHoverBg: 'rgba(255,255,255,0.2)',
      inverseHoverBorder: 'white',
      inverseHoverText: 'white',
    },

    checkBox: {
      // Primary
      primaryBg: 'white',
      primaryBgChecked: ['primary', 'base'],
      primaryBorder: ['primary', 'base'],
      primaryBorderDisabled: ['neutral', '-5'],
      primaryCheck: 'white',
      primaryRipple: ['primary', 'base'],
      ...colorOverrides.variants.checkBox,
    },

    icon: {
      base: ['primary', 'base'],
    },

    image: {
      bgc: [ 'neutral', '-6', ],
    },

    sales: {
      '-2': '#FFF7E5',
      '-1': '#FFBD45',
      base: '#FFA500',
      '+1': '#FA9600',
      '+2': '#ED8600',
      a11yOnLight: '#A7610C',
    },

    select: {
      // Primary
      primaryBg: ['primary', '-6'],
      primaryBgWrapper: 'transparent',
      primaryBorder: ['primary', '-4'],
      primaryBorderTextLabel: ['primary', '-5'],
      primaryPlaceholder: ['neutral', '-4'],
      primaryText: ['bodyText', 'base'],
      primaryTextLabel: ['primary', '+1'],
      primaryTextLabelDisabled: ['neutral', '-4'],
      primaryTextNote: ['neutral', '-3'],
      primaryAbbr: ['tertiary', 'base'],
      primaryBorderItem: ['neutral', '-6'],
      primaryArrowColor: ['primary', 'base'],
      primaryTextColor: ['primary', 'base'],

      // Primary highlighted
      primaryHighlightedBg: ['primary', '-3',],

      // Primary Focus
      primaryFocusBg: 'white',
      primaryFocusBorder: ['primary', 'base'],

      // Primary Error state
      primaryErrorBorder: ['tertiary', '+1'],
      primaryErrorText: ['bodyText', 'base'],
      primaryErrorTextLabel: ['tertiary', 'base'],
      primaryErrorTextNote: ['tertiary', 'base'],

      // Primary Hover
      primaryHoverBg: ['primary', '-5'],
      primaryHoverBorder: ['primary', '-4'],
      primaryHoverText: ['bodyText', 'base'],
      ...colorOverrides.variants.input,
    },


    noteLink: {
      color: site === 'tm' ? ['secondary', 'base'] : ['primary', 'base'],
    },

    input: {
      // Primary
      primaryBg: ['primary', '-6'],
      primaryBgWrapper: 'transparent',
      primaryBorder: ['primary', '-4'],
      primaryBorderTextLabel: ['primary', '-5'],
      primaryPlaceholder: ['neutral', '-4'],
      primaryText: ['bodyText', 'base'],
      primaryTextLabel: ['primary', '+1'],
      primaryTextLabelDisabled: ['neutral', '-4'],
      primaryTextNote: ['neutral', '-3'],
      primaryAbbr: ['tertiary', 'base'],

      // Primary Focus
      primaryFocusBg: 'white',
      primaryFocusBorder: ['primary', 'base'],

      // Primary Error state
      primaryErrorBorder: ['tertiary', '+1'],
      primaryErrorText: ['bodyText', 'base'],
      primaryErrorTextLabel: ['tertiary', 'base'],
      primaryErrorTextNote: ['tertiary', 'base'],

      // Primary Hover
      primaryHoverBg: ['primary', '-5'],
      primaryHoverBorder: ['primary', '-4'],
      primaryHoverText: ['bodyText', 'base'],
      ...colorOverrides.variants.input,
    },

    // header: {
    // },
    ...colorOverrides.misc,

    // state
    disabled: {
      text: ['neutral', '-4'],
      bg: ['neutral', '-5'],
    },

    layout: {
      rowBg: 'transparent',
      containerBg: 'transparent',
    },

    negative: {
      '-1': ['tertiary', '-1'],
      base: ['tertiary', 'base'],
      '+1': ['tertiary', '+1'],
      a11yOnDark: ['tertiary', '-1'],
    },

    positive: {
      '-2': '#F5FFF5',
      '-1': '#5BB856',
      base: '#2F872A',
      '+1': '#266D22',
      '+2': '#194716',
      a11yOnDark: ['positive', '-1'],
    },
    downloadAplicationPopUp: colorOverrides.downloadAplicationPopUp,
    placeholder: {
      bg: [ 'neutral', '-7', ],
    },
  };
};

export { colors };
