import { breakUrl, } from '@haaretz/app-utils';
import { getReferrer, } from '../queryutil/userDetailsOperations';

const regex = {
  login: /(login-[\w\d]+\.)|(login\.)|(:3000)/, // restrict referring to this url
  site: /(https?:\/\/(.+?\.)?(themarker\.com|haaretz\.co\.il|haaretz\.com)(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/, // allow referring to this url
};

const getCacheReferrer = client => {
  try {
    return getReferrer(client);
  }
  catch (e) {
    return false;
  }
};

const getFinalURL = url => {
  const { path, } = breakUrl(url);
  if (!path || path === '/') {
    return url;
  }
  let result = url;

  result = addTimeStamp(result);

  if (result.includes('cdn.ampproject.org')) {
    result += '#success=true';
  }

  return result;
};

const addTimeStamp = url => (url.includes('?') ? `${url}&lts=${Date.now()}` : `${url}?lts=${Date.now()}`);

const getReferrerUrl = (client, referrerUrl = getCacheReferrer(client)) => {
  const url = typeof referrerUrl === 'string' && referrerUrl.includes('cdn.ampproject.org') ? referrerUrl.split('%2F').join('/')
    : referrerUrl;
  const result = (!regex.login.test(url) && regex.site.test(url)
    ? getFinalURL(referrerUrl)
    : false);

  return result;
};

export { getReferrerUrl, };
