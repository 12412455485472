import React, { Fragment, } from 'react';
import PropTypes from 'prop-types';
import { createComponent, FelaComponent, } from 'react-fela';
import {
  EventTracker,
  IconAlefLogo,
  IconTheMarker,
  Astronaut,
  Diver,
} from '@haaretz/htz-components';

const propTypes = {
  /** passed as a a prop by fela's withTheme func before default export */
  site: PropTypes.oneOf([ 'htz', 'hdc', 'tm', ]).isRequired,
  hasIllustration: PropTypes.bool,
  hasMarginTop: PropTypes.bool,
  /** passing stage from client promotionsPageState to BIAction */
  stage: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, ]).isRequired,
};

const defaultProps = {
  hasIllustration: true,
  hasMarginTop: true,
  stage: 1,
};

const footerContStyle = ({ theme, hasMarginTop, }) => ({
  position: 'relative',
  backgroundColor: theme.color('footer', 'bg'),
  color: theme.color('footer', 'text'),
  extend: [
    theme.mq({ until: 'l', }, { flexDirection: 'row-reverse', }),
    hasMarginTop
      ? theme.mq({ until: 'm', }, { marginTop: '7rem', })
      : theme.mq({ until: 'l', }, { marginBlockStart: 'auto' }),
  ],
});

const footerLinkListContStyle = ({ theme, }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '4rem',
  paddingBottom: '5rem',
});

const linkStyle = ({
  theme,
  isLast = false,
  onlyMobile = false,
  onlyDesktop = false,
  hide = false,
  isHDC = false,
}) => ({
  paddingInlineEnd: '1rem',
  paddingInlineStart: '1rem',
  ...(hide ? { display: 'none', } : {}),
  extend: [
    theme.type(isHDC ? -1 : 0),
    onlyMobile && theme.mq({ from: 's', }, { display: 'none', }),
    onlyDesktop && theme.mq({ until: 's', }, { display: 'none', }),
  ],
});

const linkListStyle = ({ theme, }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '3rem',
  extend: [
    theme.mq({ until: 's', }, { flexDirection: 'column', alignItems: 'center', marginTop: 'none', }),
  ],
});

const StyledLinkList = createComponent(linkListStyle, 'ul');

const listItemStyle = ({ theme, isLast = false, }) => ({
  ...(!isLast
    ? {
      ':after': {
        content: '"|"',
      },
    }
    : {}),
  extend: [
    theme.mq(
      { until: 's', },
      {
        marginTop: '1rem',
        ':after': {
          content: '""',
        },
      }
    ),
  ],
});

const UppercaseStyles = ({ theme, isHDC, }) => ({
  textTransform: isHDC ? 'uppercase' : undefined,
  extend: isHDC ? [ theme.type(-1), ] : [],
});

const StyledListItem = createComponent(listItemStyle, 'li');

function FooterNew({ site, hasIllustration, stage, hasMarginTop, }) {
  const isTheMarker = site === 'tm';
  const isHDC = site === 'hdc';

  return (
    <FelaComponent style={props => footerContStyle({ ...props, hasMarginTop, })}>
      <FelaComponent style={footerLinkListContStyle}>
        {({
          className,
          theme: {
            footer: { homePageLink, links, },
            linkToHomePage: { href, },
          },
        }) => (
          <div className={className}>
            <EventTracker>
              {({ biAction, gaAction, }) => (
                <FelaComponent
                  style={{
                    paddingInlineEnd: '1rem',
                    paddingInlineStart: '1rem',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                    textAlign: 'center',
                  }}
                >
                  {({ className, }) => (
                    <a
                      className={className}
                      href={href}
                      onClick={() => {
                        biAction({
                          actionCode: 42,
                          additionalInfo: {
                            stage,
                          },
                        });
                        gaAction({
                          category: 'login-footer',
                          action: 'homepage',
                          label: 'login',
                        });
                      }}
                    >
                      <Fragment>
                        {isTheMarker ? (
                          <IconTheMarker color="primary" fill={[ 'footer', 'bg', ]} size={6} />
                        ) : (
                          <IconAlefLogo
                            color="white"
                            fill={[ 'footer', 'bg', ]}
                            miscStyles={{
                              marginBottom: isHDC ? '1rem' : undefined,
                            }}
                            size={isHDC ? 5 : 6}
                          />
                        )}
                        <FelaComponent
                          style={({ ...props }) => ({ ...UppercaseStyles({ ...props, isHDC, }), })}
                        >
                          {({ className, }) => (
                            <p className={className}>{homePageLink.text}</p>)}
                        </FelaComponent>
                      </Fragment>
                    </a>
                  )}
                </FelaComponent>
              )}
            </EventTracker>
            <StyledLinkList>
              {links.map((link, idx) => (
                <StyledListItem isLast={idx === links.length - 1} key={link.id}>
                  <EventTracker>
                    {({ biAction, gaAction, }) => (
                      <Fragment>
                        <FelaComponent
                          style={({ ...props }) => ({ ...UppercaseStyles({ ...props, isHDC, }), ...linkStyle({ ...props, isHDC, }), })}
                          hide={link.text === 'שאלות ותשובות'}
                          onlyMobile
                        >
                          {({ className, }) => (
                            <a
                              className={className}
                              onClick={() => {
                                biAction({
                                  actionCode: link.text === 'שאלות ותשובות' ? 43 : 41,
                                  additionalInfo: {
                                    stage,
                                  },
                                });
                                gaAction({
                                  category: 'login-footer',
                                  action: link.text === 'שאלות ותשובות' ? 'faq' : 'contact',
                                  label: 'login',
                                });
                              }}
                              href={link.hrefMobile}
                            >
                              {link.text}
                            </a>
                          )}
                        </FelaComponent>
                        <FelaComponent style={({ ...props }) => ({ ...UppercaseStyles({ ...props, isHDC, }), ...linkStyle({ ...props, isHDC, }), })} onlyDesktop>
                          {({ className, }) => (
                            <a
                              className={className}
                              onClick={() => {
                                biAction({
                                  actionCode: link.text === 'שאלות ותשובות' ? 43 : 41,
                                  additionalInfo: {
                                    stage,
                                  },
                                });
                                gaAction({
                                  category: 'login-footer',
                                  action: link.text === 'שאלות ותשובות' ? 'faq' : 'contact',
                                  label: 'login',
                                });
                              }}
                              href={link.href}
                            >
                              {link.text}
                            </a>
                          )}
                        </FelaComponent>
                      </Fragment>
                    )}
                  </EventTracker>
                </StyledListItem>
              ))}
            </StyledLinkList>
          </div>
        )}
      </FelaComponent>
      {hasIllustration && (
        <FelaComponent
          style={({ theme, }) => ({
            position: 'absolute',
            bottom: '0',
            overflow: 'hidden',
            maxHeight: isTheMarker ? '200%' : '135%',
            extend: [
              theme.mq({ from: 'l', }, { left: '10rem', }),
              theme.mq({ from: 'm', until: 'l', }, { left: '0', }),
              theme.mq(
                { from: 's', until: 'm', },
                {
                  right: isTheMarker ? '-5rem' : '-6rem',
                  maxHeight: isTheMarker ? '135%' : '120%',
                }
              ),
              theme.mq(
                { until: 's', },
                {
                  right: isTheMarker ? '-9rem' : '-10rem',
                  maxHeight: isTheMarker ? '100%' : '55%',
                }
              ),
            ],
          })}
        >
          {site === 'tm' ? (
            <Diver
              size={[
                { until: 's', value: 30, },
                { from: 's', until: 'm', value: 40, },
                { from: 'm', until: 'l', value: 50, },
                { from: 'l', value: 60, },
              ]}
            />
          ) : site === 'htz' ? (
            <Astronaut
              size={[
                { until: 's', value: 30, },
                { from: 's', until: 'm', value: 40, },
                { from: 'm', until: 'l', value: 50, },
                { from: 'l', value: 60, },
              ]}
            />
          ) : null}
        </FelaComponent>
      )}
    </FelaComponent>
  );
}

FooterNew.propTypes = propTypes;

FooterNew.defaultProps = defaultProps;

export default FooterNew;
