const domainToSiteNumber = domain => (domain.includes('themarker.com')
  ? '10'
  : domain.includes('haaretz.com')
    ? '85'
    : domain.includes('haaretz.co.il')
      ? '80'
      : '');

/**
 * returns homepage url by enviroment and host
 * @param {*} pageLocation window.loction
 */
const getHomepageUrl = pageLocation => {
  try {
    const clearedHost = pageLocation.host.replace(/.k8s-stage./g, '.');
    let prefix = clearedHost.substring(0, clearedHost.indexOf('.'));

    prefix = prefix === 'login'
      || prefix.includes('login-prod')
      || prefix.includes('login-e2e')
      ? 'https://www'
      : prefix.includes('login-canary')
        ? 'https://canary'
        : prefix.includes('login-dev')
          ? `http://${prefix.replace('login', 'react')}`
          : `http://${prefix}`;

    return `${prefix}.${clearedHost.substring(clearedHost.indexOf('.') + 1)}`.replace(':3000', '');
  }
  catch (e) {
    return `https://${pageLocation.host}`;
  }
};

function extractHostname(host) {
  return host.includes('themarker.com')
    ? 'themarker.com'
    : host.includes('haaretz.com')
      ? 'haaretz.com'
      : 'haaretz.co.il';
}

function isEngSite(host) {
  return host.includes('haaretz.com');
}

function getTextByHost({ host, he, en, }) {
  return isEngSite(host) ? en : he;
}

function getSiteByHost(host) {
  return host.includes('themarker.com')
    ? 'tm'
    : host.includes('haaretz.com')
      ? 'hdc'
      : 'htz';
}

const getUrlParams = () => {
  // eslint-disable-next-line no-undef
  const pageUrl = new URL(window.location.href);
  return {
    returnTo: pageUrl.searchParams.get('returnTo') || undefined,
  };
};

export {
  domainToSiteNumber,
  getHomepageUrl,
  extractHostname,
  isEngSite,
  getTextByHost,
  getSiteByHost,
  getUrlParams,
};
