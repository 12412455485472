const entitlementStatuses = Object.freeze({
  EMAIL_SENT: 'EMAIL_SENT',
  LOGIN_FULL: 'LOGIN_FULL',
  LOGIN_PARTIAL: 'LOGIN_PARTIAL',
  LOGIN_ENTITLEMENT: 'LOGIN_ENTITLEMENT',
  FULL_ENTITLED: 'FULL_ENTITLED',
  PARTIAL_ENTITLED: 'PARTIAL_ENTITLED',
  NOT_ENTITLED: 'NOT_ENTITLED',
  NOT_ENTITLED_PRINT: 'NOT_ENTITLED_PRINT',
  ERROR: 'ERROR',
});

export default entitlementStatuses;
