// @flow
import * as React from 'react';

type RefFunc = HTMLElement => void;

type GetRefFunc = (inputName: string, initialValue: ?any) => ?RefFunc;


const emptyInputValues = [ '', null, undefined, ];

// TODO: test this out and adjust to different scenarios
function useFocusFirstEmptyInput(shouldFocus?: boolean = true): GetRefFunc {
  const firstEmptyInputRef = React.useRef(null);
  const firstEmptyInputRefFunc = React.useCallback(el => {
    firstEmptyInputRef.current = el;
  }, []);
  const [ getRefFunc, ] = React.useState(() => {
    let refInputName;
    return (inputName, initialValue) => {
      if (refInputName == null && emptyInputValues.includes(initialValue)) {
        refInputName = inputName;
      }
      return inputName === refInputName
        ? firstEmptyInputRefFunc
        : null;
    };
  });
  React.useEffect(() => {
    if (!shouldFocus) return;

    const firstEmptyInput = firstEmptyInputRef.current;
    if (firstEmptyInput && typeof firstEmptyInput.focus === 'function') {
      firstEmptyInput.focus();
    }
  }, [ shouldFocus, ]);

  return getRefFunc;
}

export default useFocusFirstEmptyInput;
