const transformGraphQlObjectToFlowDispenserObject = ({ user, }) => {
  const userExist = user !== null && (!user.message || user.message === 'Mail is not validated');

  return {
    user: {
      isUserExist: userExist,
      isEmailValid: userExist && user.userStatus ? user.userStatus.isMailValidated : false,
      isPhoneValid: userExist && user.userStatus ? user.userStatus.isMobileValidated : false,
      isPhoneConnectedWithEmail: userExist && user.userStatus ? user.userStatus.isPhoneEmailConn : false,
      isPremiumUser: (userExist && user.isPaying) || false,
    },
  };
};

export default transformGraphQlObjectToFlowDispenserObject;
